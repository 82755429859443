import { Mark, mergeAttributes, Node } from "@tiptap/core";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableRow from "@tiptap/extension-table-row";
import { wrappingInputRule } from "prosemirror-inputrules";

// Custom Extenstions
export const FontFamily = Mark.create({
  name: "fontFamily",
  renderHTML({ HTMLAttributes }) {
    // console.log(HTMLAttributes);
    return ["span", HTMLAttributes, 0];
  },
  addAttributes() {
    return {
      fontFamily: {
        default: null,
        renderHTML: (attributes) => {
          // console.log(attributes);
          return {
            style: `font-family: ${attributes.fontFamily}`,
          };
        },
      },
    };
  },
});

export const FontSize = Mark.create({
  name: "fontSize",
  renderHTML({ HTMLAttributes }) {
    // console.log(HTMLAttributes);
    return ["span", HTMLAttributes, 0];
  },
  addAttributes() {
    return {
      fontSize: {
        default: null,
        renderHTML: (attributes) => {
          // console.log(attributes);
          return {
            style: `font-size: ${attributes.fontSize}`,
          };
        },
      },
    };
  },
});
export const FontTextColor = Mark.create({
  name: "fontTextColor",
  renderHTML({ HTMLAttributes }) {
    // console.log(HTMLAttributes);
    return ["span", HTMLAttributes, 0];
  },
  addAttributes() {
    return {
      fontTextColor: {
        default: null,
        renderHTML: (attributes) => {
          // console.log(attributes);
          return {
            style: `color: ${attributes.fontTextColor}`,
          };
        },
      },
    };
  },
});
export const Subscript = Mark.create({
  name: "subscript",
  renderHTML({ HTMLAttributes }) {
    // console.log(HTMLAttributes);
    return ["sub", HTMLAttributes, 0];
  },
});
export const Superscript = Mark.create({
  name: "superscript",
  renderHTML({ HTMLAttributes }) {
    // console.log(HTMLAttributes);
    return ["sup", HTMLAttributes, 0];
  },
});

export const ExtendedTableRow = TableRow.extend({
  type: "table_row",
  name: "table_row",
  content: "(table_cell | tableHeader)*",

  parseHTML() {
    return [{ tag: "tr" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["tr", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export const ExtendedTableCell = TableCell.extend({
  name: "table_cell",
  type: "table_cell",
  addAttributes() {
    return {
      colspan: {
        default: 1,
        parseHTML: (element) => {
          const colspan = element.getAttribute("colspan");
          const value = colspan ? [parseInt(colspan, 10)] : null;

          return {
            colspan: value,
          };
        },
      },
      rowspan: {
        default: 1,
        parseHTML: (element) => {
          const rowspan = element.getAttribute("rowspan");
          const value = rowspan ? [parseInt(rowspan, 10)] : null;

          return {
            rowspan: value,
          };
        },
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth");
          const value = colwidth ? [parseInt(colwidth, 10)] : null;

          return {
            colwidth: value,
          };
        },
      },
      background: {
        default: null,
        renderHTML: (attributes) => {
          // console.log(attributes);
          return {
            style: `${attributes.background ? `background-color:${attributes.background};` : ""}
            border: 1px solid #000000;
            min-width: 1em;
            padding: 3px 5px;
            box-sizing: border-box;
            position: relative`,
          };
        },
      },
    };
  },

  tableRole: "cell",

  isolating: true,

  parseHTML() {
    return [{ tag: "td" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["td", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export const ExtendedTable = Table.extend({
  name: "table",
  content: "table_row+",

  parseHTML() {
    return [{ tag: "table" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "table",
      mergeAttributes(this.options.HTMLAttributes, {
        style: `border-collapse: collapse;table-layout: fixed;margin: 0;overflow: hidden; margin-top:10px;`,
      }),
      ["tbody", 0],
    ];
  },
});

export const ExtendedListItem = ListItem.extend({
  name: "list_item",

  defaultOptions: {
    HTMLAttributes: {},
  },

  content: "paragraph block*",

  defining: true,

  parseHTML() {
    return [
      {
        tag: "li",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["li", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem("listItem"),
      Tab: () => this.editor.commands.sinkListItem("listItem"),
      "Shift-Tab": () => this.editor.commands.liftListItem("listItem"),
    };
  },
});
export const inputRegex = /^(\d+)\.\s$/;

export const ExtendedOrderedList = OrderedList.extend({
  name: "ordered_list",

  defaultOptions: {
    HTMLAttributes: {},
  },

  group: "block list",

  content: "list_item+",

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: (element) => ({
          start: element.hasAttribute("start") ? parseInt(element.getAttribute("start") || "", 10) : 1,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "ol",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes;

    return start === 1
      ? ["ol", mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
      : ["ol", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleOrderedList: () => ({ commands }) => {
        return commands.toggleList("orderedList", "listItem");
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-7": () => this.editor.commands.toggleOrderedList(),
    };
  },

  addInputRules() {
    return [
      wrappingInputRule(
        inputRegex,
        this.type,
        (match) => ({ order: +match[1] }),
        (match, node) => node.childCount + node.attrs.order === +match[1]
      ),
    ];
  },
});

export const inputBulletRegex = /^\s*([-+*])\s$/;

export const ExtendedBulletList = BulletList.extend({
  name: "bullet_list",

  defaultOptions: {
    HTMLAttributes: {},
  },

  group: "block list",

  content: "list_item+",

  parseHTML() {
    return [{ tag: "ul" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["ul", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleBulletList: () => ({ commands }) => {
        return commands.toggleList("bullet_list", "list_item");
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-8": () => this.editor.commands.toggleBulletList(),
    };
  },

  addInputRules() {
    return [wrappingInputRule(inputBulletRegex, this.type)];
  },
});

export const ExtendedImageTipTap = Node.create({
  name: "image",

  defaultOptions: {
    HTMLAttributes: {},
  },

  parseHTML() {
    return [{ tag: "span[sequence]" }];
  },
  addAttributes() {
    return {
      width: {
        default: "200",
      },
      src: {},
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: "rte-image" })];
  },
});

export const Blank = Node.create({
  name: "blank",

  defaultOptions: {
    HTMLAttributes: {},
  },

  parseHTML() {
    return [{ tag: "span[sequence]" }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      {
        style: `min-width: 50px;
    display: inline-block;
    border-bottom: 1px solid black;
    text-align: center;
    padding-bottom: 1px;`,
      },
      ["span", { class: "tip-tap-plugin-blank" }, "      "],
    ];
  },
});
export const Equation = Node.create({
  name: "equation",

  defaultOptions: {
    HTMLAttributes: {},
  },

  parseHTML() {
    return [{ tag: "span[data-latex]" }];
  },
  addAttributes() {
    return {
      latex: {
        default: null,
        renderHTML: (attributes) => {
          return {"data-latex": attributes.latex};
        },
      },
      equationJSONString: {
        default: null,
        renderHTML: (attributes) => {
          return {"data-equation-json-string": attributes.equationJSONString};
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: "katex-container" }), (window as any).katex.renderToString(HTMLAttributes['data-latex'])];
  },
});
