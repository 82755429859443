/* eslint-disable react/display-name */
// eslint-disable-next-line
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";

import firebaseApp from "./firebase";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
// import notifications from "./utils/Notifications";
import SnackbarContextProvider from "./context/SnackbarContextProvider";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

// Initializing firebase App and services
firebaseApp.InitializeApp();
// firebaseApp.InitializeAnalytics();
// firebaseApp.InitializePerformance(); // Performance monitoring
// const messaging = firebaseApp.InitializeMessaging(); // Push Notifications
declare global {
  interface Window {
    MessageBirdChatWidget: any;
  }
}
function App() {
  // useEffect(() => {
  //   messaging && notifications.requestPermission(messaging);
  // });
  const THEME = createMuiTheme({
    palette: {
      primary: {
        main: '#af0101'
      }
    },
    typography: {
      fontFamily: `"Source Sans Pro", sans-serif;`,
      fontSize: 18,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });
  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={THEME}>
        <SnackbarContextProvider>
          <Router>
            <Routes />
          </Router>
        </SnackbarContextProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
