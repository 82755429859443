/* eslint-disable react/display-name */
// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Grid, Typography, Container, Hidden, Box } from "@material-ui/core";
import Styled from "styled-components";
import test1 from "../../assets/images/icons/test1.png";
import test2 from "../../assets/images/icons/test2.png";
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import { post } from "../../utils/Api";
import useWidth from "../../hooks/useWidth";
function ChartSection() {
  const [dashboardData, setDashboradData] = useState(Object);
  const width = useWidth();
  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  const getDashboradDetails = () => {
    post("", {
      query: `query { dashboard {  createdPaper,  usedPaper, subjectGraph{ _id, saveQuestion, usedPaper },gradeGraph{ _id, saveQuestion, usedPaper } } }`,
    })
      .then((res) => {
        console.log("Dashboard data :", res.data);
        setDashboradData(res.data.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getDashboradDetails();
  }, []);

  return (
    <StyledGrid container spacing={3}>
      <Grid item md={2} style={{padding: '0px 20px'}} xs={12}>
        <Container style={{padding: 0}}>
          <Heading variant="caption">
            {` Tests created`}
            <Hidden smDown>
              <br />
            </Hidden>
            {" by your School"}
          </Heading>
          <GridCard
            container
            spacing={2}
            style={
              screenWidth[width] > 2 ? { height: "290px" } : { height: "auto" }
            }
          >
            <Grid item md={12} xs={6}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <SubHeading variant="caption">
                    Created
                  </SubHeading>
                </Grid>
                <Grid item xs={6}>
                  <Icon src={test1} alt="" />
                </Grid>
                <Grid item xs={6}>
                  <Count variant="caption">
                    {dashboardData.dashboard
                      ? dashboardData.dashboard.createdPaper
                      : 0}
                  </Count>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} xs={6}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <SubHeading variant="caption">
                    Used
                  </SubHeading>
                </Grid>
                <Grid item xs={6}>
                  <Icon src={test2} alt="" />
                </Grid>
                <Grid item xs={6}>
                  <CountTwo variant="caption">
                    {dashboardData.dashboard
                      ? dashboardData.dashboard.usedPaper
                      : 0}
                  </CountTwo>
                </Grid>
              </Grid>
            </Grid>
          </GridCard>
        </Container>
      </Grid>
      <Grid item md={5} style={{padding: '0px 10px'}} xs={12}>
        <Container style={{padding: 0}}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Heading variant="caption">
                {` Tests you have`}
                <Hidden smDown>
                  <br />
                </Hidden>
                {" created by Subject"}
              </Heading>
            </Grid>
            <Box clone order={{ xs: 3, md: 2 }}>
              <Section item xs={12} md={6}>
                <GreenBox />
                <Caption variant="subtitle2">Used</Caption>
                <GreyBox />
                <Caption variant="subtitle2">Unused</Caption>
              </Section>
            </Box>
            <Box clone order={{ xs: 2, md: 2 }}>
              <Grid item xs={12}>
                <GridCard container>
                  <ChartOne
                    subjectGraph={
                      dashboardData.dashboard
                        ? dashboardData.dashboard.subjectGraph
                        : []
                    }
                  />
                </GridCard>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Grid>
      <Grid item md={5} style={{padding: '0px 20px'}} xs={12}>
        <Container style={{padding: 0}}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Heading variant="caption">
                {`Tests you have`}
                <Hidden smDown>
                  <br />
                </Hidden>
                {" created by Grade"}
              </Heading>
            </Grid>
            <Box clone order={{ xs: 3, md: 2 }}>
              <Section item xs={12} md={6}>
                <GreenBox />
                <Caption variant="subtitle2">Used</Caption>
                <GreyBox />
                <Caption variant="subtitle2">Unused</Caption>
              </Section>
            </Box>
            <Box clone order={{ xs: 2, md: 2 }}>
              <Grid item xs={12}>
                <GridCard container>
                  <ChartTwo
                    gradeGraph={
                      dashboardData.dashboard
                        ? dashboardData.dashboard.gradeGraph
                        : []
                    }
                  />
                </GridCard>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </StyledGrid>
  );
}

export default ChartSection;

const StyledGrid = Styled(Grid)`
margin-top:30px;
margin-bottom: 15px;
`;
const Heading = Styled(Typography)`
color:#3D3D3D;
font-size:1rem;
font-weight: 900;
line-height: 1.14px;
`;
const SubHeading = Styled(Typography)`
color:#AAAAAA;
font-size:1rem;
font-weight:600;
`;
const Count = Styled(Typography)`
color:#383838;
font-size:2rem;
font-weight:bold`;
const CountTwo = Styled(Count)`
color:#5C8985;
`;
const GridCard = Styled(Grid)`
background:#F6F6F6;
margin-top:5px;
border-radius: 5px;
height: 290px;
`;
const Section = Styled(Grid)`
display: flex;
justify-content: center;
align-items: center;
`;
const Icon = Styled.img`
height:40px;
object-fit:contain;
`;
const GreenBox = Styled.div`
width:10px;
height:10px;
margin-left:10px;
background-color:#5C8985;
`;
const GreyBox = Styled(GreenBox)`
background-color:#AAAAAA;
`;
const Caption = Styled(Typography)`
color:#000000;
font-size:0.8rem;
font-weight:bold;
margin-left:5px;
`;
