import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function CustomLoadingOverlay() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <CircularProgress color="secondary" style={{ margin: "auto" }} />
    </div>
  );
}
