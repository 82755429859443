/* eslint-disable react/display-name */
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { LoginRoute } from "../Routes/RoutesConstants";
import firebaseApp from "../firebase";

export default function DefaultLayout() {
  const Navigate = useNavigate();
  const [isLogin , setIsLogin] = useState<Boolean>(false)

  useEffect(() => {
    firebaseApp.AuthCurrentUser().then((user) => {
      setIsLogin(user != null);

      if (!user) {
        Navigate(LoginRoute);
        // console.log(DashboardRoute);
      } else {
        window.MessageBirdChatWidget.hide();
      }
    })
  }, []);

  return isLogin ? <Outlet /> : <PageLoader />;
}
