import * as firebase from 'firebase/app';
require('firebase/auth')

// Your web app's Firebase configuration
const firebaseConfig = require(process.env.REACT_APP_FIREBASE_CONFIG_PATH!);
declare type FirebaseAppProps = {
  InitializeApp: () => firebase.app.App;
  InitializeAnalytics: () => firebase.analytics.Analytics;
  InitializePerformance: () => firebase.performance.Performance;
  InitializeMessaging: () => firebase.messaging.Messaging | null;
  AuthCurrentUser: () => Promise<firebase.User | null>;
  AuthCurrentUserSync: () => firebase.User | null;
  AuthIdToken: () => Promise<string>
  AuthSignInWithCustomToken: (customToken: string) => Promise<firebase.auth.UserCredential>
  AuthSignOut: () => void
};

const firebaseApp: FirebaseAppProps = {
  InitializeApp: () => firebase.initializeApp(firebaseConfig),
  InitializeAnalytics: () => firebase.analytics(),
  InitializePerformance: () => firebase.performance(),
  InitializeMessaging: () => {
    if (firebase.messaging.isSupported()) {
      const defaultMessaging = firebase.messaging();
      defaultMessaging.usePublicVapidKey(
        'BEwJsg2FhIL9AIYV6BE5I4X4jkNoWjzLUF-pNWQdmL_SMVDYuRQ2Kzvb7AD3sXDQ6UptDifYMSWYkRKZD3hk-7I'
      );
      return defaultMessaging;
    } else return null;
  },
  AuthCurrentUser: async () => {
    let user = firebase.auth().currentUser;

    if (!user) {
      user = await new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            setTimeout(() => resolve(null), 1000);
          }
        });
      })
    }

    return user;
  },
  AuthCurrentUserSync: () => {
    return firebase.auth().currentUser;
  },
  AuthIdToken: async () => {
    const token = await firebase.auth().currentUser?.getIdToken()
    if (!token) throw Error('You session is not valid');

    return token;
  },
  AuthSignInWithCustomToken: async (customToken: string): Promise<firebase.auth.UserCredential> => {
    return firebase.auth().signInWithCustomToken(customToken);
  },
  AuthSignOut: () => {
    firebase.auth().signOut().then((data) => console.log('Signed out successfully'));
  }
};


export default firebaseApp;
