/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { LoginRoute } from "../../Routes/RoutesConstants";
import { Theme, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styled from "styled-components";
import NavBar from "../../components/NavBar/NavBar";
// import { LoginRoute } from "../../Routes/RoutesConstants";
import { post } from "../../utils/Api";
import injectWithObserver from "../../utils/injectWithObserver";
import ChartSection from "./ChartSection";
import CreateQuestion from "./CreateQuestion";
import ViewQuestion from "./ViewQuestion";

interface SubjectProps {
  id: string;
  name: string;
  shortName: string;
}
interface GradeProps {
  id: string;
  name: string;
  displayName: string;
}

const initialSubject: SubjectProps = { id: "", name: "", shortName: "" };
const initialGrade: GradeProps = { id: "", name: "", displayName: "" };
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: 65,
      background: "#FAFAFA",
    },
  },
};
function Dashboard({ stores }: any) {
  const [school, setSchoolDetails] = useState<any>({});
  const [tabValue, setTabValue] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(initialSubject);
  const [selectedGrade, setSelectedGrade] = useState(initialGrade);
  const Navigate = useNavigate();
  const { state }: any = useLocation();
  const [refresh, doRefresh] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleTabChange = (event: any, newTab: any) => {
    setTabValue(newTab);
  };

  const DialogActions = withStyles((theme: Theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleDialogModalClose = () => {
    stores.userStore.changeUser(null);
    Navigate(LoginRoute);
    setDialogOpen(false);
  };

  const getUser = () => {
    post("", {
      query:
        "query { getUser{ _id,  email,  name,  mobile, inSchoolId, allotment, academicYear}}",
    })
      .then((res) => {
        console.log("Get user details:", res.data);
        if (!res.data.data.getUser.inSchoolId) {
          setDialogOpen(true);
        }
        if (
          res.data.data.getUser.inSchoolId &&
          !res.data.data.getUser.allotment
        ) {
          setDialogOpen(true);
        }

        if (res.data.data.getUser.academicYear) {
          stores.userStore.setAcademicYear(res.data.data.getUser.academicYear);
        }

        console.log("allotment", res.data.data.getUser.allotment);
        stores.userStore.changeUser(res.data.data.getUser);
      })
      .catch((e) => console.log(e));
  };

  const getSchoolName = () => {
    post("", {
      query:
        "query { getSchoolName{name, inSchoolId, testTypes, standards {globalStandardId,displayName,name,globalBoardId,globalProductId} } }",
    })
      .then((res) => {
        console.log("Get Schhol details:", res.data);
        stores.userStore.setSchoolDetails(res.data.data.getSchoolName);
        // stores.userStore.changeUser();
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (stores.FormDataStore.SelectedSubject) {
      setSelectedSubject(toJS(stores.FormDataStore.getSelectedSubject));
    }
    if (stores.FormDataStore.SelectedGrade) {
      setSelectedGrade(toJS(stores.FormDataStore.getSelectedGrade));
    }
    getUser();
    getSchoolName();
    if (state) {
      const { tab } = state;
      // console.log(tab);
      setTabValue(tab);
    }
  }, []);

  // console.log(toJS(stores.userStore.getUser));

  const reLoader = (e: any) => {};
  return (
    <>
      <NavBar />

      <StyledTopGrid container>
        <Grid item md={12}>
          {/* <StyledDropDown
        style={{width:'15%',float:'right'}}
            id="filled-select-subject"
            select
            label="Academic year"
            // value={selectedSubject.name}
            variant="filled"
          >
            <MenuItem value={"none"}>None</MenuItem>
          
          </StyledDropDown> */}
        </Grid>
      </StyledTopGrid>
      <Container style={{ paddingBottom: 100 }}>
        <Grid container>
          {/* Commenting the chart section of the application*/}
          <ChartSection />
          <TabContainer
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <CustomTab label="Create New Test" wrapped />
            <CustomTab label="View Saved Tests" wrapped />
          </TabContainer>

          {tabValue === 0 ? <CreateQuestion /> : <ViewQuestion />}
        </Grid>
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
          fullWidth
          maxWidth="sm"
        >
          <DialogText>
            Sorry, Test Maker is only accessible for teachers using SuperTeacher subscribed subjects in Grades 1-8.
          </DialogText>
          <DialogActions>
            <Button
              variant="contained"
              style={{ background: "#af0000", color: "#fff" }}
              onClick={handleDialogModalClose}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
export default injectWithObserver(Dashboard);

const StyledTopGrid = Styled(Grid)`
background:#F6F6F6;
padding:25px;
padding-top:96px;
`;
const StyledDropDown = Styled(TextField)`
width:35%;
// border-bottom: 2px solid #AF0000;
// border-radius:2px;
margin-left:2%;
& .MuiFilledInput-root {
  background-color: white
};
// & .MuiFilledInput-underline:before {
//   border-bottom: 2px solid #AF0000;
// }
`;
export const DialogText = styled(Typography)`
  padding: 15px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;
const TabContainer = Styled(Tabs)`
width:100%;
color: RGB(51, 51, 51);
& .MuiButtonBase-root {
  background-color:#EDEDED;
  text-transform: none;
};
& .Mui-selected {
  color: RGB(175, 0, 0);
  background-color: RGB(248, 248, 248);
}
& .PrivateTabIndicator-colorSecondary-5 {
  background-color: RGB(175, 0, 0);
}
`;
const CustomTab = Styled(Tab)`
  font-weight: 600;
  letter-spacing: '0.13px';
`;
