/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import ReactPlayer from 'react-player/youtube'

function Video() {
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url='https://www.youtube.com/watch?v=5LOze3VfUMQ'
        width='100%'
        height='100%'
      />
    </div>
  );
}
export default Video;