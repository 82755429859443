/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import {
  createStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: "#D8D8D8",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      color: "#000000",
      fontSize: "1.4em",
      fontWeight: 400,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function BlockDetailDialog({
  open,
  toggleDialog,
  content,
  onClose,
}: {
  open: boolean;
  toggleDialog: any;
  content: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <div>
      <StyledDialog
        onClose={toggleDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogContent>{content}</MuiDialogContent>
      </StyledDialog>
    </div>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    width: 100%;
  }
`;
