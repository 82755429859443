/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Gapcursor from "@tiptap/extension-gapcursor";
import Paragraph from "@tiptap/extension-paragraph";
import TableHeader from "@tiptap/extension-table-header";
import { Text as ExtendedText } from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import { defaultExtensions } from "@tiptap/starter-kit";
import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Difficulty from "../../assets/images/Difficulty.png";
import ClockImage from "../../assets/images/clock.svg";
import Pdf from "../../assets/images/icons/PDF.png";
import Word from "../../assets/images/icons/word.png";
import Marks from "../../assets/images/marks.svg";
import StatusTick from "../../assets/images/StatusTick.svg";
import CustomizedButton from "../../components/Button/CustomizedButton";
import CustomizedDialog from "../../components/Modal/CustomizedDialog";
import BlockDetailDialog from "../../components/Modal/BlockDetailDialog";
import NavBar from "../../components/NavBar/NavBar";
import useSnackbar from "../../hooks/useSnackbar";
import useWidth from "../../hooks/useWidth";
import { DashboardRoute } from "../../Routes/RoutesConstants";
import { post } from "../../utils/Api";
import injectWithObserver from "../../utils/injectWithObserver";
import {
  Blank,
  Equation,
  ExtendedBulletList,
  ExtendedImageTipTap,
  ExtendedListItem,
  ExtendedOrderedList,
  ExtendedTable,
  ExtendedTableCell,
  ExtendedTableRow,
  FontFamily,
  FontSize,
  FontTextColor,
  Subscript,
  Superscript,
} from "../../utils/Tiptap/TipTapExtensions";
import Question from "./Question";
import RoundedButton from "./RoundedButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  groupConsecutiveNumbers,
  getQPAssetDetail,
  FILE_GENERATION_ERROR_STATUSES,
  getDownloadStatusContent,
  getStatusTextColor,
  getImageIcon
} from "../../helpers/common";
import Tooltip from "@material-ui/core/Tooltip";
import { useTable } from "react-table";
import firebase from "firebase";
import firebaseApp from "../../firebase";
const axios = require('axios');

const Styles = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    text-align: center;
    
    thead>tr {
      color: white;
      background-color: #ac2322;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }:any) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if(cell.column.id === "displaySequence") {
                  return <td {...cell.getCellProps()}>{"B"}{cell.render("Cell")}</td>;
                } else {
                  return <td {...cell.getCellProps()}  style={{ color: "#ac2322", fontWeight: 700 }}>{cell.render("Cell")}</td>;
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}


const { startCase } = require("lodash");

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PreviewQuestionPaper({ stores }: { stores: any }) {
  const [data, setData] = useState<null | any>(null);
  const [renderData, setRenderData] = useState<null | any>(null);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blockDetailOpen, setBlockDialogOpen] = useState(false);
  const [refreshDialogOpen, setRefreshDialogOpen] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [pageType, setPageType] = useState("preview");
  const [viewAnswer, setViewAnswer] = useState(false);
  const [email] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const Navigate = useNavigate();
  const width = useWidth();
  const snackBar = useSnackbar();
  const [visible, setVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEmailSendInProgress, setIsEmailSendInProgress] = React.useState(
    false
  );
  const [testNameSuggestion, setTestNameSuggestion] = React.useState("");

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    setDialogOpen(true);
  };
  const handleDialogModalClose = () => {
    Navigate("/app/dashboard");
  };
  const handleDialogModalDiscard = () => {
    Navigate("/app/dashboard");
    handleClickDiscard();
  };
  const handleDialogDiscardModal = () => {
    setDiscardDialogOpen(true);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "white",
        },
      },
    })
  );
  const classes = useStyles();
  const [isvisible, setIsVisible] = React.useState(false);
  const [values, setValues] = React.useState({
    testName: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [testNameErrorMessage, setTestNameErrorMessage] = React.useState("");
  const [expanded, setExpanded] = React.useState("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>([]);
  const [typingEmailAddress, setTypingEmailAddress] = React.useState<any>("");

  const handleChangeAccordion = (panel: any) => (
    event: any,
    isExpanded: any
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDownloadClick = (event: any) => {

    let checkStatus = renderData.assets.filter((e: any) => (e.status !== "completed" && e.status !== "not_started"));
    if(checkStatus.length > 0) {
      getQuestionPaperStatus(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const isDownloadPopupOpen = Boolean(anchorEl);
  const downloadPopupId = isDownloadPopupOpen ? "download-popover" : undefined;

  const handleClickOpen = () => {
    let testName = testNameSuggestion;

    if (!testName) {
      testName = `${renderData.questionDetails.testType} - ${renderData.questionDetails.gradeName} ${renderData.questionDetails.subjectName}`;
    }

    setValues({ ...values, testName: testName });
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };

  const handleSave = () => {
    if (values.testName) {
      setPageType("preview");
      handleQuestionPaperSave();
    }
  };

  const handleChange = (testName: string) => (event: {
    target: { value: any };
  }) => {
    const input = event.target.value;
    setValues({ ...values, [testName]: input });

    const errorMessage = validateTestName(input);

    if (errorMessage.length > 0) {
      setTestNameErrorMessage(errorMessage);
    } else {
      setTestNameErrorMessage("");
    }
  };

  const validateTestName = (testName: any) => {
    if (testName.length === 0) {
      return "Please enter the test name";
    }

    return "";
  };

  const contentArea = React.useRef(null);
  const editor = useEditor({
    extensions: [
      ...defaultExtensions(),
      Document,
      Paragraph,
      Bold,
      ExtendedText,
      TextStyle,
      ExtendedTable,
      ExtendedTableCell,
      TableHeader,
      ExtendedTableRow,
      FontFamily,
      FontSize,
      FontTextColor,
      ExtendedBulletList,
      ExtendedOrderedList,
      ExtendedListItem,
      Subscript,
      Underline,
      Superscript,
      Gapcursor,
      Equation,
      Blank,
      ExtendedImageTipTap,
    ],
  });
  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };

  const handleError = (_error: any) => {
    snackBar.show(
      _error,
      "error",
      { vertical: "bottom", horizontal: "center" },
      true
    );
  };
  const handleClickDiscard = () => {
    post("", {
      query: `query{
        discardQuestion(paperId: \"${data.previewId}\"){
          paperId,
          message
        }
      }`,
    });
  };

  const handleQuestionPaperSave = () => {
    if (pageType === "preview") {
      console.log("one");
      post("", {
        query: `query { submitPaper (paperId: "${data.previewId}", paperName: "${values.testName}"){  message, paperId } }`,
      })
        .then((res) => {
          if (res.data.data) {
            snackBar.show(
              `${values.testName} saved successfully`,
              "success",
              { vertical: "bottom", horizontal: "center" },
              true,
              3000
            );
            stores.FormDataStore.changeCreateQuestionPaperForm(null);
            setPageType("view");
            setVisible(false);
          } else {
            const errorMessages = res.data.errors.map((e: any) => e.message);

            snackBar.show(
              errorMessages.join(", "),
              "error",
              { vertical: "bottom", horizontal: "center" },
              true
            );
          }
        })
        .catch((err) => {
          console.log(err);
          handleError("Something went wrong");
        });
    } else {
      // Download pdf here
      // printDocument();
      // console.log("two");
      // handleDownload("");
    }
  };

  const handleQuestionCloseAndEmail = () => {
    if (pageType === "preview") {
      Navigate(DashboardRoute);
    } else {
      // Open sent email dialog here
      setOpen(true);
      setEmailSent(false);
    }
  };

  const handleOpenBlockDetail = () => {
    setBlockDialogOpen(true);
  };

  const handleSendEmail = () => {
    let emails = [];
    if (typingEmailAddress.length) {
      emails = addEmailIntoList(typingEmailAddress);
    } else {
      emails = autoCompleteValue;
    }

    const emailString = emails.toString();
    if (emailString) {
      setIsEmailSendInProgress(true);

      post("", {
        query: `query { sendQuestionPaper (emailId: \"${emailString}\",paperId: \"${data.previewId}\"){  base64FileContent, downloadExt } }`,
      })
        .then((res) => {
          setIsEmailSendInProgress(false);
          setEmailSent(true);
          console.log("email", res.data);
        })
        .catch((e) => {
          setIsEmailSendInProgress(false);
          console.log(e, "email");
        });
    } else {
      setErrorText("Enter valid email address");
      return false;
    }
    console.log("Sending email to :", email);
  };
  const testJSON = (text: any) => {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };
  const getHtml = (JsonInput: string) => {
    try {
      if (!testJSON(JsonInput)) {
        return JsonInput;
      }
      if (editor) {
        editor.commands.setContent(JsonInput ? JSON.parse(JsonInput) : null);
        return updateKatexContainer(editor.getHTML());
      }
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  // - From editorString
  // - 1. convert into DOM node
  // - 2. Find all katex-containers
  // - 3. Replace the innerText (string) with the DOM node of that string
  // - 4. Finally return the stringified version of the editorHtml NODE
  function updateKatexContainer(editorHtml: string) {
    let editorHtmlDomElement = new DOMParser().parseFromString(
      editorHtml,
      "text/html"
    ).body;
    if (
      editorHtmlDomElement.getElementsByClassName("katex-container").length == 0
    )
      return editorHtml;

    var allChildNodes = editorHtmlDomElement.childNodes;
    allChildNodes.forEach((childNode: any) => {
      let katexContainers = childNode.getElementsByClassName("katex-container");

      for (const katexCnr of katexContainers) {
        var equationNode = new DOMParser().parseFromString(
          katexCnr.innerText,
          "text/html"
        ).body.childNodes[0];

        katexCnr.innerText = "";
        katexCnr.appendChild(equationNode);
      }
    });

    return editorHtmlDomElement.innerHTML;
  }

  const trueFalseQuestion = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }
    const questionText: string = props.questionData.questionText;
    const options: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.options;
    let mcqText = "";
    if (props.questionGroupId && props.stimulus && getStimulus) {
      mcqText += getHtml(props.stimulus) || "";
    }
    mcqText += getHtml(questionText) || "";

    let optionsValue = "";
    [...options]
      .sort(() => {
        return Math.random() - 0.5;
      })
      .forEach((option: any, index) => {
        const optionText = getHtml(option.optionText) || "";
        optionsValue += `<p><b>${alphabets[index]}.</b> ${optionText!.replace(
          "<p",
          `<p style="display:inline"`
        )}</p>`;
      });
    return `
    ${mcqText}
    <br/>
    ${optionsValue}
    `;
  };
  const MultipleChoiceQuestion = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }
    const questionText: string = props.questionData.questionText;
    const options: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.options;
    let mcqText = "";
    if (props.questionGroupId && props.stimulus && getStimulus) {
      mcqText += getHtml(props.stimulus) || "";
    }
    mcqText += getHtml(questionText) || "";

    let optionsValue = "";
    [...options]
      .sort(() => {
        return Math.random() - 0.5;
      })
      .forEach((option: any, index) => {
        const optionText = getHtml(option.optionText) || "";
        optionsValue += `<p style="display:inline"><b>${
          alphabets[index]
        }.</b> ${optionText!.replace("<p", `<p style="display:inline"`)}</p>`;
      });
    return `
    ${mcqText}
    <br/>
    ${optionsValue}
    `;
  };
  const MultipleChoiceAnswer = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }
    const options: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.options;
    let optionsValue = "";
    options.forEach((option: any, index) => {
      if (!option.isCorrectAnswer) {
        return;
      }
      const optionText = getHtml(option.optionText) || "";
      optionsValue += `<p style="display:inline"><b>${
        alphabets[index]
      }.</b> ${optionText!.replace("<p", `<p style="display:inline"`)}</p>`;
    });
    return `
    <p>Correct Answer</p>
    <br/>
    ${optionsValue}
    `;
  };

  const FilltheBlanksAnswer = (props: any, getStimulus: boolean) => {
    if (!props) {
      return "";
    }
    let blanks_list = ``;
    (props.questionData.blanks || []).forEach((blank: any) => {
      const blanks = blank.correctAnswers;
      console.log(blanks);
      if (blanks && blanks.length > 0) {
        blanks.forEach((option: any, index: any) => {
          const optionText = getHtml(option.text) || "";
          blanks_list += `<p style="display:inline">${
            (index ? "/ " : "") + optionText
          }</p>`;
        });
      }
    });
    return `
    <p>Correct Answer</p>
    <br/>
    <div>${blanks_list}</div>
    <br/>
    `;
  };

  const FilltheBlanks = (props: any, getStimulus: boolean) => {
    if (!props) {
      return "";
    }
    const questionText: string = props.questionData.questionText;
    let mcqText = "";
    if (props.questionGroupId && props.stimulus && getStimulus) {
      mcqText += getHtml(props.stimulus) || "";
    }
    mcqText += getHtml(questionText) || "";
    let blankdiv = "";
    let blanks_list = ``;
    [...(props.questionData.blanks || [])]
      .sort(() => {
        return Math.random() - 0.5;
      })
      .forEach((blank: any) => {
        const blanks = blank.correctAnswers;
        if (blanks && blanks.length > 0) {
          blanks.forEach((option: any) => {
            const optionText = getHtml(option.text) || "";
            blanks_list += `${optionText.replace(
              "<p",
              `<p style="display:inline"`
            )}`;
            blanks_list += `&nbsp;&nbsp;&nbsp;&nbsp;`;
          });
        }
      });
    const distractors: any = props.questionData.distractors;
    if (distractors && distractors.length > 0) {
      distractors.forEach((distractor: any) => {
        const optionText = getHtml(distractor.text) || "";
        blanks_list += `${optionText.replace(
          "<p",
          `<p style="display:inline"`
        )}`;
        blanks_list += `&nbsp;&nbsp;&nbsp;&nbsp;`;
      });
    }
   

    if (props?.questionType == "fib") {
      if (!props?.isInstructionAdded) {
        blankdiv = `<div>Choose the correct options to complete the sentences </div>
    <div style="padding:5px;border:1px solid #000;display:inline-block;">${blanks_list}</div>`;
      }
    } else {
      blankdiv = `<div style="padding:5px;border:1px solid #000;display:inline-block;">${blanks_list}</div>`;
    } 
   
    return `
    
    <div>${blankdiv}</div>
    <br/>
    ${mcqText}
    `;
  };
  const MatchtheFolowing = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }

    const questionText: string = props.questionData.questionText;
    const optionskeys: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionKeys || [];
    const optionsvalues: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionValues || [];
    let mcqText = "";
    if (props.questionGroupId && props.stimulus && getStimulus) {
      mcqText += getHtml(props.stimulus) || "";
    }
    mcqText += getHtml(questionText) || "";
    let optionTableData = "";
    let optionAndValues: any = [];
    optionskeys.forEach((option: any, index) => {
      const optionText = getHtml(option.optionText) || "";
      optionAndValues.push({
        optionKey: `<div style="padding:5px"><b>${
          alphabets[index]
        }. </b>${optionText.replace("<p", `<p style="display:inline"`)}</div>`,
      });
    });
    [...optionsvalues]
      .sort(() => {
        return Math.random() - 0.5;
      })
      .forEach((option: any, index) => {
        const optionText = getHtml(option.optionText);
        const optionAndValue = optionAndValues[index];
        optionAndValue.optionValue = `<div style="padding:5px">${optionText}</div>`;
        optionAndValues[index] = optionAndValue;
      });
    optionTableData += `<table style="border: 1px solid black;border-spacing:0px;" style=""><tr><th style="border: 1px solid black">Options</th><th style="border: 1px solid black">Answers</th></tr>`;
    optionAndValues.forEach((opAndVal: any) => {
      optionTableData += `<tr>`;
      optionTableData += `<td style="border: 1px solid black" >${opAndVal.optionKey}</td>`;
      optionTableData += `<td style="border: 1px solid black" >${opAndVal.optionValue}</td>`;
      optionTableData += "</tr>";
    });
    optionTableData += `</table>`;

    return `
  ${mcqText}
  <br/>
  ${optionTableData}
  `;
  };
  const MatchtheFolowingAnswer = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }
    const optionskeys: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionKeys || [];
    const optionsvalues: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionValues || [];
    let optionTableData = "";
    let optionAndValues: any = [];
    optionskeys.forEach((option: any, index) => {
      const optionText = getHtml(option.optionText) || "";
      optionAndValues.push({
        optionKey: `<div style="padding:5px"><b>${
          alphabets[index]
        }. </b>${optionText.replace("<p", `<p style="display:inline"`)}</div>`,
      });
    });
    optionsvalues.forEach((option: any, index: any) => {
      const optionText = getHtml(option.optionText);
      const optionAndValue = optionAndValues[index];
      optionAndValue.optionValue = `<div style="padding:5px">${optionText}</div>`;
      optionAndValues[index] = optionAndValue;
    });

    optionTableData += `<table style="border: 1px solid black;border-spacing:0px;" style=""><tr><th style="border: 1px solid black">Options</th><th style="border: 1px solid black">Answers</th></tr>`;
    optionAndValues.forEach((opAndVal: any) => {
      optionTableData += `<tr>`;
      optionTableData += `<td style="border: 1px solid black" >${opAndVal.optionKey}</td>`;
      optionTableData += `<td style="border: 1px solid black" >${opAndVal.optionValue}</td>`;
      optionTableData += "</tr>";
    });
    optionTableData += `</table>`;

    return `
    <p>Correct Answer</p>
    <br/>
    ${optionTableData}
    `;
  };
  const ArragetheFollowing = (props: any, getStimulus: boolean): string => {
    if (!props) {
      return "";
    }
    const questionText: string = props.questionData.questionText;
    const optionsvalues: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionValues;
    let mcqText = "";
    if (props.questionGroupId && props.stimulus && getStimulus) {
      mcqText += getHtml(props.stimulus) || "";
    }
    mcqText += getHtml(questionText) || "";
    let options_values = "";
    [...optionsvalues]
      .sort(() => {
        return Math.random() - 0.5;
      })
      .forEach((option: any, index) => {
        const optionText = getHtml(option.optionText);
        options_values += `<div style="padding:5px"><b>${alphabets[index]}. <b/>${optionText}</div>`;
      });
    return `
  ${mcqText}
  <br/>
  <div>  
  ${options_values}
  </div>
  
  `;
  };
  const ArragetheFollowingAnswer = (
    props: any,
    getStimulus: boolean
  ): string => {
    if (!props) {
      return "";
    }
    const questionText: string = props.questionData.questionText;
    const optionsvalues: [
      {
        _id: string;
        optionText: string;
        isCorrectAnswer: boolean;
        misconception: string;
      }
    ] = props.questionData.optionValues;
    const mcqText = getHtml(questionText) || "";
    let options_values = "";
    optionsvalues.forEach((option: any, index) => {
      const optionText = getHtml(option.optionText);
      options_values += `<div style="padding:5px"><b>${alphabets[index]}. <b/>${optionText}</div>`;
    });
    return `
    <p>Correct Answer</p>
    <br/>
  ${mcqText}
  <br/>
  <div>  
  ${options_values}
  </div>
  
  `;
  };
  const findAndApplyPaper = (x: any, getStimulus: boolean) => {
    let questionText: string = ``;
    let answerText: string = ``;
    let lineandBox: any = ``;
    switch (x.questionType) {
      case "mcq":
        questionText = MultipleChoiceQuestion(x, false);
        answerText = MultipleChoiceAnswer(x, false);
        break;
      case "msq":
        questionText = MultipleChoiceQuestion(x, false);
        answerText = MultipleChoiceAnswer(x, false);
        break;
      case "tf":
        questionText = trueFalseQuestion(x, false);
        answerText = MultipleChoiceAnswer(x, false);
        break;
      case "mtf":
        questionText = MatchtheFolowing(x, false);
        answerText = MatchtheFolowingAnswer(x, false);
        break;
      case "fib":
        questionText = FilltheBlanks(x, false);
        answerText = FilltheBlanksAnswer(x, false);
        break;
      case "atf":
        questionText = ArragetheFollowing(x, false);
        answerText = ArragetheFollowingAnswer(x, false);
        break;
      case "la":
        if (x.questionGroupId && x.stimulus && false) {
          questionText += getHtml(x.stimulus) || "";
        }
        questionText += getHtml(x.questionData.questionText) || "";
        lineandBox += handleLineandBox(x);
        answerText = `<p>Correct Answer</p>
        <br/>${getHtml(x.questionData.answerGuideText)}`;
        break;

      default:
        questionText += getHtml(x.questionData.questionText) || "";
        answerText = `<p>Correct Answer</p>
        <br/>${getHtml(x.questionData.answerGuideText)}`;
        break;
    }
    if (getStimulus) {
      answerText += getAnswerCriteria(x);
    }
    return {
      ...x,
      questionTextHtml: questionText,
      answerGuideTextHtml: answerText,
      linesandBox: lineandBox,
    };
  };

  const getAnswerCriteria = (props: any) => {
    let answerCriteriaText = ``;

    if (props.answerCriteria && props.answerCriteria.length > 0) {
      answerCriteriaText = `<br/><br/><p>Answer Criteria</p><table style="border: 1px solid black;border-spacing:0px;"><tr><th style="border: 1px solid black">Criteria</th><th style="border: 1px solid black">&nbsp;&nbsp;Percentage&nbsp;&nbsp;</th></tr>`;
      props.answerCriteria.forEach((criteria: any) => {
        answerCriteriaText += `<tr>&nbsp;&nbsp;<td style="border: 1px solid black">${criteria.title}&nbsp;&nbsp;</td><td style="border: 1px solid black">&nbsp;&nbsp;${criteria.percentage}%&nbsp;&nbsp;</td></tr>`;
      });
      answerCriteriaText += `</table>`;
    }
    console.log("propsprops", answerCriteriaText);
    return answerCriteriaText;
  };

  const handleLineandBox = (props: any) => {
    let noOfLines = props.writingLines;
    let DrawingBoxSize = props.drawingBox;
    let lines: any = Array.from({ length: noOfLines }, (x, i) => {
      return `<br/><p>____________________________________________________</p>`;
    }).join("");
    let DrawingBox = ``;
    if (DrawingBoxSize == "1/4") {
      DrawingBox = `<div style="width:470px;height:250px;border:1px solid #000;"></div>`;
    }
    if (DrawingBoxSize == "1/2") {
      DrawingBox = `<div style="width:570px;height:350px;border:1px solid #000;"></div>`;
    }
    // Br
    return `<div style="display: flex;
    ">
    <div style=" float:left;">${lines}</div>
    <div style=" float:right;margin-top:35px; margin-left:30px">${DrawingBox}</div>
  </div>`;
  };
  const parseJsonDataToHtml = async (
    jsonData: any,
    isReplaceQuestionsData: boolean
  ) => {
    let previousGroupQuestionId = "ab";
    let currentGroupQuestionId = "";
    let currentQuestionPaperIndex = 0;
    let groupQuestionIndex = 1;

    let result = await {
      ...jsonData,
      short: jsonData.short.map((x: any) => {
        let getStimulus = false;
        let groupMarks = 0;
        if (x.questionGroupId) {
          currentGroupQuestionId = x.questionGroupId;
          if (currentGroupQuestionId !== previousGroupQuestionId) {
            currentQuestionPaperIndex++;
            groupQuestionIndex = 1;
            getStimulus = true;
            jsonData.short.forEach((shortQuestion: any) => {
              if (shortQuestion.questionGroupId === currentGroupQuestionId) {
                groupMarks += shortQuestion.questionMarks;
              }
            });
          } else {
            groupQuestionIndex++;
          }
        } else {
          groupQuestionIndex = 1;
          currentQuestionPaperIndex++;
        }

        const data = findAndApplyPaper(x, getStimulus);
        data.groupMarks = groupMarks;
        if (getStimulus && x.stimulus) {
          data.stimulusData = getHtml(x.stimulus) || "";
        }
        if (x.questionGroupId) {
          previousGroupQuestionId = x.questionGroupId;
          data.questionNo = `${(groupQuestionIndex + 9)
            .toString(36)
            .toUpperCase()}`;
          data.groupQuestion = true;
          if (groupQuestionIndex === 1) {
            data.stimulus = true;
            data.groupQuestionNo = currentQuestionPaperIndex;
            data.isSingleQsOr1stQsInGroup = true;
          } else {
            data.stimulus = false;
          }
        } else {
          data.questionNo = currentQuestionPaperIndex;
          data.groupQuestion = false;
          data.isSingleQsOr1stQsInGroup = true;
        }
        return data;
      }),
      long: jsonData.long.map((x: any) => {
        let getStimulus = false;
        let groupMarks = 0;
        if (x.questionGroupId) {
          currentGroupQuestionId = x.questionGroupId;
          if (currentGroupQuestionId !== previousGroupQuestionId) {
            currentQuestionPaperIndex++;
            groupQuestionIndex = 1;
            getStimulus = true;
            jsonData.long.forEach((shortQuestion: any) => {
              if (shortQuestion.questionGroupId === currentGroupQuestionId) {
                groupMarks += shortQuestion.questionMarks;
              }
            });
          } else {
            groupQuestionIndex++;
          }
        } else {
          groupQuestionIndex = 1;
          currentQuestionPaperIndex++;
        }

        const data = findAndApplyPaper(x, getStimulus);
        data.groupMarks = groupMarks;
        if (getStimulus && x.stimulus) {
          data.stimulusData = getHtml(x.stimulus) || "";
        }
        if (x.questionGroupId) {
          previousGroupQuestionId = x.questionGroupId;
          data.questionNo = `${(groupQuestionIndex + 9)
            .toString(36)
            .toUpperCase()}`;
          data.groupQuestion = true;
          if (groupQuestionIndex === 1) {
            data.stimulus = true;
            data.groupQuestionNo = currentQuestionPaperIndex;
            data.isSingleQsOr1stQsInGroup = true;
          } else {
            data.stimulus = false;
          }
        } else {
          data.questionNo = currentQuestionPaperIndex;
          data.groupQuestion = false;
          data.isSingleQsOr1stQsInGroup = true;
        }
        return data;
      }),
    };

    if (isReplaceQuestionsData === true) {
      setReplacementQuestionsUIData(result);
    } else {
      setRenderData(result);
    }
    console.log("Parsing JSON to HTML :", result);
  };

  const handleKeyDown = (event: any) => {
    const inputText = event.target.value;

    switch (event.keyCode) {
      case 13:
      case 9: {
        event.preventDefault();
        event.stopPropagation();

        addEmailIntoList(inputText);
        break;
      }
      default:
    }
  };

  const addEmailIntoList = (inputText: any) => {
    let finalValue: any[] = [];

    if (isValid(inputText)) {
      var emails = inputText.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

      if (emails) {
        var toBeAdded = emails.filter((email: any) => !isInList(email));
        finalValue = [...autoCompleteValue, ...toBeAdded];
        setAutoCompleteValue(finalValue);
        setTypingEmailAddress("");
      }
    }

    return finalValue;
  };

  const [errorText, setErrorText] = React.useState<any>(null);
  const [paperID] = useState(window.location.href.split("/")[6]);
  const [
    isReplaceQuestionsListDialogOpen,
    setIsReplaceQuestionListDialogOpen,
  ] = React.useState(false);
  const [toBeReplacedQuestion, setToBeReplacedQuestion] = React.useState({});
  const [
    replacementQuestionsUIData,
    setReplacementQuestionsUIData,
  ] = React.useState({});
  const [replacingQuestion, setReplacingQuestion] = React.useState({});
  const [
    replaceQuestionsFetchApiStatus,
    setReplaceQuestionsFetchApiStatus,
  ] = React.useState<String>("");

  const [
    isReplaceQuestionSaveDialogOpen,
    setIsReplaceQuestionSaveDialogOpen,
  ] = React.useState(false);
  const [
    replaceQuestionsSaveApiStatus,
    setReplaceQuestionsSaveApiStatus,
  ] = React.useState<String>("");
  const [
    replaceQuestionExecutionId,
    setReplaceQuestionExecutionId,
  ] = React.useState(null);

  const isValid = (email: string) => {
    let errorText = null;

    if (isInList(email)) {
      errorText = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errorText = `${email} is not a valid email address.`;
    }

    if (errorText) {
      setErrorText(errorText);
      return false;
    }

    return true;
  };

  const isInList = (email: any) => {
    return autoCompleteValue.includes(email);
  };

  const isEmail = (email: string) => {
    let regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regEmail.test(email);
  };

  const handleRefreshQuestion = (
    paperId: string,
    isShort: Boolean,
    questionId: string
  ) => {
    post("", {
      query: `query { replaceQuestion(paperId: \"${paperId}\" isShort: ${isShort}, questionId: \"${questionId}\"){ _id, questionGroupId, stimulus, difficulty, questionType, questionMarks,time,questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}}}`,
    }).then((res) => {
      console.log("Refresh test:", res.data.data);
      if (!res.data.data.replaceQuestion) {
        // Here we need to show the error that the question is not available
        return;
      }
      if (isShort) {
        const short = data.short.map((question: any) => {
          if (question._id == questionId) {
            return res.data.data.replaceQuestion;
          }
          return question;
        });
        setData({ ...data, short });
      } else {
        const long = data.long.map((question: any) => {
          if (question._id == questionId) {
            return res.data.data.replaceQuestion;
          }
          return question;
        });
        setData({ ...data, long });
      }
      console.log("data.short", data.short);
      console.log("data.long", data.long);
    });
  };

  const handleReplaceQuestion = (
    paperId: string,
    isShort: boolean,
    questionId: string,
    questionGroupId: string
  ) => {
    let isSameQuestion = false;
    if (
      (toBeReplacedQuestion as any).questionGroupId &&
      questionGroupId === (toBeReplacedQuestion as any).questionGroupId
    ) {
      isSameQuestion = true;
    } else if (
      (toBeReplacedQuestion as any).questionId &&
      questionId === (toBeReplacedQuestion as any).questionId
    ) {
      isSameQuestion = true;
    }

    setIsReplaceQuestionListDialogOpen(true);

    if (isSameQuestion) {
      setReplaceQuestionsFetchApiStatus("completed");
      return;
    }

    setReplaceQuestionsFetchApiStatus("in_progress");

    let toBeReplacedQuestions;
    if (isShort) {
      if (questionGroupId) {
        toBeReplacedQuestions = renderData.short.filter(
          (_d: any) => _d.questionGroupId === questionGroupId
        );
      } else {
        toBeReplacedQuestions = renderData.short.filter(
          (_d: any) => _d._id === questionId
        );
      }
    } else {
      if (questionGroupId) {
        toBeReplacedQuestions = renderData.long.filter(
          (_d: any) => _d.questionGroupId === questionGroupId
        );
      } else {
        toBeReplacedQuestions = renderData.long.filter(
          (_d: any) => _d._id === questionId
        );
      }
    }

    setToBeReplacedQuestion({
      paperId,
      isShort,
      questionId,
      questionGroupId,
      questions: toBeReplacedQuestions,
    });

    post("", {
      query: `query { 
        getReplacementQuestion(
          questionId: "${questionId}"
          paperId: "${paperId}"
          isShort: ${isShort}
          questionGroupId: "${questionGroupId || ""}"
        ){
          executionId
          questions {
            _id
            questionGroupId
            stimulus
            difficulty
            questionType
            questionMarks
            time
            questionData {
              questionText
              answerGuideText
              options {
                _id
                optionText
                isCorrectAnswer
                misconception
              }
              optionKeys {
                _id
                optionText
                sequence
              }
              optionValues {
                _id
                optionText
                sequence
              }
              blanks {
                _id
                correctAnswers {
                  _id
                  text
                }
                sequence
              }
              distractors {
                _id
                text
              }
            }
          }
        }
      }`,
    })
      .then((res) => {
        if (!res.data.data.getReplacementQuestion && res.data.data.errors) {
          setReplaceQuestionsFetchApiStatus("error");
        }

        setReplaceQuestionExecutionId(
          res.data.data.getReplacementQuestion.executionId
        );

        const data = {
          short: res.data.data.getReplacementQuestion.questions.filter(
            (_s: any) => !!_s
          ),
          long: [],
        };

        parseJsonDataToHtml(data, true);
        setReplaceQuestionsFetchApiStatus("completed");
      })
      .catch((e) => {
        handleError("Something went wrong");
        setReplaceQuestionsFetchApiStatus("error");
      });
  };

  const handleReplaceQuestionSave = () => {
    setIsReplaceQuestionListDialogOpen(false);
    setReplaceQuestionsSaveApiStatus("in_progress");
    setIsReplaceQuestionSaveDialogOpen(true);

    const { questionId, questionGroupId } = replacingQuestion as any;
    post("", {
      query: `
        mutation {
          replaceQuestionInQP(
            executionId: "${replaceQuestionExecutionId}"
            replaceQuestionId: "${questionId}"
            replaceQuestionGroupId: "${questionGroupId ? questionGroupId : ""}"
          )
        }
      `,
    })
      .then((res) => {
        if (res.data.data.replaceQuestionInQP) {
          setReplaceQuestionsSaveApiStatus("completed");

          getQuestionPaperQuestions();

          setTimeout(() => {
            setIsReplaceQuestionSaveDialogOpen(false);
          }, 1000);
        } else {
          setReplaceQuestionsSaveApiStatus("error");
        }
      })
      .catch((e) => {
        setReplaceQuestionsSaveApiStatus("error");
        handleError("Something went wrong. Please try again after some time.");
      });
  };

  const getQuestionPaperStatus = (event : any) => {
    if (paperID) {

      post("", {
        query: `query { viewQuestions(usedQuestionPaper:{paperId: "${paperID}"}){ weightedAverage,  previewId, short{ _id, isInstructionAdded questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}},long { _id, isInstructionAdded, questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}}, blocks { id,bId,title,displaySequence,noOfQuestions}, questionDetails { testType,minutes, marks, difficulty, questionType, testName,subjectName, gradeName, isSaveQuestion, isUsedPaper }, grade {id, name}, subject {id, name} originalInput { testType, minutes, marks, difficulty, questionType, blockIds }, assets { enqueuedAt status type format generatedAt assetId } }}`,
      }).then((res) => {
          const viewQuestions = res.data.data.viewQuestions;

          setAnchorEl(event);
          setData(viewQuestions);
        })
        .catch((e) => {
          console.log(e);
          handleError("Something went wrong");
        });
    }
  };

  const getQuestionPaperQuestions = () => {
    if (paperID) {
      console.log("opening test from datagrid");
      if (pageType === "preview") {
        setIsLoading(true);
      }
      post("", {
        query: `query { viewQuestions(usedQuestionPaper:{paperId: "${paperID}"}){ weightedAverage,  previewId, short{ _id,isInstructionAdded,  questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}},long { _id,isInstructionAdded, questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}}, blocks { id,bId,title,displaySequence,noOfQuestions}, questionDetails { testType,minutes, marks, difficulty, questionType, testName,subjectName, gradeName, isSaveQuestion, isUsedPaper }, grade {id, name}, subject {id, name} originalInput { testType, minutes, marks, difficulty, questionType, blockIds }, assets { enqueuedAt status type format generatedAt assetId } }}`,
      })
      .then((res) => {
          console.log("View test:", res.data.data);
          const viewQuestions = res.data.data.viewQuestions;
          const isSavedQuestion = viewQuestions.questionDetails.isSaveQuestion;

          if (isSavedQuestion) {
            setPageType("view");
          } else {
            setPageType("preview");

            const {
              testType,
              gradeName,
              subjectName,
            } = viewQuestions.questionDetails;
            post("", {
              query: `query { suggestedTestName( testType: "${testType}", gradeName: "${gradeName}", subjectName: "${subjectName}" ) { suggestion} }`,
            }).then((res) => {
              if (res.data.data) {
                setTestNameSuggestion(
                  res.data.data.suggestedTestName.suggestion
                );
              }
            });
          }

          setData(viewQuestions);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          handleError("Something went wrong");
        });
    }
  };

  useEffect(() => {
    getQuestionPaperQuestions();
    if (stores.QuestionsStore.Questions) {
      console.log("opening created questionpaper");
      setData(toJS(stores.QuestionsStore.Questions));
    }

    return () => {
      stores.QuestionsStore.changeQuestions(null);
    };
  }, []);

  useEffect(() => {
    /* eslint-disable */
    pageType === "view"
      ? window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          return (ev.returnValue = "Are you sure you want to close?");
        })
      : null;
    if (pageType === "preview") {
      window.addEventListener("popstate", onBackButtonEvent);
    }
    window.history.pushState(null, window.location.pathname);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    if (data) {
      parseJsonDataToHtml(data, false);
    }
  }, [data]);
  const shortMarks = renderData?.short.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.questionMarks),
    0
  );
  const longMarks = renderData?.long.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.questionMarks),
    0
  );

  const resetDialog = () => {
    setEmailSent(false);
    setAutoCompleteValue([]);
    setTypingEmailAddress("");
  };

  const originalBlocks =
    renderData && renderData.originalInput
      ? renderData?.blocks.filter((b: any) =>
          renderData?.originalInput?.blockIds.includes(parseInt(b.id))
        )
      : [];

  const getUser = () => {
    if (stores.userStore.academicYear) {
      return;
    }
    post("", {
      query:
        "query { getUser{ _id,  email,  name,  mobile, inSchoolId, allotment, academicYear}}",
    })
      .then((res) => {
        if (!res.data.data.getUser.inSchoolId) {
        }
        if (
          res.data.data.getUser.inSchoolId &&
          !res.data.data.getUser.allotment
        ) {
        }

        if (res.data.data.getUser.academicYear) {
          stores.userStore.setAcademicYear(res.data.data.getUser.academicYear);
        }

        stores.userStore.changeUser(res.data.data.getUser);
      })
      .catch((e) => console.log(e));
  };

  getUser();
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Block Number",
        accessor: "displaySequence"
      },
      {
        Header: "Number of questions",
        accessor: "noOfQuestions"
      }
    ],
    []
  );

  const tableData = renderData?.blocks
  .sort((a: any, b: any) => {
    if (a.displaySequence > b.displaySequence) {
      return 1;
    }
    return -1;
  }).map((data:any) => {
    // data.displaySequence = "B" + data.displaySequence;
    console.log("data renderData : ", data.displaySequence);
    return data;
  });
  
  return (
    <>
      {/* {isLoading? */}

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        <NavBar />
        <Header
          elevation={1}
          style={{
            // position: "-webkit-sticky" /* Safari & IE */,
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: "140px",
          }}
        >
          <StyledGrid container>
            <Grid
              container
              style={{
                marginTop: "-28px",
                marginLeft: screenWidth[width] > 2 ? "80px" : "15px",
              }}
            >
              {console.log(renderData && renderData, "renderData113")}
              <Grid item xs={12} md={6}>
                <div style={{ fontSize: 24, fontWeight: 700 }}>
                  {renderData
                    ? `${
                        renderData.questionDetails.subjectName +
                        " | " +
                        renderData.questionDetails.gradeName
                      } `
                    : "Loading..."}{" "}
                </div>
                <Breadcrumbs
                  style={{ cursor: "pointer" }}
                  separator={
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ color: "#000000" }}
                    />
                  }
                >
                  <LinkText onClick={() => Navigate(DashboardRoute)}>
                    Home
                  </LinkText>
                  <LinkText
                    onClick={() =>
                      Navigate(DashboardRoute, { state: { tab: 1 } })
                    }
                  >
                    Tests
                  </LinkText>
                  <LinkText>
                    {/* SA1 */}
                    {renderData
                      ? renderData.questionDetails.testName
                      : "Draft Test"}
                  </LinkText>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomizedButton
                  title={!viewAnswer ? "View all Answers" : "Hide all Answers"}
                  onClick={() => setViewAnswer(!viewAnswer)}
                  style={{
                    ...ButtonStyle,
                    ...{
                      float: "right",
                      width: `${screenWidth[width] > 2 ? "15em" : "100%"}`,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </StyledGrid>

          <Grid>
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={visible}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add Test Name
              </DialogTitle>
              <DialogContent>
                <form>
                  <TextField
                    id="testName"
                    variant="outlined"
                    label="Test Name"
                    value={values.testName}
                    name="testName"
                    type="string"
                    fullWidth
                    onChange={handleChange("testName")}
                    helperText={testNameErrorMessage}
                    error={testNameErrorMessage.length > 0}
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  style={{ background: "#af0000", color: "#fff" }}
                  disabled={testNameErrorMessage.length > 0}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Header>

        <div style={{ marginLeft: screenWidth[width] > 2 ? "95px" : "15px" }}>
          <Accordion
            className={classes.MuiAccordionroot}
            expanded={expanded === "panel4"}
            style={{
              marginTop: "10px",
              boxShadow: "none",
              backgroundColor: "white",
            }}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummary
              style={{ width: "100px" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                variant="subtitle1"
                style={{ fontSize: "1.1rem", fontWeight: "bold" }}
              >
                Blocks{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginTop: "-15px" }}>
              {console.log(renderData, "renderDatatrrr")}
              <Grid container direction="row" justify="flex-start" spacing={2}>
                {renderData?.blocks
                  .sort((a: any, b: any) => {
                    if (a.displaySequence > b.displaySequence) {
                      return 1;
                    }
                    return -1;
                  })
                  .map((block: any, index: any) => (
                    <Grid item style={{ marginTop: "6px" }}>
                      <RoundedButton
                        title={`Block ${block.displaySequence} - ${block.title}`}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        {/* </Header> */}

        <div id="divToPrint" ref={contentArea}>
          <Paper
            elevation={2}
            style={{
              margin:
                screenWidth[width] > 2
                  ? "10px auto 85px auto"
                  : "40px 0px 85px 0px",
              width: screenWidth[width] > 2 ? "85vw" : "100%",
              padding: "30px 20px 30px 20px",
            }}
          >
            <HeaderSection
              container
              alignItems="center"
              style={{
                padding: "22px",
                border: "1px solid #d8d8d8",
                marginBottom: "14px",
              }}
            >


            <Grid style={{ marginBottom: 20 }} container>
              <Grid item xs={10} className="grid2">

                <TestType
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.",
                  }}
                >
                  {stores.userStore.schoolDetails &&
                    stores.userStore.schoolDetails.name}
                </TestType>
                <Grid container style={{ marginBottom: 25, marginTop : 10 }}>
                  <FlexDiv style={{ display: "flex" }}>
                    <Text
                      style={{
                        width: screenWidth[width] > 2 ? "auto" : "100%",
                        fontWeight: 600,
                      }}
                    >
                      {renderData
                        ? renderData.questionDetails.testType +
                          " - " +
                          renderData.questionDetails.testName
                        : "Summmative Assessment - Test 2"}
                    </Text>
                  </FlexDiv>
                  
                </Grid>
                <Grid container>
                  <FlexDiv
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Blocks Included: &nbsp;
                    <span style={{ fontWeight: 700, display: "flex" }}>
                      {" "}
                      &nbsp;
                      { groupConsecutiveNumbers(renderData?.blocks.map((block: any) => block.displaySequence)) }
                      &nbsp;
                      {renderData &&
                      originalBlocks.length &&
                      renderData.blocks.length != originalBlocks.length ? (
                        <LightTooltip
                          placement="right"
                          title={`Original selection - Block: ${groupConsecutiveNumbers(
                            originalBlocks.map(
                              (block: any) => block.displaySequence
                            )
                          )}`}
                          arrow
                        >
                          <InfoOutlinedIcon
                            style={{ fontSize: "20px", color: "#AC2323" }}
                          />
                        </LightTooltip>
                      ) : null}
                    </span>
                  </FlexDiv>

                  <ReplaceIconButton onClick={handleOpenBlockDetail}>
                    <InfoOutlinedIcon
                      style={{ fontSize: "20px", color: "#000000" }}
                    />
                  </ReplaceIconButton>
                </Grid>

                <Grid container>
                  <FlexDiv
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Total number of questions: &nbsp;
                    <span style={{ fontWeight: 700, display: "flex" }}>
                      {" "}
                      {"12"}
                      &nbsp;
                    </span>
                  </FlexDiv>
                </Grid>
              </Grid>
              
              <Grid item xs={2} className="grid2">

                <TimeContainer
                  style={{
                    border:
                      screenWidth[width] > 2 ? "none" : "2px solid #979797",
                    marginLeft: "auto",
                    display: "block",
                    borderRadius: "25px",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "10rem"
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      marginTop: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <TestLevel
                      src={Difficulty}
                      alt=""
                      style={{ width: "32px", objectFit: "contain" }}
                    />

                    <TimeText
                      style={{
                        marginLeft: "10px",
                        marginRight: "0px",
                        fontSize: "16px",
                        marginTop: isvisible == true ? "" : "",
                      }}
                    >{`${
                      renderData
                        ? startCase(renderData.questionDetails.difficulty) + ": " + parseFloat(renderData.weightedAverage).toFixed(1) + " out of 10"
                        : ""
                    }`}</TimeText>
                    &nbsp;
                    {renderData &&
                    renderData.originalInput &&
                    renderData.questionDetails.difficulty.toLowerCase() !=
                      renderData.originalInput.difficulty.toLowerCase() ? (
                      <LightTooltip
                        placement="right"
                        title={`Original selection - ${startCase(
                          renderData.originalInput.difficulty
                        )}`}
                        arrow
                      >
                        <InfoOutlinedIcon
                          style={{ fontSize: "20px", color: "#AC2323" }}
                        />
                      </LightTooltip>
                    ) : null}
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      marginTop: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <QuestionDiv
                      src={Marks}
                      alt=""
                      style={{
                        width: "32px",
                        objectFit: "contain",
                        marginBottom: "2px",
                      }}
                    />
                    <TimeText
                      style={{
                        marginLeft: "10px",
                        marginRight: "0px",
                        fontSize: "16px",
                        marginTop: isvisible == true ? "" : "",
                      }}
                    >{`${
                      renderData ? renderData.questionDetails.marks : "0"
                    } Marks`}</TimeText>
                    &nbsp;
                    {renderData &&
                    renderData.originalInput &&
                    renderData.questionDetails.marks !=
                      renderData.originalInput.marks ? (
                      <LightTooltip
                        placement="right"
                        title={`Original selection - ${renderData.originalInput.marks}`}
                        arrow
                      >
                        <InfoOutlinedIcon
                          style={{ fontSize: "20px", color: "#AC2323" }}
                        />
                      </LightTooltip>
                    ) : null}
                  </Grid>
                  
                  <Grid style={{ display: "flex", marginTop: "2rem", alignItems: "center" }}>
                    <Clock
                      src={ClockImage}
                      alt=""
                      style={{ width: "32px", objectFit: "contain" }}
                    />
                    <TimeText
                      style={{ marginLeft: "10px", fontSize: "16px", marginRight: "0px" }}
                    >{`${
                      renderData ? renderData.questionDetails.minutes : "0"
                    } Minutes`}</TimeText>
                    &nbsp;
                    {renderData &&
                    renderData.originalInput &&
                    renderData.questionDetails.minutes !=
                      renderData.originalInput.minutes ? (
                      <LightTooltip
                        placement="right"
                        title={`Original selection - ${renderData.originalInput.minutes} mins`}
                        arrow
                      >
                        <InfoOutlinedIcon
                          style={{ fontSize: "20px", color: "#AC2323" }}
                        />
                      </LightTooltip>
                    ) : null}
                  </Grid>

            </TimeContainer>
              </Grid>
            </Grid>

            </HeaderSection>

            <HeaderSection
              container
              alignItems="center"
              style={{ padding: "22px" }}
            >
              <Grid item xs={12} md={4}>
                <Grid container wrap="nowrap">
                  <Heading
                    style={{
                      marginRight: "6px",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      color: "#3a3a3a",
                    }}
                    variant="subtitle2"
                  >
                    NAME:
                  </Heading>
                  <Dash />
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container wrap="nowrap">
                  <Heading
                    style={{
                      marginRight: "6px",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      color: "#3a3a3a",
                    }}
                    variant="subtitle2"
                  >
                    GRADE:
                  </Heading>
                  <Dash />
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid container wrap="nowrap">
                  <Heading
                    style={{
                      marginRight: "6px",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      color: "#3a3a3a",
                    }}
                    variant="subtitle2"
                  >
                    SECTION:
                  </Heading>
                  <Dash />
                </Grid>
              </Grid>
            </HeaderSection>

            <Grid
              style={
                screenWidth[width] > 2
                  ? {}
                  : { marginLeft: "15px", marginRight: "15px" }
              }
            >
              {/* Short answers */}
              <RectangleGrid
                container
                style={{
                  backgroundColor: "#af0000",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontWeight: 700,
                  fontSize: "1.2em",
                  color: "#ffffff",
                }}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">
                    PART I - Short Answer Questions
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    style={screenWidth[width] > 2 ? { float: "right" } : {}}
                  >
                    {shortMarks} Marks
                  </Typography>
                </Grid>
              </RectangleGrid>
              {renderData?.short.map((question: any, index: any) => (
                <div
                  className={`question-wrapper ${question._id} ${
                    question.questionGroupId || ""
                  }`}
                >
                  <Question
                    key={index}
                    question={question}
                    questionData={question.questionTextHtml}
                    answerData={question.answerGuideTextHtml}
                    viewAnswer={viewAnswer}
                    linesandBox={question.linesandBox}
                    sl_no={question.questionNo}
                    pageType={pageType}
                    visibleBtn={isvisible}
                    isShort={true}
                    paperId={data.previewId}
                    handleRefreshQuestion={handleRefreshQuestion}
                    groupQuestionId={question.questionGroupId}
                    stimulus={question.stimulus}
                    groupQuestionNo={question.groupQuestionNo}
                    stimulusData={question.stimulusData}
                    groupQuestion={question.groupQuestion}
                    groupMarks={question.groupMarks}
                    usedPapers={question.usedPapers}
                    showReplaceQuestionButton={
                      data.questionDetails.isUsedPaper != true
                    }
                    handleReplaceQuestion={handleReplaceQuestion}
                    isSingleQsOr1stQsInGroup={
                      question.isSingleQsOr1stQsInGroup == true
                    }
                    showRadioInPlaceOfRootLabel={false}
                    setReplacingQuestion={() => {}}
                    replacingQuestionId=""
                  />
                </div>
              ))}

              {/* Short and long answer */}
              {renderData && renderData.long && renderData.long.length > 0 && (
                <RectangleGrid
                  container
                  style={{
                    backgroundColor: "#af0000",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontWeight: 700,
                    fontSize: "1.2em",
                    color: "#ffffff",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      PART II - Long Answer Questions
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h6"
                      style={screenWidth[width] > 2 ? { float: "right" } : {}}
                    >
                      {longMarks} Marks
                    </Typography>
                  </Grid>
                </RectangleGrid>
              )}

              {renderData?.long.map((question: any, index: any) => (
                <Question
                  key={index}
                  question={question}
                  questionData={question.questionTextHtml}
                  answerData={question.answerGuideTextHtml}
                  viewAnswer={viewAnswer}
                  sl_no={question.questionNo}
                  pageType={pageType}
                  linesandBox={question.linesandBox}
                  visibleBtn={isvisible}
                  isShort={false}
                  paperId={data.previewId}
                  groupQuestionId={question.questionGroupId}
                  stimulus={question.stimulus}
                  groupQuestionNo={question.groupQuestionNo}
                  groupQuestion={question.groupQuestion}
                  stimulusData={question.stimulusData}
                  handleRefreshQuestion={handleRefreshQuestion}
                  groupMarks={question.groupMarks}
                  usedPapers={question.usedPapers}
                  showReplaceQuestionButton={
                    data.questionDetails.isUsedPaper != true
                  }
                  handleReplaceQuestion={handleReplaceQuestion}
                  isSingleQsOr1stQsInGroup={
                    question.isSingleQsOr1stQsInGroup == true
                  }
                  showRadioInPlaceOfRootLabel={false}
                  setReplacingQuestion={() => {}}
                  replacingQuestionId=""
                />
              ))}
            </Grid>
          </Paper>
        </div>
        {console.log(pageType && pageType, "pageType")}
        <Footer>
          <FooterContainer
            style={
              screenWidth[width] > 2
                ? { justifyContent: "flex-end" }
                : { justifyContent: "center" }
            }
          >
            {/* TODO Commented Email Dialog */}
            {pageType === "preview" && <CustomizedButton
              // title={pageType === "preview" ? "Discard" : "Share via Email"}
              // onClick={
              //   pageType === "preview"
              //     ? handleDialogDiscardModal
              //     : handleQuestionCloseAndEmail
              // }
              title="Discard"
              onClick={handleDialogDiscardModal}
              style={ButtonStyle}
            />}
            <CustomizedButton
              title={pageType === "preview" ? "Save" : "Download"}
              // onClick={handleModalOpen}handleDownload
              onClick={
                pageType === "preview" ? handleClickOpen : handleDownloadClick
              }
              style={{ ...ButtonStyle, width: "16em" }}
              color="red"
            />
            <Popover
              id={downloadPopupId}
              open={isDownloadPopupOpen}
              anchorEl={anchorEl}
              onClose={handleDownloadClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Grid container justify="center" alignItems="center">
                <div
                  style={{
                    padding: "20px",
                    color: "#666666",
                  }}
                >
                  Download as
                  <br />
                  <Grid container style={{ marginTop: "13px" }}>
                    <Image1 src={ renderData != null ? getImageIcon(getQPAssetDetail(renderData.assets, "student_test", "pdf", "status"), "pdf") : Pdf }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: renderData != null ? getStatusTextColor(getQPAssetDetail(renderData.assets, "student_test", "pdf", "status")) : '#666666',
                      }}
                      onClick={async () => {
                        setPageType("preview");
                        setIsLoading(true);
                        handleDownloadClose();

                        const _status = getQPAssetDetail(renderData.assets, "student_test", "pdf", "status");
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(
                            renderData.assets,
                            "student_test",
                            "pdf",
                            "assetId"
                          );

                          setPageType("view");
                          setIsLoading(false);

                          if (assetId) {
                            window.open(
                              `${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`,
                              "_blank"
                            );
                          } else {

                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {
                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                paperID +
                                "/requestPdfDocxFileGeneration?format=pdf&type=student_test",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res : any) => {
                            setPageType("view");
                            setIsLoading(false);
                            
                              if(res.data.status) {
                                renderData.assets = res.data.data.assets;
                                setRenderData(renderData);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }
                            })
                            .catch((err: any) => {
                              console.log(err);
                              setPageType("view");
                              setIsLoading(false);
                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        } else {
                          setPageType("view");
                          setIsLoading(false);
                        }
                      }}
                    >
                      {`${ renderData ? getDownloadStatusContent(getQPAssetDetail(renderData.assets, "student_test", "pdf", "status")) : "" }`}
                      Student Test (Recommended)
                    </TestType>
                  </Grid>
                  <Grid container style={{ marginTop: "13px" }}>
                    <Image1 src={ renderData != null ? getImageIcon(getQPAssetDetail(renderData.assets, "teacher_answer_guide", "pdf", "status"), "pdf") : Pdf }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: renderData != null ? getStatusTextColor(getQPAssetDetail(renderData.assets, "teacher_answer_guide", "pdf", "status")) : '#666666',
                      }}
                      onClick={async () => {
                        setPageType("preview");
                        setIsLoading(true);
                        handleDownloadClose();
                        
                        const _status = getQPAssetDetail( renderData.assets, "teacher_answer_guide", "pdf", "status")
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(renderData.assets, "teacher_answer_guide", "pdf", "assetId");

                          setPageType("view");
                          setIsLoading(false);

                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                paperID +
                                "/requestPdfDocxFileGeneration?format=pdf&type=teacher_answer_guide",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res:any) => {
                              setPageType("view");
                              setIsLoading(false);
                              
                              if(res.data.status) {
                                renderData.assets = res.data.data.assets;
                                setRenderData(renderData);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }
                            })
                            .catch((err: Error) => {
                              console.log(err);
                              setPageType("view");
                              setIsLoading(false);
                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        } else {
                          setPageType("view");
                          setIsLoading(false);
                        }
                      }}
                    >
                      
                      {`${ renderData ? getDownloadStatusContent(getQPAssetDetail( renderData.assets, "teacher_answer_guide", "pdf", "status")) : "" }`}
                      Teacher's Answer Guide (Recommended)
                    </TestType>
                  </Grid>
                  <Grid
                    className="beta-version"
                    container
                    style={{ marginTop: "13px" }}
                  >
                    <Image1 src={ renderData != null ? getImageIcon(getQPAssetDetail(renderData.assets, "student_test", "docx", "status"), "docx") : Word }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: renderData != null ? getStatusTextColor(getQPAssetDetail(renderData.assets, "student_test", "docx", "status")) : '#666666',
                      }}
                      onClick={async () => {
                        setPageType("preview");
                        setIsLoading(true);
                        handleDownloadClose();

                        const _status = getQPAssetDetail(renderData.assets, "student_test", "docx", "status");
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(renderData.assets, "student_test", "docx", "assetId");

                          setPageType("view");
                          setIsLoading(false);

                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {
                          
                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                paperID +
                                "/requestPdfDocxFileGeneration?format=docx&type=student_test",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res:any) => {
                              setPageType("view");
                              setIsLoading(false);

                              if(res.data.status) {
                                renderData.assets = res.data.data.assets;
                                setRenderData(renderData);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }
                            })
                            .catch((err: any) => {
                              setPageType("view");
                              setIsLoading(false);
                              console.log(err);
                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        } else {
                          setPageType("view");
                          setIsLoading(false);
                        }
                      }}
                    >
                    {`${ renderData ? getDownloadStatusContent(getQPAssetDetail( renderData.assets, "student_test", "docx", "status")) : "" }`}
                      Student Test
                    </TestType>
                  </Grid>
                  <Grid
                    className="beta-version"
                    container
                    style={{ marginTop: "13px" }}
                  >
                    <Image1 src={ renderData != null ? getImageIcon(getQPAssetDetail(renderData.assets, "teacher_answer_guide", "docx", "status"), "docx") : Word }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: renderData != null ? getStatusTextColor(getQPAssetDetail(renderData.assets, "teacher_answer_guide", "docx", "status")) : '#666666',
                      }}
                      onClick={async () => {
                        setPageType("preview");
                        setIsLoading(true);
                        handleDownloadClose();
                        
                        const _status = getQPAssetDetail( renderData.assets, "teacher_answer_guide", "docx", "status");
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(renderData.assets, "teacher_answer_guide", "docx", "assetId");

                          setPageType("view");
                          setIsLoading(false);

                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                paperID +
                                "/requestPdfDocxFileGeneration?format=docx&type=teacher_answer_guide",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res:any) => {
                              setPageType("view");
                              setIsLoading(false);

                              if(res.data.status) {
                                renderData.assets = res.data.data.assets;
                                setRenderData(renderData);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }
                            })
                            .catch((err: any) => {
                              setPageType("view");
                              setIsLoading(false);
                              console.log(err);
                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        } else {
                          setPageType("view");
                          setIsLoading(false);
                        }
                      }}
                    >
                    {`${ renderData ? getDownloadStatusContent(getQPAssetDetail( renderData.assets, "teacher_answer_guide", "docx", "status")) : "" }`}
                      Teacher's Answer Guide
                    </TestType>
                  </Grid>
                </div>
              </Grid>
            </Popover>
          </FooterContainer>
        </Footer>

        <CustomizedDialog
          open={discardDialogOpen}
          title={"Information"}
          toggleDialog={() => {
            setDiscardDialogOpen(!discardDialogOpen);
          }}
          actionTitle={"Confirm"}
          cancelBtn={"Cancel"}
          content={
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: 120 }}
            >
              <DialogText>
                The Test is not saved. Are you sure you want to proceed?
              </DialogText>
            </Grid>
          }
          onConfirm={handleDialogModalDiscard}
          onClose={() => {
            setDiscardDialogOpen(false);
          }}
        />

        <CustomizedDialog
          open={dialogOpen}
          title={"Information"}
          toggleDialog={() => {
            setDialogOpen(!dialogOpen);
          }}
          actionTitle={"Confirm"}
          cancelBtn={"Cancel"}
          content={
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: 120 }}
            >
              <DialogText>
                {pageType === "preview"
                  ? "You have not saved the test. Are you sure you want to navigate?"
                  : "Are you sure you want go back?"}
              </DialogText>
            </Grid>
          }
          onConfirm={
            pageType === "preview"
              ? handleDialogModalDiscard
              : handleDialogModalClose
          }
          onClose={() => {
            setDialogOpen(false);
          }}
        />
        {pageType === "preview" ? (
          <CustomizedDialog
            open={refreshDialogOpen}
            title={"Information"}
            toggleDialog={() => {
              setRefreshDialogOpen(!refreshDialogOpen);
            }}
            actionTitle={"Confirm"}
            cancelBtn={"Cancel"}
            content={
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: 120 }}
              >
                <DialogText>Changes that you made may not be saved.</DialogText>
              </Grid>
            }
            onConfirm={handleDialogModalClose}
            onClose={() => {
              setRefreshDialogOpen(false);
            }}
          />
        ) : null}

        {/* <CustomizedDialog
          open={open}
          toggleDialog={() => {
            setOpen(!open);
            resetDialog();
          }}
          onClose={() => {
            setOpen(false);
            resetDialog();
          }}
          cancelBtn={"cancel"}
          title={"Share Via e-mail"}
          actionTitle={!emailSent && !isEmailSendInProgress ? "Send" : ""}
          content={
            isEmailSendInProgress ? (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: 120 }}
              >
                <CircularProgress />
                <DialogText>Loading...</DialogText>
              </Grid>
            ) : !emailSent ? (
              <>
                <DialogTitle
                  id="form-dialog-title"
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  Share via email
                </DialogTitle>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={[]}
                  freeSolo={true}
                  value={autoCompleteValue}
                  onChange={(event, newValue) => {
                    setAutoCompleteValue(newValue);
                  }}
                  renderInput={(params: any) => {
                    // setOnInput(params.inputProps.value);
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Enter email"
                        placeholder="email"
                        margin="normal"
                        fullWidth
                        onChange={(e: any) => {
                          setTypingEmailAddress(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          setErrorText(null);
                          handleKeyDown(e);
                        }}
                      />
                    );
                  }}
                />
                {errorText && <p style={{ color: "red" }}>{errorText}</p>}
              </>
            ) : (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: 120 }}
              >
                <TickImage src={StatusTick} alt="" />
                <DialogText>Sent</DialogText>
              </Grid>
            )
          }
          onConfirm={handleSendEmail}
        /> */}

        <BlockDetailDialog
          open={blockDetailOpen}
          toggleDialog={() => {
            setBlockDialogOpen(!blockDetailOpen);
          }}
          onClose={() => {
            setBlockDialogOpen(false);
          }}
          content={
            <>
              <DialogTitle
                id="form-dialog-title"
                onClose={() => {
                  setBlockDialogOpen(false);
                }}
              >
              </DialogTitle>
              <Styles>
                
                <Table columns={columns} data={tableData} />

              </Styles>
              
              {errorText && <p style={{ color: "red" }}>{errorText}</p>}
            </>
          }
        />
      </div>

      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={isReplaceQuestionsListDialogOpen}
        onClose={() => setIsReplaceQuestionListDialogOpen(false)}
      >
        <DialogContent>
          <div style={{ padding: "10px" }}>
            <h2 style={{ paddingBottom: "20px", paddingLeft: "10px" }}>
              Instead Of
            </h2>
            {((toBeReplacedQuestion as any).questions || []).map(
              (question: any, index: any) => (
                <Question
                  key={index}
                  question={question}
                  questionData={question.questionTextHtml}
                  answerData={question.answerGuideTextHtml}
                  viewAnswer={viewAnswer}
                  sl_no={question.questionNo}
                  pageType={pageType}
                  linesandBox={question.linesandBox}
                  visibleBtn={isvisible}
                  isShort={false}
                  paperId={data.previewId}
                  groupQuestionId={question.questionGroupId}
                  stimulus={question.stimulus}
                  groupQuestionNo={question.groupQuestionNo}
                  groupQuestion={!!question.questionGroupId}
                  stimulusData={question.stimulusData}
                  handleRefreshQuestion={handleRefreshQuestion}
                  groupMarks={question.groupMarks}
                  usedPapers={question.usedPapers}
                  showReplaceQuestionButton={false}
                  handleReplaceQuestion={handleReplaceQuestion}
                  isSingleQsOr1stQsInGroup={
                    question.isSingleQsOr1stQsInGroup == true
                  }
                  showRadioInPlaceOfRootLabel={false}
                  setReplacingQuestion={() => {}}
                  replacingQuestionId=""
                />
              )
            )}
            <h2 style={{ paddingBottom: "20px", paddingLeft: "10px" }}>Use</h2>
            {replaceQuestionsFetchApiStatus == "in_progress" ? (
              <div>Fetching questions in progress...</div>
            ) : replaceQuestionsFetchApiStatus == "completed" ? (
              ((replacementQuestionsUIData as any).short || []).length == 0 ? (
                <div>No Questions available to replace</div>
              ) : (
                <div>
                  {((replacementQuestionsUIData as any).short || []).map(
                    (question: any, index: any) => (
                      <Question
                        key={index}
                        question={question}
                        questionData={question.questionTextHtml}
                        answerData={question.answerGuideTextHtml}
                        viewAnswer={viewAnswer}
                        sl_no={question.questionNo}
                        pageType={pageType}
                        linesandBox={question.linesandBox}
                        visibleBtn={isvisible}
                        isShort={false}
                        paperId={data.previewId}
                        groupQuestionId={question.questionGroupId}
                        stimulus={question.stimulus}
                        groupQuestionNo={question.groupQuestionNo}
                        groupQuestion={!!question.questionGroupId}
                        stimulusData={question.stimulusData}
                        handleRefreshQuestion={handleRefreshQuestion}
                        groupMarks={question.groupMarks}
                        usedPapers={question.usedPapers}
                        showReplaceQuestionButton={false}
                        handleReplaceQuestion={handleReplaceQuestion}
                        isSingleQsOr1stQsInGroup={
                          question.isSingleQsOr1stQsInGroup == true
                        }
                        showRadioInPlaceOfRootLabel={true}
                        setReplacingQuestion={setReplacingQuestion}
                        replacingQuestionId={
                          (replacingQuestion as any).questionId
                        }
                      />
                    )
                  )}
                </div>
              )
            ) : replaceQuestionsFetchApiStatus == "error" ? (
              <div>Something went wrong. Please try again after sometime.</div>
            ) : (
              <div></div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomizedButton
            title="Cancel"
            onClick={() => {
              setReplacingQuestion({});
              setIsReplaceQuestionListDialogOpen(false);
            }}
            variant="outlined"
            style={{ ...ButtonStyle, width: "16em" }}
          />
          <CustomizedButton
            title="Apply"
            disabled={!(replacingQuestion as any).questionId}
            variant="contained"
            onClick={handleReplaceQuestionSave}
            style={{ ...ButtonStyle, width: "16em" }}
            color="red"
          />
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={isReplaceQuestionSaveDialogOpen}
        onClose={() => {}}
      >
        <DialogContent>
          {replaceQuestionsSaveApiStatus == "in_progress" ? (
            <div style={{ textAlign: "center" }}>Saving in progress...</div>
          ) : replaceQuestionsSaveApiStatus == "completed" ? (
            <div style={{ textAlign: "center" }}>
              The question has been replaced successfully!
            </div>
          ) : replaceQuestionsSaveApiStatus == "error" ? (
            <div>Something went wrong. Please try again after some time.</div>
          ) : (
            <div></div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default injectWithObserver(PreviewQuestionPaper);

const Header = styled(Paper)`
  padding: 96px 20px 30px 20px;
  width: 100%;
`;
const StyledGrid = styled(Grid)`
  // border-top: 1px solid #979797;
  padding-top: 10px;
`;

const Heading = styled(Typography)`
  margin-right: 6px;
  font-weight: bold;
  font-size: 1.2rem;
  color: #000000;
`;
const Text = styled(Heading)`
  font-weight: 400;
`;
const TestType = styled(Typography)`
  font-weight: bold;
  font-size: 1.3rem;
`;
const LinkText = styled(Link)`
  color: #000000;
  font-weight: bold;
  font-size: 1.1rem;
`;

const DropDown = styled(FormControl)`
  border-radius: 1.2rem;
  background-color: #f4e0e0;
  margin-top: 8px;
  font-size: 1.1rem;
  font-weight: 400;
  height: 30px;
  color: #000000;
  text-transform: none;
  & .MuiInputBase-root .MuiSelect-root {
    border-radius: 1.2rem;
    background-color: #f4e0e0;
    padding: 0px 0px 0px 20px;
    font-size: 1rem;
    color: #000000;
    font-weight: 400;
  }
`;
const ButtonStyle = {
  float: "right",
  fontWeight: 700,
  fontSize: "0.9em",
  width: "15em",
};
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
  box-shadow: 0px 0 3px rgb(169 169 169);
  background-color: #ffffff;
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 30px;
  & .MuiButtonBase-root {
    width: 150px;
    margin-right: 1.6em;
  }
`;

const ImageDiv = styled.div``;

const HeaderSection = styled(Grid)``;

const Clock = styled.img`
  width: 25px;
  object-fit: contain;
`;

const TestLevel = styled.img`
  width: 25px;
  object-fit: contain;
`;

const QuestionDiv = styled.img`
  width: 25px;
  object-fit: contain;
`;

const TimeText = styled(Text)`
  font-size: 1.2em;
  margin-left: 10px;
  margin-right: 0px;
`;
const FlexDiv = styled.div``;
const TimeContainer = styled.div`
  display: flex;
  margin-left: auto;
  border-radius: 25px;
  // width: 8em;
  justify-content: center;
  align-items: center;
`;
const Dash = styled.div`
  border-bottom: 2px solid black;
  width: 75%;
  margin-bottom: 5px;
  margin-right: 10px;
`;
const RectangleGrid = styled(Grid)`
  background-color: #af0000;
  padding-left: 20px;
  padding-right: 20px;
  & .MuiTypography-root {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.3em;
  }
`;

export const TickImage = styled.img`
  width: 60px;
  height: 50px;
  object-fit: contain;
`;

export const DialogText = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;
const Image1 = styled.img`
  object-fit: contain;
  width: 25px;
  margin-right: 10px;
`;
export const Dialog1 = styled(Typography)`
position: 'absolute',
left: 10,
top: 50
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;
const StrikeText = styled(Text)`
  text-decoration: line-through;
`;
const ReplaceIconButton = styled(Button)``;
