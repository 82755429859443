/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  Button,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Popper,
  PopperPlacementType,
  Typography,
  TextField,
  Box,
  Tooltip,
  Popover,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { toJS } from "mobx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Download from "../../../assets/images/icons/Download .png";
import Share from "../../../assets/images/icons/Share.png";
import Preview from "../../../assets/images/icons/Preview.png";
import StatusGreyTick from "../../../assets/images/StatusGreyTick.svg";
import StatusRedTick from "../../../assets/images/StatusRedTick.svg";
import StatusTick from "../../../assets/images/StatusTick.svg";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay/CustomLoadingOverLay";
import CustomizedDialog from "../../../components/Modal/CustomizedDialog";
import useWidth from "../../../hooks/useWidth";
import {
  DashboardRoute,
  QuestionPaperRoute,
} from "../../../Routes/RoutesConstants";
import { post } from "../../../utils/Api";
import DayFromNow from "../../../utils/DayFromNow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { SubjectProps, GradeProps } from "../ViewQuestion";
import { getQPAssetDetail, getDownloadStatusContent, getImageIcon, getStatusTextColor, FILE_GENERATION_ERROR_STATUSES } from "../../../helpers/common";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useSnackbar from "../../../hooks/useSnackbar";
import firebase from "firebase";
import firebaseApp from "../../../firebase";
const { startCase } = require("lodash");
const axios = require('axios');

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "flex",
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -20,
      left: -20,
      zIndex: 1,
    },
    whiteButton: {
      color: "#474747",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#FFFFFF",
      },
    },
    redButton: {
      backgroundColor: "#AC2323",
      color: "#FFFFFF",
      textTransform: "none",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#AC2323",
      },
    },
  })
);

const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "black",
    marginTop: "-2px",
    backgroundColor: "transparent",
  },
})(Tooltip);
interface Data {
  id: number;
  _id: number;
  isSaveQuestion: boolean;
  isUsedPaper: boolean;
  aapUserId: string;
  marks: number;
  minutes: number;
  questionType: string;
  difficulty: string;
  testName: string;
  testType: string;
  createdAt: number;
  createdBy: string;
}
function getWeight(difficulty: any): number {
  switch (difficulty) {
    case "easy":
      return 1;
    case "medium":
      return 2;

    case "hard":
      return 3;
    default:
      return 0;
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "difficulty") {
    const aValue: number = getWeight(a[orderBy]);
    const bValue: number = getWeight(b[orderBy]);
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: any, comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map(
    (el: any, index: number) => [el, index] as [T, number]
  );
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

const headCells = [
  {
    id: "_id",
    label: "S.No",
  },
  { id: "testName", label: "Test Name" },
  { id: "testType", label: "Test Type" },
  {
    id: "difficulty",
    label: "Difficulty Level",
  },
  // { id: "isSaveQuestion", label: "Preview" },
  {
    id: "createdAt",
    label: "Time Created",
  },
  { id: "minutes", label: "Time" },
  { id: "marks", label: "Marks" },
  {
    id: "createdBy",
    label: "Created By",
  },
  {
    id: "isUsedPaper",
    label: "Test Status",
  },
  {
    id: "download",
    label: "Actions",
  },
  // {
  //   id: "view",
  //   label: "Action",
  // },
];
function EnhancedTableHead(props: any) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isMobile,
    handleFilterClick,
  } = props;
  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
  </TableCell> */}
        {!isMobile ? (
          // For Desktop column
          <>
            {headCells.map((headCell) => (
              <TableCell
                align="center"
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                style={headCell.label === "S.No" ? customColumnStyle : {}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {headCell.id === "testName" ? (
                    <div>
                      {/* <Text style={{ fontWeight: 600 }}>Test Type</Text> */}
                      <Text style={{ fontWeight: 600 }}>Test Name </Text>
                    </div>
                  ) : (
                    <Text style={{ fontWeight: 600 }}> {headCell.label} </Text>
                  )}
                </TableSortLabel>
              </TableCell>
            ))}
          </>
        ) : (
          // For Mobile column
          <>
            <TableCell
              key={headCells[0].id}
              sortDirection={orderBy === headCells[0].id ? order : false}
              style={customColumnStyle}
            >
              <TableSortLabel
                active={orderBy === headCells[0].id}
                direction={orderBy === headCells[0].id ? order : "asc"}
                onClick={createSortHandler(headCells[0].id)}
                IconComponent={KeyboardArrowDownIcon}
              >
                <Text style={{ fontWeight: 600 }}> {headCells[0].label} </Text>
              </TableSortLabel>
            </TableCell>
            <TableCell
              key={headCells[1].id}
              sortDirection={orderBy === headCells[1].id ? order : false}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <TableSortLabel
                active={orderBy === headCells[1].id}
                direction={orderBy === headCells[1].id ? order : "asc"}
                onClick={createSortHandler(headCells[1].id)}
                IconComponent={KeyboardArrowDownIcon}
              >
                <div>
                  {/* <Text style={{ fontWeight: 600 }}>Test Type</Text> */}
                  <Text style={{ fontWeight: 600 }}>Test Name </Text>
                </div>
              </TableSortLabel>
              <IconButton
                aria-label="filter list"
                onClick={handleFilterClick("bottom")}
              >
                <FilterListIcon />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

function CollapsibleArea(props: any) {
  const { timeCreated, minutes, createdBy, marks } = props;
  return (
    <Grid container>
      <BorderedGrid item xs={6}>
        <MobileText>Created On</MobileText>
        <MobileCaption>{timeCreated}</MobileCaption>
      </BorderedGrid>
      <BorderedGrid item xs={6}>
        <MobileText>Time</MobileText>
        <MobileCaption>{minutes + "mins"}</MobileCaption>
      </BorderedGrid>
      <BorderedGrid item xs={6}>
        <MobileText>Created By</MobileText>
        <MobileCaption>{createdBy}</MobileCaption>
      </BorderedGrid>
      <BorderedGrid item xs={6}>
        <MobileText>Marks</MobileText>
        <MobileCaption>{marks}</MobileCaption>
      </BorderedGrid>
    </Grid>
  );
}
const NoDataOverlay = () => (
  <div
    style={{
      width: "100%",
      top: 0,
      bottom: 0,
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    No Test Found.
  </div>
);

const Row = (props: any) => {
  const classes = useStyles();
  const snackBar = useSnackbar();

  const {
    row,
    index,
    isItemSelected,
    labelId,
    handleClick,
    isMobile,
    updatePaperID,
    selected,
    Navigate,
  } = props;
  const [qpAssets, setQpAssets] = useState(row.assets || []);
  const [expandRow, setExpandRow] = useState(false);
  const [open, setOpen] = useState(false);

  const [isopen, setIsOpen] = React.useState(false);
  const [selectedPaperID, setSelecetedPaperID] = React.useState<any>("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>([]);
  const [typingEmailAddress, setTypingEmailAddress] = React.useState<any>("");
  const [isEmailSendInProgress, setIsEmailSendInProgress] = React.useState(
    false
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDownloadClick = (event: any, selectedPaperID: any) => {
    let checkStatus = qpAssets.filter((e: any) => (e.status !== "completed" && e.status !== "not_started"));
    if(checkStatus.length > 0) {
      getQuestionPaperStatus(selectedPaperID, event);
    } else {
      setAnchorEl(event);
    }
  };

  const getQuestionPaperStatus = (selectedPaperID: any, event: any) => {
    if (selectedPaperID) {
      post("", {
        query: `query { viewQuestions(usedQuestionPaper:{paperId: "${selectedPaperID}"}){ weightedAverage,  previewId, short{ _id, isInstructionAdded, questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}},long { _id, isInstructionAdded, questionGroupId, stimulus, difficulty, questionType, usedPapers { testName }, questionMarks,time,answerCriteria {_id, title, percentage},questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}}, blocks { id,bId,title,displaySequence,noOfQuestions}, questionDetails { testType,minutes, marks, difficulty, questionType, testName,subjectName, gradeName, isSaveQuestion, isUsedPaper }, grade {id, name}, subject {id, name} originalInput { testType, minutes, marks, difficulty, questionType, blockIds }, assets { enqueuedAt status type format generatedAt assetId } }}`,
      })
        .then((res) => {
          const viewQuestions = res.data.data.viewQuestions;
          setQpAssets(viewQuestions.assets);
          setAnchorEl(event);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const isDownloadPopupOpen = Boolean(anchorEl);
  const downloadPopupId = isDownloadPopupOpen ? "download-popover" : undefined;

  const [pageType, setPageType] = useState("preview");
  const [emailSent, setEmailSent] = useState(false);
  const handleQuestionCloseAndEmail = () => {
    if (pageType === "preview") {
      Navigate(DashboardRoute);
    } else {
      // Open sent email dialog here
      setOpen(true);
      setEmailSent(false);
    }
  };
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleEmail = () => {
    let emails = [];
    if (typingEmailAddress.length) {
      emails = addEmailIntoList(typingEmailAddress);
    } else {
      emails = autoCompleteValue;
    }

    const emailString = emails.toString();
    if (emailString) {
      setIsEmailSendInProgress(true);
      post("", {
        query: `query { sendQuestionPaper (emailId: \"${emailString}\",paperId: \"${selectedPaperID}\"){  base64FileContent, downloadExt } }`,
      })
        .then((res) => {
          setIsEmailSendInProgress(false);
          setEmailSent(true);
          console.log("email", res.data);
        })
        .catch((e) => {
          setIsEmailSendInProgress(false);
          console.log(e, "email");
        });
    } else {
      setErrorText("Enter valid email address");
      return false;
    }
  };

  const handleClickOpen = (row: any) => {
    setIsOpen(true);
    setSelecetedPaperID(row._id);
  };

  const handleKeyDown = (event: any) => {
    const inputText = event.target.value;

    switch (event.keyCode) {
      case 13:
      case 9: {
        event.preventDefault();
        event.stopPropagation();

        addEmailIntoList(inputText);
        break;
      }
      default:
    }
  };

  const addEmailIntoList = (inputText: any) => {
    let finalValue: any[] = [];

    if (isValid(inputText)) {
      var emails = inputText.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

      if (emails) {
        var toBeAdded = emails.filter((email: any) => !isInList(email));
        finalValue = [...autoCompleteValue, ...toBeAdded];
        setAutoCompleteValue(finalValue);
        setTypingEmailAddress("");
      }
    }

    return finalValue;
  };

  const [onInputText, setOnInput] = React.useState<any>("");
  const [errorText, setErrorText] = React.useState<any>(null);
  const navigate = useNavigate();
  const isValid = (email: string) => {
    let errorText = null;

    if (isInList(email)) {
      errorText = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errorText = `${email} is not a valid email address.`;
    }

    if (errorText) {
      setErrorText(errorText);
      return false;
    }

    return true;
  };

  const isInList = (email: any) => {
    return autoCompleteValue.includes(email);
  };

  const isEmail = (email: string) => {
    let regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regEmail.test(email);
  };

  const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const resetDialog = () => {
    setEmailSent(false);
    setAutoCompleteValue([]);
    setTypingEmailAddress("");
  };

  return (
    <React.Fragment>
      <CustomizedDialog
        open={isopen}
        toggleDialog={() => {
          resetDialog();
          setIsOpen(!isopen);
        }}
        onClose={() => {
          resetDialog();
          setIsOpen(false);
        }}
        cancelBtn={"cancel"}
        title={"Share Via e-mail"}
        actionTitle={!emailSent && !isEmailSendInProgress ? "Send" : ""}
        content={
          isEmailSendInProgress ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: 120 }}
            >
              <CircularProgress />
              <DialogText>Loading...</DialogText>
            </Grid>
          ) : !emailSent ? (
            <>
              <DialogTitle
                id="form-dialog-title"
                onClose={() => {
                  setIsOpen(false);
                }}
              >
                Share via email
              </DialogTitle>

              {/* <ChipInput
                style={{ width: "100%" }}
                onKeyDown={(e: any) => {
                  handleKeyUp(e);
                }}
                allowDuplicates={false}
                onChange={(e: any) => {
                  setText(e);
                }}
              /> */}

              <Autocomplete
                multiple
                id="tags-outlined"
                options={[]}
                freeSolo={true}
                value={autoCompleteValue}
                onChange={(event, newValue) => {
                  setAutoCompleteValue(newValue);
                }}
                renderInput={(params: any) => {
                  setOnInput(params.inputProps.value);
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Enter email"
                      placeholder="email"
                      margin="normal"
                      fullWidth
                      onChange={(e: any) => {
                        setTypingEmailAddress(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        setErrorText(null);
                        handleKeyDown(e);
                      }}
                    />
                  );
                }}
              />
              {errorText && <p style={{ color: "red" }}>{errorText}</p>}
            </>
          ) : (
            // <TextField
            //   id='email'
            //   label='Enter e-mail to share with'
            //   variant='outlined'
            //   style={{ width: '60%', minWidth: '220px' }}
            //   onChange={(e) => setEmail(e.target.value)}
            // />
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: 120 }}
            >
              <TickImage src={StatusTick} alt="" />
              <DialogText>Sent</DialogText>
            </Grid>
          )
        }
        onConfirm={handleEmail}
      />
      <TableRow
        hover
        /*onClick={(event) => handleClick(event, row._id)}*/
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        {/*<TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
  </TableCell> */}
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="center"
          style={customColumnStyle}
        >
          <Text>{index + 1}</Text>
        </TableCell>
        <TableCell align="center" scope="row" padding="none">
          {!isMobile ? (
            // For Desktop
            <div>
              <Text>{row.testName}</Text>
              {/* <Text style={{ fontSize: 12 }}>{row.testType}</Text> */}
            </div>
          ) : (
            // For Mobile
            <Grid container>
              <Grid container justify="space-between">
                <div>
                  <MobileText>{row.testType}</MobileText>
                  <MobileCaption>{row.testName}</MobileCaption>
                </div>
                <div>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setExpandRow(!expandRow);
                    }}
                  >
                    {expandRow ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </Grid>
              <Grid container justify="space-between">
                <CustomButton
                  onClick={() =>
                    !row.isUsedPaper
                      ? updatePaperID(row._id, row.aapUserId)
                      : console.log(row._id, " is already used")
                  }
                >
                  <Image
                    style={{ width: 15 }}
                    src={row.isUsedPaper ? StatusRedTick : StatusGreyTick}
                    alt=""
                  />
                  <MobileText style={{ fontSize: 12 }}>
                    {row.isUsedPaper ? "Used" : "Not Yet Used"}
                  </MobileText>
                </CustomButton>

                <DifficultyButton
                  variant="contained"
                  style={{
                    fontSize: 12,
                    backgroundColor:
                      row.difficulty === "easy"
                        ? "#1A73E8"
                        : row.difficulty === "hard"
                        ? "#E67C73"
                        : "#0B8043",
                  }}
                >
                  {row.difficulty}
                </DifficultyButton>
                {/* <PreviewButton
                  variant="outlined"
                  disabled={selected.length > 1}
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    console.log(row._id);
                    Navigate(QuestionPaperRoute, {
                      state: { paperID: row._id },
                    });
                  }}
                >
                  View
                </PreviewButton> */}
              </Grid>
            </Grid>
          )}
        </TableCell>
        <TableCell>
          <Text>{row.testType}</Text>
        </TableCell>
        {!isMobile ? (
          <>
            <TableCell>
              <DifficultyButton
                variant="contained"
                fullWidth
                disableElevation
                disableFocusRipple
                style={{
                  backgroundColor:
                    row.difficulty === "easy"
                      ? "RGB(26, 115, 232)"
                      : row.difficulty === "hard"
                      ? "RGB(230, 124, 115)"
                      : "RGB(11, 128, 67)",
                }}
              >
                {startCase(row.difficulty)}
              </DifficultyButton>
            </TableCell>
            <TableCell>
              <Text>{DayFromNow(row.createdAt)}</Text>
            </TableCell>
            <TableCell>
              <Text>{row.minutes} mins</Text>
            </TableCell>
            <TableCell>
              <Text>{row.marks}</Text>
            </TableCell>
            <TableCell>
              <Text>{row.createdBy}</Text>
            </TableCell>
            <TableCell align="center">
              <CustomButton
                fullWidth
                onClick={() =>
                  !row.isUsedPaper
                    ? updatePaperID(row._id, row.aapUserId)
                    : console.log(row._id, " is already used")
                }
              >
                <Image
                  style={{ width: 15 }}
                  src={row.isUsedPaper ? StatusRedTick : StatusGreyTick}
                  alt=""
                />
                <Text style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                  {row.isUsedPaper ? "Used" : "Not Yet Used"}
                </Text>
              </CustomButton>
            </TableCell>
            <TableCell align="center">
              <div className={classes.wrapper}>
                <TextOnlyTooltip title="Download">
                  <Image src={Download} onClick={(event) => {
                     handleDownloadClick(event.currentTarget, row._id)
                    }}></Image>
                </TextOnlyTooltip>

                {loading && (
                  <CircularProgress size={68} className={classes.fabProgress} />
                )}
                <TextOnlyTooltip title="Preview">
                  <Image
                    style={{ marginLeft: "15px" }}
                    src={Preview}
                    onClick={() => {
                      navigate(QuestionPaperRoute.replace(":id", row._id));
                    }}
                  ></Image>
                </TextOnlyTooltip>
                {/* <TextOnlyTooltip title="Share">
                  <Image
                    style={{ marginLeft: "15px" }}
                    src={Share}
                    onClick={() => handleClickOpen(row)}
                  ></Image>
                </TextOnlyTooltip> */}
              </div>
            </TableCell>

            <Popover
              id={downloadPopupId}
              open={isDownloadPopupOpen}
              anchorEl={anchorEl}
              onClose={handleDownloadClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Grid container justify="center" alignItems="center">
                <div
                  style={{
                    padding: "20px",
                    color: "#666666",
                  }}
                >
                  Download as
                  <br />
                  <Grid container style={{ marginTop: "13px" }}>
                    <Image1 src={ getImageIcon(getQPAssetDetail(qpAssets, "student_test", "pdf", "status"), "pdf") }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: getStatusTextColor(getQPAssetDetail(qpAssets, "student_test", "pdf", "status")),
                      }}
                      onClick={async () => {
                        const _status = getQPAssetDetail(qpAssets, "student_test", "pdf", "status")
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(
                            qpAssets,
                            "student_test",
                            "pdf",
                            "assetId"
                          );
                          if (assetId) {
                            window.open(
                              `${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`,
                              "_blank"
                            );
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          handleDownloadClose();
                          setSuccess(false);
                          setLoading(true);

                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                row._id +
                                "/requestPdfDocxFileGeneration?format=pdf&type=student_test",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res : any) => {
                              setLoading(false);

                              if(res.data.status) {
                                setQpAssets(res.data.data.assets);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }

                            })
                            .catch((err: any) => {
                              console.log(err);
                              setLoading(false);
                              snackBar.show(
                                "Something went wrong, Please try ag  ain later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        }
                      }}
                    >
                      {getDownloadStatusContent(getQPAssetDetail( qpAssets, "student_test", "pdf", "status"))} Student Test (Recommended)
                    </TestType>
                  </Grid>
                  <Grid container style={{ marginTop: "13px" }}>
                    <Image1 src={ getImageIcon(getQPAssetDetail(qpAssets, "teacher_answer_guide", "pdf", "status"), "pdf") }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: getStatusTextColor(getQPAssetDetail(qpAssets, "teacher_answer_guide", "pdf", "status")),
                      }}
                      onClick={async () => {
                        const _status = getQPAssetDetail( qpAssets, "teacher_answer_guide", "pdf", "status")
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(qpAssets, "teacher_answer_guide", "pdf", "assetId");
                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          handleDownloadClose();
                          setSuccess(false);
                          setLoading(true);
                          
                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                row._id +
                                "/requestPdfDocxFileGeneration?format=pdf&type=teacher_answer_guide",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res : any) => {
                              setLoading(false);

                              if(res.data.status) {
                                setQpAssets(res.data.data.assets);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }

                            })
                            .catch((err: any) => {
                              console.log(err);
                              setLoading(false);

                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        }
                      }}
                    >
                      {getDownloadStatusContent(getQPAssetDetail( qpAssets, "teacher_answer_guide", "pdf", "status"))} Teacher's Answer
                      Guide (Recommended)
                    </TestType>
                  </Grid>
                  <Grid
                    className="beta-version"
                    container
                    style={{ marginTop: "13px" }}
                  >
                    <Image1 src={ getImageIcon(getQPAssetDetail( qpAssets, "student_test", "docx", "status"), "word") }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: getStatusTextColor(getQPAssetDetail(qpAssets, "student_test", "docx", "status")),
                      }}
                      onClick={async () => {
                        const _status = getQPAssetDetail(qpAssets, "student_test", "docx", "status");
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(qpAssets, "student_test", "docx", "assetId");
                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          handleDownloadClose();
                          setSuccess(false);
                          setLoading(true);
                          
                          axios
                            .post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                row._id +
                                "/requestPdfDocxFileGeneration?format=docx&type=student_test",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res : any) => {
                              setLoading(false);

                              if(res.data.status) {
                                setQpAssets(res.data.data.assets);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }

                            })
                            .catch((err: any) => {
                              console.log(err);
                              setLoading(false);

                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        }
                      }}
                    >
                      {getDownloadStatusContent(getQPAssetDetail( qpAssets, "student_test", "docx", "status"))} Student Test
                    </TestType>
                  </Grid>
                  <Grid
                    className="beta-version"
                    container
                    style={{ marginTop: "13px" }}
                  >
                    <Image1 src={ getImageIcon(getQPAssetDetail( qpAssets, "teacher_answer_guide", "docx", "status"), "word") }></Image1>
                    <TestType
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: getStatusTextColor(getQPAssetDetail(qpAssets, "teacher_answer_guide", "docx", "status")),
                      }}
                      onClick={async () => {
                        const _status = getQPAssetDetail(qpAssets, "teacher_answer_guide", "docx", "status");
                        if(_status === 'completed') {
                          const assetId = getQPAssetDetail(qpAssets, "teacher_answer_guide", "docx", "assetId");
                          if(assetId) {
                            window.open(`${process.env.REACT_APP_REPORT_API_ENDPOINT}/testmaker/question_paper/files/${assetId}`, "_blank")
                          } else {
                            snackBar.show(
                              "Something went wrong, Please try again later.",
                              "error",
                              { vertical: "bottom", horizontal: "center" },
                              true
                            );
                          }
                        } else if(_status === 'not_started' || FILE_GENERATION_ERROR_STATUSES.includes(_status)) {

                          handleDownloadClose();
                          setSuccess(false);
                          setLoading(true);

                          axios.post(
                              process.env.REACT_APP_TEST_MAKER_V2_URL +
                                "/api/question_paper/" +
                                row._id +
                                "/requestPdfDocxFileGeneration?format=docx&type=teacher_answer_guide",
                                {},
                                {
                                  headers: {
                                    "authorization": await firebaseApp.AuthIdToken()
                                  }
                                }
                            )
                            .then((res : any) => {
                              setLoading(false);

                              if(res.data.status) {
                                setQpAssets(res.data.data.assets);
                                snackBar.show(
                                  res.data.message,
                                  "success",
                                  { vertical: "bottom", horizontal: "center" },
                                  true,
                                  3000
                                );
                              } else {
                                snackBar.show(
                                  res.data.message,
                                  "error",
                                  { vertical: "bottom", horizontal: "center" },
                                  true
                                );
                              }
                            })
                            .catch((err: any) => {
                              console.log(err);
                              setLoading(false);

                              snackBar.show(
                                "Something went wrong, Please try again later.",
                                "error",
                                { vertical: "bottom", horizontal: "center" },
                                true
                              );
                            });
                        }
                      }}
                    >
                      {getDownloadStatusContent(getQPAssetDetail( qpAssets, "teacher_answer_guide", "docx", "status"))} Teacher's Answer
                      Guide
                    </TestType>
                  </Grid>
                </div>
              </Grid>
            </Popover>
          </>
        ) : null}
      </TableRow>
      {isMobile ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={expandRow} timeout="auto" unmountOnExit>
              <CollapsibleArea
                timeCreated={DayFromNow(row.createdAt)}
                minutes={row.minutes}
                createdBy={row.createdBy}
                marks={row.marks}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};;

function DataGridTable({
  filterApplied,
  formRef,
  stores,
  refresh,
}: {
  filterApplied: string;
  formRef: any;
  stores: any;
  refresh: number;
}) {
  const snackBar = useSnackbar();
  const [myQuestionPaperList, setMyQuestionPaperList] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [paperIDForUpdate, setPaperIDForUpdate] = useState("false");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [selected, setSelected] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popOpen, setPopOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const pageNumber = useRef(1);
  const totalPages = useRef(0);
  const GridRef = useRef<Element | null>(null);
  const width = useWidth();
  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  const Navigate = useNavigate();
  const [user_ID, setUserID] = useState("");
  const [dialogTxt, setDialogTxt] = useState(false);
  const {
    SelectedGrade,
    SelectedSubject,
    PaperID,
    FilterAppliedValue,
    SavedQuestionPaperValues,
  } = toJS(stores.FormDataStore);
  useEffect(() => {
    if (FilterAppliedValue && SavedQuestionPaperValues) {
      setFilterValue();
    }
  }, [SelectedGrade, SelectedSubject]);

  // useEffect(() => {
  //   getMyQuestionPaperList();
  // }, [refresh]);
  console.log("FilterFormValues", SavedQuestionPaperValues);
  const setFilterValue = () => {
    const {
      blocks,
      difficulty,
      minutes,
      marks,
      objectiveType,
      questionType,
      testName,
      testType,
    } = SavedQuestionPaperValues;
    formRef.current.setFieldValue("testType", testType);
    formRef.current.setFieldValue("blocks", blocks);
    formRef.current.setFieldValue("difficulty", difficulty);
    formRef.current.setFieldValue("questionType", questionType);
    formRef.current.setFieldValue("testName", testName);
    formRef.current.setFieldValue("minutes", minutes);
    formRef.current.setFieldValue("marks", marks);
    formRef.current.setFieldValue("objectiveType", objectiveType);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRequestSortMobile = (property: keyof Data, order: string) => {
    const isAsc = order === "asc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = myQuestionPaperList.map((n: any) => n.testType);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, _id: string) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;

  const handleFilterClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPopOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const formObjectWithKey = (array: string[], key: string) => {
    let newString: string = "[";
    array.map((i) => {
      newString += `{${key}: \"${i}\"}`;
    });
    return newString;
  };
  const getDataQuery = () => {
    console.log("incoming", SelectedSubject);
    if (FilterAppliedValue && SavedQuestionPaperValues) {
      filterApplied = filterApplied ? filterApplied : FilterAppliedValue;
      formRef.current.values = SavedQuestionPaperValues;
    }
    if (filterApplied !== "" && formRef.current) {
      let {
        blocks,
        difficulty,
        minutes,
        marks,
        objectiveType,
        questionType,
        testName,
        testType,
      } = formRef.current.values;
      let data = [];
      data.push(testName.testName);
      testName = data[0] ? [data] : [];
      stores.FormDataStore.SavedQpaper(formRef.current.values);
      const subjectIds: string[] = []
      SelectedSubject.map((subject: SubjectProps) => {subjectIds.push(subject.id)})
      const standardIds: string[] = []
      SelectedGrade.map((grade: GradeProps) => {standardIds.push(grade.id)})
      //  If Minutes array index 1 is 120  then it should be 180.
      const getMins = () => {
        if (minutes[1] === 120) {
          return `\"[${minutes[0]}, 180]\"`;
        } else {
          return `\"[${minutes}]\"`;
        }
      };
      //  If Marks array index 1 is 100  then it should be 180.
      const getMarks = () => {
        if (marks[1] === 100) {
          return `\"[${marks[0]}, 100]\"`;
        } else {
          return `\"[${marks}]\"`;
        }
      };
      return `mutation { questionFilter(filter: { subjectIds: [${
        subjectIds
      }], standardIds: [${
        standardIds
      }], testType: ${formObjectWithKey(
        testType,
        "type"
      )}], blockId: \"[${blocks}]\", objectiveType: \"${objectiveType}\", time: ${getMins()}, marks: ${getMarks()},  difficulty:${formObjectWithKey(
        difficulty,
        "diffiType"
      )}], questionType : ${formObjectWithKey(
        questionType,
        "qType"
      )}], testName:${formObjectWithKey(
        testName,
        "name"
      )}]}) { questionResult { _id, isSaveQuestion, isUsedPaper, aapUserId, marks, minutes, questionType, difficulty, testName, testType, createdAt, createdBy }} }`;
    } else {
      console.log("incoming931", SelectedSubject, SelectedGrade);
      let subjectIds: string[] = []
      let gradeIds: string[] = []
      if (SelectedSubject.length) {
        SelectedSubject.map((subject: SubjectProps) => {
          subjectIds.push(subject.id)
        })
      }
      if (SelectedGrade.length) {
        SelectedGrade.map((grade: GradeProps) => {
          gradeIds.push(grade.id)
        })
      }
      return `query { myQuestionsList(myQuestion: {pagerNumber: ${pageNumber.current},subjectIds: [${subjectIds}], standardIds: [${gradeIds}]}){ pages,  questionResult { _id, isSaveQuestion, aapUserId, marks, minutes, questionType, difficulty, testName, testType, createdAt, createdBy,isUsedPaper, assets { enqueuedAt status type format generatedAt assetId } } } }`;
    }
  };
  const getMyQuestionPaperList = () => {
    setLoading(true);
    post("", {
      query: getDataQuery(),
    })
      .then((res) => {
        console.log("Get My Question lists:", res.data);
        // if(!filterApplied&&refresh){
        //   setMyQuestionPaperList(res.data.data.myQuestionsList.questionResult);
        // }

        if (filterApplied === "") {
          console.log("incoming", res.data);
          setMyQuestionPaperList((previousState) => [
            ...previousState,
            ...res.data.data.myQuestionsList.questionResult,
          ]);
          totalPages.current = res.data.data.myQuestionsList.pages;
        } else {
          console.log("incoming", res.data);
          totalPages.current = 1;
          pageNumber.current = 1;
          setMyQuestionPaperList(res.data.data.questionFilter.questionResult);
        }
        setLoading(false);
        if (!GridRef.current) {
          setEventListner();
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const updatePaperID = async (id: string, aapUserId: string) => {
    setOpen(true);
    setDialogTxt(true);
    setPaperIDForUpdate(id);
  };
  const updateQuestionUsed = () => {
    post("", {
      query: `query { usedQuestion(usedQuestionPaper:{paperId: \"${paperIDForUpdate}\"}){   message,   paperId }}`,
    })
      .then((res) => {
        if(res.data && res.data.errors) {
          setPaperIDForUpdate("");
          setOpen(false);
          snackBar.show(
            `${res.data.errors.map((e : any) => e.message).join(', ')}.`,
            "error",
            { vertical: "bottom", horizontal: "center" },
            true
          );
        } else {
          console.log("Changed usedQuestion paper:", res.data);
          var data = [...myQuestionPaperList];
          var index = data.findIndex((obj) => obj._id === paperIDForUpdate);
          data[index].isUsedPaper = true;
          setMyQuestionPaperList(data);
          setPaperIDForUpdate("");
          setOpen(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleScroll = (e: any) => {
    var node = e.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    // console.log("trigger scroll");
    if (bottom && !loading && totalPages.current > pageNumber.current) {
      setLoading(true);
      pageNumber.current++;
      console.log("Changing pagenumber to :", pageNumber.current);
      getMyQuestionPaperList();
    }
  };
  const setEventListner = () => {
    const element = document.querySelector(".MuiTableContainer-root");
    GridRef.current = element;
    if (GridRef.current) {
      console.log("Initializing Event for grid scroll");
      GridRef.current.addEventListener("scroll", handleScroll);
      GridRef.current.addEventListener("touchmove", handleScroll);
    }
  };
  const checkActiveSort = (id: string, orderType: string) => {
    return orderBy === id && order === orderType;
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setMyQuestionPaperList([]);
    getMyQuestionPaperList();
  }, [filterApplied, formRef, refresh]);

  useEffect(() => {
    return () => {
      console.log("Scroll event clean up");
      if (GridRef.current) {
        GridRef.current.removeEventListener("scroll", handleScroll);
        GridRef.current.removeEventListener("touchmove", handleScroll);
      }
    };
  }, []);
  useEffect(() => {
    setRows(
      myQuestionPaperList.map((row: any, index: number) => {
        const { ...rest } = row;
        return { id: index + 1, ...rest };
      })
    );
  }, [myQuestionPaperList]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header>Saved Tests</Header>
      </Grid>
      <Popper
        open={popOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopOver>
              <PopHeading>Created On</PopHeading>
              <StyledButton
                onClick={(event) => handleRequestSortMobile("createdAt", "asc")}
                disabled={checkActiveSort("createdAt", "asc")}
              >
                <PopCaption>Low to High</PopCaption>
                {checkActiveSort("createdAt", "asc") ? <Dot /> : null}
              </StyledButton>

              <StyledButton
                onClick={(event) =>
                  handleRequestSortMobile("createdAt", "desc")
                }
                disabled={checkActiveSort("createdAt", "desc")}
              >
                <PopCaption>High to Low</PopCaption>
                {checkActiveSort("createdAt", "desc") ? <Dot /> : null}
              </StyledButton>

              <PopHeading>Marks</PopHeading>
              <StyledButton
                onClick={(event) => handleRequestSortMobile("marks", "asc")}
                disabled={checkActiveSort("marks", "asc")}
              >
                <PopCaption>Low to High</PopCaption>
                {checkActiveSort("marks", "asc") ? <Dot /> : null}
              </StyledButton>
              <StyledButton
                onClick={(event) => handleRequestSortMobile("marks", "desc")}
                disabled={checkActiveSort("marks", "desc")}
              >
                <PopCaption>High to Low</PopCaption>
                {checkActiveSort("marks", "desc") ? <Dot /> : null}
              </StyledButton>

              <PopHeading>Created By</PopHeading>
              <StyledButton
                onClick={(event) => handleRequestSortMobile("createdBy", "asc")}
                disabled={checkActiveSort("createdBy", "asc")}
              >
                <PopCaption>Low to High</PopCaption>
                {checkActiveSort("createdBy", "asc") ? <Dot /> : null}
              </StyledButton>
              <StyledButton
                onClick={(event) =>
                  handleRequestSortMobile("createdBy", "desc")
                }
                disabled={checkActiveSort("createdBy", "desc")}
              >
                <PopCaption>High to Low</PopCaption>
                {checkActiveSort("createdBy", "desc") ? <Dot /> : null}
              </StyledButton>

              <PopHeading>Test Status</PopHeading>
              <StyledButton
                onClick={(event) =>
                  handleRequestSortMobile("isUsedPaper", "asc")
                }
                disabled={checkActiveSort("isUsedPaper", "asc")}
              >
                <PopCaption>Low to High</PopCaption>
                {checkActiveSort("isUsedPaper", "asc") ? <Dot /> : null}
              </StyledButton>
              <StyledButton
                onClick={(event) =>
                  handleRequestSortMobile("isUsedPaper", "desc")
                }
                disabled={checkActiveSort("isUsedPaper", "desc")}
              >
                <PopCaption>High to Low</PopCaption>
                {checkActiveSort("isUsedPaper", "desc") ? <Dot /> : null}
              </StyledButton>
            </PopOver>
          </Fade>
        )}
      </Popper>
      <div
        style={{
          position: "relative",
          maxHeight: 400,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Paper>
          <StyledTableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                isMobile={screenWidth[width] <= 2}
                handleFilterClick={handleFilterClick}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row: any, index: number) => (
                    <Row
                      key={row.id}
                      row={row}
                      index={index}
                      isItemSelected={isSelected(row._id)}
                      labelId={`enhanced-table-checkbox-${index}`}
                      handleClick={handleClick}
                      isMobile={screenWidth[width] <= 2}
                      updatePaperID={updatePaperID}
                      selected={selected}
                      Navigate={Navigate}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          {/* <Grid container>
            <FooterContainer
              style={
                screenWidth[width] > 2
                  ? { justifyContent: "flex-end" }
                  : { flexDirection: "column", justifyContent: "center" }
              }
            >
              <CustomizedButton
                title="Share via e-mail"
                onClick={() => {
                  console.log("test");
                }}
                style={screenWidth[width] > 2 ? {} : { marginRight: 0, marginBottom: 10, width: "12em" }}
              />
              <div>
                <Popper open={visible} anchorEl={anchorEl} placement={placement} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <List>
                          <ListItem button onClick={() => setVisible(false)} key={"pdf"}>
                            <ListItemText primary={"pdf"} />
                          </ListItem>
                          <ListItem autoFocus button onClick={() => handleListItemClick("addAccount")}>
                            <ListItemText primary="word" />
                          </ListItem>
                        </List>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </div>
              <CustomizedButton
                title={"Download PDF"}
                onClick={handleClickdownloadviapdf("top-start")}
                color="red"
                style={screenWidth[width] > 2 ? {} : { marginRight: 0, marginBottom: 10 }}
              ></CustomizedButton>
            </FooterContainer>
                  </Grid> */}
        </Paper>
        {loading ? <CustomLoadingOverlay /> : ""}
        {rows.length === 0 && !loading ? <NoDataOverlay /> : ""}
      </div>
      <CustomizedDialog
        open={open}
        toggleDialog={() => {
          setOpen(!open);
        }}
        title={"Information"}
        actionTitle={dialogTxt ? "Confirm" : "OK"}
        cancelBtn={"Cancel"}
        content={
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: 120 }}
          >
            <DialogText>
              {dialogTxt
                ? " Are you sure want to set the test to used. This action cannot be undone ."
                : "Sorry, you are not allowed to perform this action since you are not the creator of this test."}
            </DialogText>
          </Grid>
        }
        onConfirm={
          dialogTxt
            ? updateQuestionUsed
            : () => {
                setOpen(false);
              }
        }
        onClose={() => {
          setOpen(false);
        }}
      />

    </Grid>
  );
}

export default DataGridTable;

const Header = styled(Typography)`
  margin-top: 1.5rem;
  color: #000000;
  margin-bottom: 10px;
  font-size: 1.3em;
  font-weight: 700;
`;
const DifficultyButton = styled(Button)`
  cursor: text;
  border-radius: 20px;
  color: white;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: none;
`;
const PreviewButton = styled(DifficultyButton)`
  border-radius: 5px;
  color: black;
`;
const StyledTableContainer = styled(TableContainer)`
  border-top: 5px solid #af0000;
  min-height: 150px;
  max-height: 400px;
  margin-bottom: 10px;
  & .MuiTableCell-stickyHeader {
    background-color: white;
  }
  & .MuiTableBody-root {
    background-color: #f6f6f6;
  }
  & .MuiTableRow-root.Mui-selected {
    background-color: none;
  }
  & .MuiCheckbox-colorSecondary.Mui-checked {
    color: #af0000;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
  & .MuiButtonBase-root {
    width: 15em;
    margin-right: 1.6em;
    font-weight: 700;
    font-size: 0.9em;
  }
`;
const Image = styled.img`
  object-fit: contain;
  width: 25px;
  margin-right: 10px;
  cursor: pointer;
`;
const Text = styled(Typography)`
  color: #000000;
  font-size: 16px;
`;
const MobileText = styled(Text)`
  font-weight: 700;
`;
const MobileCaption = styled(Text)`
  font-size: 12px;
`;
const DialogText = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;
const CustomButton = styled(Button)`
  text-transform: none;
  justify-content: end;
  & .muiinputbase-root: {
    & .muibutton-text: {
      padding: "6px 8px 6px 0px";
    }
  }
`;
const BorderedGrid = styled(Grid)`
  border: 1px solid #dddddd;
  padding: 10px;
`;
const customColumnStyle = {
  maxWidth: 65,
};

const PopOver = styled(Paper)`
  background-color: #fafafa;
  padding: 15px;
  display: flex;
  flex-direction: column;
  & .MuiIconButton-root.Mui-disabled {
    color: #000000;
  }
`;
const PopHeading = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;
const PopCaption = styled(PopHeading)`
  font-weight: 400;
`;
const Dot = styled.div`
  margin-left: 3px;
  background-color: red;
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;
const StyledButton = styled(IconButton)`
  color: #000000;
  padding: 0;
`;
export const TickImage = styled.img`
  width: 60px;
  height: 50px;
  object-fit: contain;
`;
const Image1 = styled.img`
  object-fit: contain;
  width: 25px;
  margin-right: 10px;
`;
const TestType = styled(Typography)`
  font-weight: bold;
  font-size: 1.3rem;
`;
