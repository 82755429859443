import axios from "axios";
import firebase from "firebase";
import firebaseApp from "../firebase";

// export const get = async (endpoint: string) => {
//   const res = await fetch(`${API_URL}${endpoint}`);
//   const json = await res.json();
//   return json;
// };

const REACT_APP_CAA_BACKEND_API_ENDPOINT = `${process.env.REACT_APP_CAA_BACKEND_API_ENDPOINT}`;

export const post = async (
  endpoint: string,
  bodyObj: any = {},
  header: any = {}
) => {
  const mainHeader = {
    authorization: await firebaseApp.AuthIdToken(),
  };
  const url =
    endpoint.length > 0
      ? REACT_APP_CAA_BACKEND_API_ENDPOINT
      : process.env.REACT_APP_TESTMAKER_GRAPHQL_ENDPOINT;
  
  if (
    endpoint ===
    `${process.env.REACT_APP_VERIFY_PASSWORD_API_ENDPOINT}/verifyPassword`
  ) {
    const res = await axios(
      `${process.env.REACT_APP_VERIFY_PASSWORD_API_ENDPOINT}/verifyPassword`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          ...mainHeader,
          ...header,
        },
        // body: urlEncodedBody.toString(),
        data: bodyObj,
      }
    );
    const json = await res;
    return json;
  } else {
    const res = await axios(`${url}${endpoint}`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        ...mainHeader,
        ...header,
      },
      // body: urlEncodedBody.toString(),
      data: bodyObj,
    });
    const json = await res;
    return json;
  }
};

export const generateEmailOtp=async(data:any)=>{
  const refreshToken = localStorage.getItem("refreshToken") || null;
  const userID = localStorage.getItem("userID") || null;
  const url=`${process.env.REACT_APP_EMAIL_OTP_GENERATOR_API}/v2/generate_email_otp`

  return await axios.post(url,data,{
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: refreshToken,
      userId: userID,
    },
  })
}

export const verifyMobileEmailOtp=async(data:any)=>{
  const refreshToken = localStorage.getItem("refreshToken") || null;
  const userID = localStorage.getItem("userID") || null;
  const url=`${process.env.REACT_APP_VERIFY_OTP_API}/v2/verify_otp`

  return await axios.post(url,data,{
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: refreshToken,
      userId: userID,
    },
  })
}

export const emailPasswordLogin=async(data:any)=>{
  const refreshToken = localStorage.getItem("refreshToken") || null;
  const userID = localStorage.getItem("userID") || null;
  const url=`${process.env.REACT_APP_VERIFY_PASSWORD_API_ENDPOINT}/verifyPassword`

  return await axios.post(url,data,{
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: refreshToken,
      userId: userID,
    },
  })
}

export const googleSignIn = async(data:any)=>{
  const url=`${process.env.REACT_APP_CAA_BACKEND_API_ENDPOINT}v2/sign_in_with_provider`
  return await axios.post(url,data,{
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
  })
}






