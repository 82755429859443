/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import useWidth from "../../hooks/useWidth";
import CustomizedButton from "../Button/CustomizedButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: "#D8D8D8",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      color: "#000000",
      fontSize: "1.4em",
      fontWeight: 400,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function CustomizedDialog({
  open,
  toggleDialog,
  content,
  title,
  actionTitle,
  cancelBtn,
  onConfirm,
  onClose,
}: {
  open: boolean;
  toggleDialog: any;
  content: React.ReactNode;
  actionTitle: string;
  title: string;
  cancelBtn: string;
  onConfirm: () => void
  onClose: () => void;
}) {
  const width = useWidth();

  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  return (
    <div>
      <StyledDialog
        onClose={toggleDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogContent>{content}</MuiDialogContent>
        {actionTitle ? (
          <MuiDialogActions
            style={
              screenWidth[width] > 2
                ? { justifyContent: "flex-end" }
                : { justifyContent: "center" }
            }
          >
            {cancelBtn && cancelBtn == "Cancel" ? (
              <CustomizedButton
                title={cancelBtn}
                onClick={onClose}
                style={ButtonStyle}
                color="red"
              />
            ) : (
              <CustomizedButton
                title={cancelBtn}
                onClick={onClose}
                style={ButtonStyle}
                color="red"
              />
            )}
            
            {actionTitle && actionTitle !== "NoAction" ? (
              <CustomizedButton
                title={actionTitle}
                onClick={onConfirm}
                style={ButtonStyle}
                color="red"
              />
            ) : (
              ""
            )}
          </MuiDialogActions>
        ) : null}
      </StyledDialog>
    </div>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    width: 100%;
  }
`;
const ButtonStyle = {
  fontWeight: 700,
  borderRadius: "5px",
  minWidth: "200px",
};
