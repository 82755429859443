/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";

function Line() {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#979797",
        height: "1px",
        marginBottom: "20px",
      }}
    ></div>
  );
}

export default Line;
