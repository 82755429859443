import Pdf from "../assets/images/icons/PDF.png";
import PdfGreenIcon from "../assets/images/pdf_green_icon.svg";
import PdfRedIcon from "../assets/images/pdf_red_icon.svg";
import Word from "../assets/images/icons/word.png";
import WordGreenIcon from "../assets/images/word_green_icon.svg";
import WordRedIcon from "../assets/images/word_red_icon.svg";

export const groupConsecutiveNumbers = (arrayOfNumbers) => {
  let output = [];

  arrayOfNumbers = Array.from(new Set(arrayOfNumbers)).sort((a, b) => a - b);

  const groupedNumbers = arrayOfNumbers.filter((element) => element !== null).reduce((r, n) => {
    const lastSubArray = r[r.length - 1];

    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
      r.push([]);
    }
    
    r[r.length - 1].push(n);

    return r;
  }, []);

  for (const item of groupedNumbers) {
    if (item.length > 1) {
      output.push(`${item[0]}-${item[item.length - 1]}`);
    } else {
      output.push(`${item[0]}`);
    }
  }

  return output.join(', ');
};

export const getQPAssetDetail = (assets, type, format, key) => {
  if(assets) {
    const asset = assets.find((b) => b.type === type && b.format === format);
    if (asset) {
      return asset[key];
    }
  }
  return "not_started";
}

export const FILE_GENERATION_ERROR_STATUSES = ["htmlGenerationFailed", "pdfGenerationFailed", "pdfUploadFailed", "docxGenerationFailed", "docxUploadFailed"];
export const IN_PROGRESS = ["htmlGenerationStarted", "htmlGenerationCompleted", "pdfGenerationStarted", "pdfGenerationCompleted", "pdfUploaded", "docxGenerationStarted", "docxGenerationCompleted", "docxUploaded"];

export const getDownloadStatusContent = (status) => {
  let value = "Generate ";

  if (status === "not_started") {
    value = "Generate ";
  } else if (IN_PROGRESS.indexOf(status) > -1) {
    value = "Generating ";
  } else if (status === "queued") {
    value = "Waiting to generate ";
  } else if (status === "picked") {
    value = "Generating ";
  } else if (status === "completed") {
    value = "Download ";
  } else if (FILE_GENERATION_ERROR_STATUSES.indexOf(status) > -1) {
    value = "Re-generate ";
  }
  return value;
}

export const getImageIcon = (status, type) => {
  let value = Pdf;

  if(type === 'pdf') {
    if (IN_PROGRESS.indexOf(status) > -1) {
      value = PdfGreenIcon;
    } else if (status === "completed") {
      value = PdfRedIcon;
    } else {
      value = Pdf;
    }
  } else {
    if (IN_PROGRESS.indexOf(status) > -1) {
      value = WordGreenIcon;
    } else if (status === "completed") {
      value = WordRedIcon;
    } else {
      value = Word;
    }
  }
  return value;
}

export const getStatusTextColor = (status) => {
  let color = '#666666';
  if (IN_PROGRESS.indexOf(status) > -1) {
    color = '#158800';
  } else if (status === "completed") {
    color = '#aa1011';
  }
  return color;
}