/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Slider } from "@material-ui/core";
import styled from "styled-components";

const CustomizedSlider = ({ ...rest }: any) => {
  return <StyledSlider {...rest} />;
};

export default CustomizedSlider;

const StyledSlider = styled(Slider)`
  & .MuiSlider-rail {
    background-color: #888888;
    height: 5px;
    border-radius: 5px;
  }
  & .MuiSlider-track {
    background-color: #888888;
    height: 5px;
  }
  & .MuiSlider-thumb {
    background-color: #888888;
    margin-top: -7px;
    height: 20px;
    width: 20px;
  }
  & .MuiSlider-mark {
    background-color: #888888;
    height: 15px;
    font-size: 0.7em;
  }
  & .MuiSlider-markLabel {
    font-size: 0.7em;
  }
`;
