/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import styled from "styled-components";

function SocialMedia() {
  return (
    <>
      <Button>
        <FacebookIcon />
      </Button>
      <Button>
        <TwitterIcon />
      </Button>
      <Button>
        <YouTubeIcon />
      </Button>
    </>
  );
}

export default SocialMedia;

const Button = styled(IconButton)`
  color: black;
  & .MuiSvgIcon-root {
    font-size: 30px;
  }
`;
