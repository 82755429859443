/* eslint-disable react/display-name */
// eslint-disable-next-line
import React, { useEffect } from "react";
import { Grid, Typography, Paper, Container } from "@material-ui/core";
import Styled from "styled-components";
import Logo from "../../components/Logo";
import Video from "./Video";
import Header from "./Header";
import LoginForm from "./LoginForm";
import Icon1 from "../../assets/images/icons/icon1.png";
import Icon2 from "../../assets/images/icons/icon2.png";
import Icon3 from "../../assets/images/icons/icon3.png";
import Icon4 from "../../assets/images/icons/icon4.png";
import LoginIconOverlay from "../../assets/images/loginoverlay.png";
import Dot from "../../assets/images/Overlays/Dot.png";
import Lines from "../../assets/images/Overlays/lines.png";
import Contact from "./Contact";
import HowToUseVideo from "./HowToUseVideo";
import Footer from "./Footer";
import useWidth from "../../hooks/useWidth";
function Login() {
  const width = useWidth();

  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  return (
    <>
      <StyledGridContainer
        container
        style={
          screenWidth[width] > 2
            ? { borderBottomRightRadius: "50px" }
            : { borderBottomRightRadius: "0px" }
        }
      >
        <MainContainer
          style={{
            backgroundImage: `url(${LoginIconOverlay})`,
            backgroundPosition: "right center",
          }}
        >
          <Grid container justify="space-evenly">
            <Grid item xs={6}>
              <StyledLogo
                style={
                  screenWidth[width] > 2
                    ? { width: "200px" }
                    : { width: "150px" }
                }
              />
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Grid item style={{ marginRight: 20 }}>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    document
                      .getElementById("contact-section")
                      ?.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  SUPPORT{" "}
                </span>
              </Grid>
              <Grid item>
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  href="https://info.xseededucation.com/contact"
                  target="_blank"
                >
                  XSEED SUPERTEACHER CONTACT
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-evenly">
            {screenWidth[width] > 2 ? (
              <>
                {/* For Desktop View  */}
                <Grid item md={6} xs={12}>
                  <Video />
                </Grid>
                <Grid item md={6} xs={12}>
                  <RightGrid
                    container
                    direction="column"
                    justify="space-evenly"
                  >
                    <Header />
                    <LoginForm />
                  </RightGrid>
                </Grid>
              </>
            ) : (
              <>
                {/* For Mobile View */}
                <Header isMobile={true} />
                <Video />
                <LoginForm isMobile={true} />
              </>
            )}
          </Grid>
        </MainContainer>
      </StyledGridContainer>
      <Container>
        <Grid container justify="center">
          <BottomHeading
            variant="h3"
            gutterBottom
            style={
              screenWidth[width] > 2
                ? { fontSize: "2em" }
                : { fontSize: "1.4em" }
            }
          >
            The Power Features of XSEED TestMaker
          </BottomHeading>
          <Grid container spacing={8} justify="center">
            <Card item md={4} xs={12}>
              <StyledPaper elevation={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <StyledIcon src={Icon1} alt="icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledText variant="button">
                      Stress-Free, Fast & Easy
                    </StyledText>
                  </Grid>
                </Grid>
                <StyledCaption variant="subtitle2">
                  Simply click to select what you require from the options and
                  the test paper will be generated instantly. You can also
                  export the test paper to PDF and Microsoft Word for printing
                  or for sharing over email
                </StyledCaption>
              </StyledPaper>
            </Card>
            <Card item md={4} xs={12}>
              <StyledPaper elevation={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <StyledIcon src={Icon2} alt="icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledText variant="button">Fully Customizable</StyledText>
                  </Grid>
                </Grid>
                <StyledCaption variant="subtitle2">
                  Choose your desired test type, grade level, subject, topic,
                  testing duration, difficulty level, scoring and question
                  format. You can also choose to replace questions with a click
                  and new questions will generate.
                </StyledCaption>
              </StyledPaper>
            </Card>
            <Card item md={4} xs={12}>
              <DotOverLay src={Dot} alt="" />
              <StyledPaper elevation={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <StyledIcon src={Icon3} alt="icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledText variant="button">
                      Top-Quality Questions
                    </StyledText>
                  </Grid>
                </Grid>
                <StyledCaption variant="subtitle2">
                  Supports all test types by drawing items from an extensive
                  bank of high-quality questions. XSEED’s academic experts
                  designed each question to align with the skill and concept
                  requirements of the given grade level.
                </StyledCaption>
              </StyledPaper>
            </Card>
            <Card item md={6} xs={12}>
              <LineOverLay src={Lines} alt="" />
              <StyledPaper elevation={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <StyledIcon src={Icon4} alt="icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledText variant="button">
                      Intelligent Usage Tracking
                    </StyledText>
                  </Grid>
                </Grid>
                <StyledCaption variant="subtitle2">
                  Keeps track of which questions your students have already
                  attempted, ensuring that questions do not get reused in
                  subsequent tests. This means that the same topic can be
                  re-tested at least 3 times!
                </StyledCaption>
              </StyledPaper>
            </Card>
            {/* TODO: Check with kaushal
            <Card item md={6} xs={12}>
              <StyledPaper elevation={2}>
                <Grid container>
                  <Grid item xs={2}>
                    <StyledIcon src={Icon5} alt="icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledText variant="button">
                      Test and Retest with Usage Tracking!
                    </StyledText>
                  </Grid>
                </Grid>
                <StyledCaption variant="subtitle2">
                  XSEED Test Maker keeps track of which questions your students have already attempted, ensuring that questions do not get reused in subsequent tests. This means that the same topic can be retested at least 3 times!
                </StyledCaption>
              </StyledPaper>
            </Card> */}
          </Grid>
        </Grid>
        <HowToUseVideo />
        <Contact />
      </Container>
      <Footer isMobile={screenWidth[width] <= 2} />
    </>
  );
}

export default Login;

const StyledGridContainer = Styled(Grid)`
  background: #ac2323;
  color: #ffffff;
  font-weight: bold;
  position: relative;
`;
const ImageOverLay = Styled.img`
    position: absolute;
    color:white;
    top:0;
    right:0;
    height:100%;
    padding:10px;
`;
const MainContainer = Styled(Grid)`
  padding:25px;
  width:100%;  
`;
const RightGrid = Styled(Grid)`
  height:100% 
`;
const StyledLogo = Styled(Logo)`
  height: 80px;
  object-fit: contain;
`;
const BottomHeading = Styled(Typography)`
  font-size: 2rem;
  color: #111111;
  font-weight: 700;
  margin:30px;
  margin-bottom: 60px;
`;
const Card = Styled(Grid)`
  position: relative;
  max-width: 400px;
  max-height: 250px;
  padding: 16px !important;
  margin: 0px 32px;
& .MuiPaper-root:hover {
  border: 2px solid #AC2323;  
  transform: scale(1.1);
  -moz-transition: all 1s linear;
  -ms-transition: all 1s linear;
  -o-transition: all 1s linear;
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
}
  `;
const StyledPaper = Styled(Paper)`
  position: relative;
  padding: 15px;
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 10px 2px rgba(172,35,35,0.1);
  -webkit-box-shadow: 2px 2px 10px 2px rgba(172,35,35,0.1);
  -moz-box-shadow: 2px 2px 10px 2px rgba(172,35,35,0.1);
}
`;
const StyledIcon = Styled.img`
  width: 40px;
  object-fit: contain;
`;
const StyledText = Styled(Typography)`
  color: #111111;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 5px;
`;
const StyledCaption = Styled(Typography)`
  color: #444444;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 15px;
  word-spacing: 0.1rem;
`;
const DotOverLay = Styled.img`
position: absolute;
right: 45px;
bottom: -45px;
transform: rotate(90deg);
`;
const LineOverLay = Styled.img`
position: absolute;
left: -15px;
bottom: 30px;
`;
