/* eslint-disable react/display-name */
// eslint-disable-next-line
import { Avatar, Button, Grid, Typography, Radio } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWidth from "../../hooks/useWidth";
import QuestionRender from "./QuestionRender";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ReplaceIcon from "../../assets/images/replaceIcon.svg";
interface ReplacingQuestionData {
  questionId: string;
  questionGroupId: string;
}

function Question({
  question,
  questionData,
  answerData,
  viewAnswer,
  sl_no,
  pageType,
  visibleBtn,
  isShort,
  paperId,
  linesandBox,
  groupQuestionId,
  stimulus,
  groupQuestionNo,
  groupQuestion,
  stimulusData,
  groupMarks,
  usedPapers,
  handleRefreshQuestion,
  showReplaceQuestionButton,
  handleReplaceQuestion,
  isSingleQsOr1stQsInGroup,
  showRadioInPlaceOfRootLabel,
  setReplacingQuestion,
  replacingQuestionId,
}: {
  question: any;
  questionData: any;
  answerData: any;
  viewAnswer: boolean;
  sl_no: number;
  pageType: string;
  visibleBtn: boolean;
  isShort: Boolean;
  paperId: string;
  linesandBox: any;
  groupQuestionId: string;
  stimulus: boolean;
  groupQuestionNo: number;
  groupQuestion: boolean;
  stimulusData: any;
  groupMarks: any;
  usedPapers: any;
  handleRefreshQuestion: (
    paperId: string,
    isShort: Boolean,
    questionId: string
  ) => void;
  showReplaceQuestionButton: boolean,
  handleReplaceQuestion: (
    paperId: string,
    isShort: boolean,
    questionId: string,
    questionGroupId: string,
  ) => void;
  isSingleQsOr1stQsInGroup: boolean,
  showRadioInPlaceOfRootLabel: boolean,
  setReplacingQuestion: (data: ReplacingQuestionData) => void
  replacingQuestionId: string,
}) {
  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const width = useWidth();
  const [showAnswer, setShowAnswer] = useState(false);

  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  const handleShowAnswerChange = () => {
    setShowAnswer(!showAnswer);
  };

  const _handleReplaceQuestion = () => {
    handleReplaceQuestion(
      paperId,
      isShort as boolean,
      question._id,
      question.questionGroupId
    );
  };

  const getReplaceQuestionRadioButton = () => {
    return (
      <Radio
        value={question._id}
        color="primary"
        checked={replacingQuestionId === question._id.toString()}
        onChange={() => {
          setReplacingQuestion({
            questionId: question._id.toString(),
            questionGroupId: question.questionGroupId,
          });
        }}
      />
    );
  };

  useEffect(() => {
    window.onscroll = null;
    setShowAnswer(viewAnswer);
  }, [viewAnswer]);
  return (
    <>
      {screenWidth[width] > 2 ? (
        <Container
          style={
            !groupQuestion
              ? {
                  paddingLeft: "15px",
                  // borderBottom: "1px solid #979797",
                  borderTop: "1px solid #979797",
                  alignItems: "baseline",
                }
              : stimulus
              ? {
                  // paddingLeft: "45px",
                  borderBottom: "none",
                  borderTop: "1px solid #979797",
                  alignItems: "baseline",
                }
              : {
                  paddingLeft: "95px",
                  borderBottom: "none",
                  alignItems: "baseline",
                }
          }
          container
        >
          {groupQuestion && stimulus && (
            <Grid style={{ marginBottom: 20 }} container>
              <Grid xs={1}
                item
                className="grid1"
              >
                {
                  isSingleQsOr1stQsInGroup
                  ?
                    showRadioInPlaceOfRootLabel
                    ?
                      getReplaceQuestionRadioButton()
                    :
                      <Circle>{groupQuestionNo}</Circle>
                  :
                    <Circle>{groupQuestionNo}</Circle>
                }
              </Grid>
              <Grid item xs={9} className="grid2">
                {/* Render Question in UI */}
                <QuestionRender data={stimulusData} />
              </Grid>
              <Grid xs={ showReplaceQuestionButton ? 1 : 2}
                item
                style={{ left: "40px" }}
                className="grid3"
              >
                <div style={{ display: "flex" }}>
                  {usedPapers ? (
                    <span style={{ marginRight: "10px" }}>
                      <LightTooltip
                        placement="left"
                        title={`This question was used by ${
                          usedPapers.length
                        } test${
                          usedPapers.length > 1 ? "s" : ""
                        } -  \n ${usedPapers
                          .map((e: any) => e.testName)
                          .join(", ")}`}
                        arrow
                      >
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </LightTooltip>
                    </span>
                  ) : null}
                  <Box>
                    <div className="mark">{parseInt(groupMarks)}</div>
                  </Box>
                </div>

                {pageType === "preview" ? (
                  <Button
                    style={{
                      backgroundColor: "#AF0000",
                      color: "#FFFFFF",
                      maxHeight: "45px",
                      float: "right",
                      marginTop: 10,
                    }}
                  >
                    {/* TODO Commenting the refresh button*/}
                    {/* <RefreshIcon
                      onClick={() =>
                        handleRefreshQuestion(paperId, isShort, question._id)
                      }
                      fontSize="small"
                    /> */}
                  </Button>
                ) : null}
              </Grid>
              {
                showReplaceQuestionButton === true &&
                <Grid xs={1}
                  item
                  className="grid4"
                >
                  <ReplaceIconButton onClick={_handleReplaceQuestion}>
                    <img alt="Repace Button Icon" src={ReplaceIcon} />
                  </ReplaceIconButton>
                </Grid>
              }
              <br />
              <br />
            </Grid>
          )}
          <Grid container
            style={groupQuestion && stimulus ? ({ paddingLeft: "75px" }) : {}}
          >
            <Grid xs={1}
              className="grid1"
              style={groupQuestion ? ({ paddingLeft: "25px" }) : {}}
              item
            >
              {
                  isSingleQsOr1stQsInGroup && !groupQuestion
                  ?
                    showRadioInPlaceOfRootLabel
                    ?
                      getReplaceQuestionRadioButton()
                    :
                      <Circle>{sl_no}</Circle>
                  :
                    <Circle>{sl_no}</Circle>
                }
            </Grid>
            <Grid className="grid2" item xs={9}>
              {/* Render Question in UI */}
              <QuestionRender data={questionData} />
              <QuestionRender data={linesandBox} dbl={true} />
              {/* Render Answer in UI */}
              {showAnswer ? (
                <AnswerContainer container>
                  <QuestionRender data={answerData} />
                </AnswerContainer>
              ) : (
                ""
              )}
            </Grid>
            <Grid className="grid3" item xs={showReplaceQuestionButton ? 1 : 2}>
              {!groupQuestion && (
                <>
                  <div style={{ display: "flex" }}>
                    {usedPapers ? (
                      <span style={{ marginRight: "10px" }}>
                        <LightTooltip
                          placement="left"
                          title={`This question was used by ${
                            usedPapers.length
                          } test${
                            usedPapers.length > 1 ? "s" : ""
                          } -  \n ${usedPapers
                            .map((e: any) => e.testName)
                            .join(", ")}`}
                          arrow
                        >
                          <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                        </LightTooltip>
                      </span>
                    ) : null}
                    <Box>
                      <div className="mark">
                        {parseInt(question.questionMarks)}
                      </div>
                    </Box>
                  </div>
                  {pageType === "preview" ? (
                    <Button
                      style={{
                        backgroundColor: "#AF0000",
                        color: "#FFFFFF",
                        maxHeight: "45px",
                        float: "right",
                        marginTop: 10,
                      }}
                    >
                      {/* TODO Commenting the refresh button*/}
                      {/* <RefreshIcon
                        onClick={() =>
                          handleRefreshQuestion(paperId, isShort, question._id)
                        }
                        fontSize="small"
                      /> */}
                    </Button>
                  ) : null}
                </>
              )}
            </Grid>
            {
              showReplaceQuestionButton === true &&
              <Grid xs={1}
                item
                className="grid4"
              >
                {!groupQuestion && (
                  <ReplaceIconButton onClick={_handleReplaceQuestion}>
                    <img alt="Logo" src={ReplaceIcon} />
                  </ReplaceIconButton>
                )}
            </Grid>
            }
            <Grid
              container
              className="grid4"
              style={{ display: visibleBtn !== false ? "none" : "" }}
            >
              <StyledButton variant="outlined" onClick={handleShowAnswerChange}>
                {!showAnswer ? "View Answer" : "Hide Answer"}
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <MobileHeader container alignItems="center">
            <Typography variant="subtitle2"> {`Question ${sl_no}`}</Typography>
            <Box>
              <div className="mark">{parseInt(question.questionMarks)}</div>
            </Box>
            {pageType === "preview" ? (
              <Button
                style={{
                  backgroundColor: "#AF0000",
                  color: "#FFFFFF",
                  maxHeight: "35px",
                  marginLeft: "5px",
                  width: "50px",
                  minWidth: "35px",
                }}
              >
                <RefreshIcon fontSize="small" />
              </Button>
            ) : null}
          </MobileHeader>
          <Grid container style={{ paddingLeft: "15px" }}>
            {/* Render Question in UI */}
            <QuestionRender data={questionData} />
            {/* Render Answer in UI */}
            {showAnswer ? (
              <AnswerContainer container>
                <QuestionRender data={answerData} />
              </AnswerContainer>
            ) : (
              ""
            )}
            <StyledButton
              variant="outlined"
              style={{ width: "180px", margin: "15px auto" }}
              onClick={handleShowAnswerChange}
            >
              {!showAnswer ? "View Answer" : "Hide Answer"}
            </StyledButton>
          </Grid>
        </>
      )}
    </>
  );
}

export default Question;

const Container = styled(Grid)`
  padding: 15px;
  align-items: baseline;
`;
const Circle = styled(Avatar)`
  color: #ffffff;
  background-color: #474747;
  width: 30px;
  height: 30px;
  font-size: 1.1em;
`;
const Box = styled.div`
  width: 60px;
  height: 28px;
  border: 1px solid;
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  margin-right: 2px;
  & .mark {
    width: 50%;
    background-color: #d8d8d8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
`;

const StyledButton = styled(Button)`
  text-transform: none;
  color: #474747;
  font-weight: 700;
  font-size: 0.9em;
  margin-left: auto;
  margin-top: 10px;
  width: 15em;
`;
const MobileHeader = styled(Grid)`
  background-color: #e6e6e6;
  padding: 2px 5px;
  & .MuiSvgIcon-root {
    font-size: 1.6rem;
  }
`;
const AnswerContainer = styled(Grid)`
  margin-top: 20px;
`;

const ReplaceIconButton = styled(Button)`
  height: 30px;
  width: 10px;
  margin-left: 20px;
`;
