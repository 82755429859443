/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Grid, TextField, Typography, TextareaAutosize } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Question from "../../assets/images/icons/question.png";
import StripOverlay from "../../assets/images/Overlays/OverlayStripe.png";
import Dot from "../../assets/images/Overlays/Dot.png";
import DotQuestionOverlay from "../../assets/images/Overlays/DotQuestionOverlay.png";
import useWidth from "../../hooks/useWidth";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SubmitButton from "../../components/Button/SubmitButton";

interface FormValues {
  name: string;
  email: string;
  message: string;
  phoneNumber: string;
}
const initialFormValue: FormValues = {
  name: "",
  email: "",
  message: "",
  phoneNumber: ""
};

function Contact() {
  const width = useWidth();

  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  const handleSubmit = (
    { name, email, message, phoneNumber }: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    formikHelpers.setSubmitting(false);
    console.log(`Name: ${name}, Email: ${email}, PhoneNumber: ${phoneNumber}, Message: ${message}`);
  };

  return (
    <Container id="contact-section">
      <TopRightOverLay src={Dot} alt="" />
      <BottomleftOverLay src={Dot} alt="" />

      <StyledGrid container>
        <LeftOverLay src={StripOverlay} alt="" />
        <Grid justify="center" container>
          <StyledHeader variant="h6" gutterBottom>Questions and Feedback</StyledHeader>
        </Grid>
        <Grid justify="center" container>
          <SubTitle>We will get back to you as soon as possible!</SubTitle>
        </Grid>
        <Grid item md={12} xs={12}>
          <Formik
            initialValues={initialFormValue}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required("Name is required"),
              email: Yup.string()
                .required("Email is required")
                .email("Should be a valid Email"),
              message: Yup.string()
                .required("Message is required"),
              phoneNumber: Yup.number()
                .required("Number is required")
                .test(
                  "length",
                  "Invalid phone number",
                  (phoneNumber) => phoneNumber?.toString().length === 10
                ),
            })}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid item container justify="space-around">
                  <Grid item md={5} xs={12}>
                    <InputField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label={"Your Name "}
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                    />
                    <InputField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      label={"E-mail Address "}
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <ReactPhoneInput
                      country="in"
                      inputProps={{
                        name: "mobile",
                        required: true,
                      }}
                      placeholder="Mobile"
                      containerStyle={{
                        width: "100%",
                        height: "60px",
                      }}
                      inputStyle={{
                        marginLeft: "10%",
                        width: "90%",
                        height: "60px",
                        fontSize: "1.2em",
                      }}
                      buttonStyle={{
                        width: "5em",
                        backgroundColor: "#FFFFFF",
                        padding: "15px",
                      }}
                      dropdownStyle={{
                        color: "#666666",
                        backgroundColor: "#FFFFFF",
                      }}
                      countryCodeEditable={false}
                      enableSearch={true}
                      value={values.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleBlur("phoneNumber")}
                      copyNumbersOnly={false}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ margin: "auto" }} md={11} xs={12} container justify="space-around">
                  <InputField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    multiline
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    label={"Your Message"}
                    name="message"
                    type="text"
                    rows={1}
                    rowsMax={3}
                    value={values.message}
                    onChange={handleChange("message")}
                    onBlur={handleBlur("message")}
                  />
                </Grid>
                <Grid container justify="center">
                  <SubmitButton
                    title="SUBMIT"
                    isSubmitting={isSubmitting}
                    style={{
                      ...ButtonStyle,
                      width: `${screenWidth[width] > 2 ? "275px" : "100%"}`
                    }}
                    color="red"
                  />
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </StyledGrid>
    </Container>
  );
}

export default Contact;

const Container = styled.div`
  position: relative;
`;
const StyledGrid = styled(Grid)`
  background: linear-gradient(
    to right,
    #fff2f2 0%,
    #f7f7f7 50%,
    rgb(252 242 242) 100%
  );
  margin-top: 50px;
  border-radius: 30px;
  padding: 2rem;
  position: relative;
`;
const LeftOverLay = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
`;
const TopRightOverLay = styled.img`
  position: absolute;
  right: -40px;
  top: -30px;
`;
const BottomleftOverLay = styled.img`
  position: absolute;
  left: 0;
  bottom: -70px;
  transform: rotate(90deg);
`;
const StyledHeader = styled(Typography)`
  position: relative;
  color: #b52525;
  font-size: 1.5rem;
  font-weight: bold;
`;
const StyledText = styled(Typography)`
  position: relative;
  color: #5f5f5f;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.7;
  word-spacing: 0.3rem;
`;
const ImageDiv = styled.div`
  position: relative;
  width: 290px;
  border-radius: 15px;
  background: #ffffff;
  height: 160px;
  margin-right: auto;
  text-align: center;
  margin-top: 20px;
`;
const SytledImage = styled.img`
  object-fit: contain;
`;
const QuestionOverlay = styled.img`
  position: absolute;
  bottom: -18px;
  right: -17px;
  object-fit: contain;
`;
const ButtonStyle = {
  marginTop: "15px",
  fontSize: "1rem",
  fontWeight: "bold",
};
const InputField = styled(TextField)`
  color: #666666;
  & .MuiInputBase-root {
    background: #ffffff;
  }
`;
const SubTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 20px;
`;
