/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  Button,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TuneIcon from "@material-ui/icons/Tune";
import { Formik, FormikHelpers } from "formik";
import { toJS } from "mobx";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import SubmitButton from "../../components/Button/SubmitButton";
import CustomizedSlider from "../../components/Slider/CustomizedSlider";
import useWidth from "../../hooks/useWidth";
import { post } from "../../utils/Api";
import injectWithObserver from "../../utils/injectWithObserver";
import {
  Difficulty,
  ObjectiveType,
  QuestionType,
} from "./CreateQuestion";
import DataGridTable from "./DataGrid/DataGridTable";
import FilterComponent from "./Filter/FilterComponent";
import Line from "./Line";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Subject } from "@material-ui/icons";

interface Props {
  selectedSubjects: any;
  selectedGrades: any;
  stores: any;
  refresh:number;
}
interface FilterFormValues {
  testType: Array<string>;
  blocks: Array<string>;
  difficulty: Array<string>;
  questionType: Array<string>;
  objectiveType: string;
  testName: string;
  minutes: number | number[];
  marks: number | number[];
}

const initialFormValue: FilterFormValues = {
  testType: [],
  blocks: [],
  difficulty: [],
  questionType: [],
  testName: "",
  minutes: [0, 120],
  marks: [0, 100],
  objectiveType: "",
};

const Minutes = [
  {
    value: 0,
    label: "0m",
  },
  {
    value: 15,
  },
  {
    value: 30,
    label: "30m",
  },
  {
    value: 45,
  },
  {
    value: 60,
    label: "60m",
  },
  {
    value: 75,
  },
  {
    value: 90,
    label: "90m",
  },
  {
    value: 105,
  },
  {
    value: 120,
    label: "120+m",
  },
];

const Marks = [
  {
    value: 15,
    label: "15",
  },
  {
    value: 30,
  },
  {
    value: 45,
    label: "45",
  },
  {
    value: 60,
  },
  {
    value: 75,
    label: "75",
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: "100",
  },
];

const MenuProps = {
  autoFocus: false,
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: 65,
      background: "#FAFAFA",
    },
  },
};

export interface SubjectProps {
  id: string;
  name: string;
  shortName: string;
}
export interface GradeProps {
  id: string;
  name: string;
  displayName: string;
}
const initialSubject: SubjectProps = { id: "", name: "", shortName: "" };
const initialGrade: GradeProps = { id: "", name: "", displayName: "" };

function ViewQuestion({ stores }: Props) {
  const formRef: any = useRef();
  const [minutes, setMinutes] = useState<number | number[]>([0, 120]);
  const [marks, setMarks] = useState<number | number[]>([0, 100]);
  const [dropDownData, setDropDownData] = useState<null | any>(null);
  const [drawer, setDrawer] = useState(false);
  const [filterApplied, setFilterApplied] = useState("");
  const [subjects, setSubjects] = useState<any[]>([]);
  const [grades, setGrades] = useState<any[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<SubjectProps[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<GradeProps[]>([]);
  const [refresh, doRefresh] = useState<number>(0);
  const width = useWidth();
  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  // console.log(filterApplied, "filterApplied", Math.random().toString());
  const handleSubmit = (
    {}: FilterFormValues,
    formikHelpers: FormikHelpers<FilterFormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    stores.FormDataStore.SavedQpaper(formRef.current.values);
    setFilterApplied(Math.random().toString());
    stores.FormDataStore.changeFilterApplied(Math.random().toString());
    formikHelpers.setSubmitting(false);
    console.log(formRef.current, "FilterFormValues");
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    getGrades();
    ClearForm();
  }, [selectedSubjects, selectedGrades]);

  useEffect(() => {
    console.log("usuusus")
    ClearForm();
  }, [refresh]);
  const ClearForm = () => {
    if (formRef.current) {
      formRef.current.values.testType = [];
      formRef.current.values.blocks = [];
      formRef.current.values.difficulty = [];
      formRef.current.values.questionType = [];
      formRef.current.values.testName = [];
      formRef.current.values.minutes = [0, 120];
      formRef.current.values.marks = [0, 100];
      formRef.current.values.objectiveType = "";
      setMinutes([0, 120]);
      setFilterApplied("");
    stores.FormDataStore.changeFilterApplied('');
    // stores.FormDataStore.SavedQpaper(formRef.current.values);
    }
  };
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const getTestList = async () => {
    let subjectIds: string[] = []
    let gradeIds: string[] = []
    if (selectedSubjects) {
      selectedSubjects.map((subject) => {
        subjectIds.push(subject.id)
      })
    }
    if (selectedGrades) {
      selectedGrades.map((grade) => {
        gradeIds.push(grade.id)
      })
    }
    post("", {
      query: `query { getTestList( subjectIds: [${
        subjectIds
      }], standardIds: [${
        gradeIds
      }]) { testNameList{ testName },  blocks { id,  bId, title, displaySequence } } }`,
    })
      .then((res) => {
        console.log("Getting filter dropdown values:", res.data.data);
        setDropDownData(res.data.data.getTestList);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getTestList()
  }, [selectedSubjects, selectedGrades]);
  {
    console.log(dropDownData && dropDownData, "dropDownData");
  }
  console.log(toJS(stores.FormDataStore.getQuestionPaperForm),"FilterFormValues")
  useEffect(() => {
    // This lines of code is used for preserving the values in mobx and get it back
    if (formRef.current) {
      if (stores.FormDataStore.getQuestionPaperForm) {
        const {
          testType,
          blocks,
          difficulty,
          questionType,
          testName,
          objectiveType,
          minutes,
          marks,
        } = toJS(stores.FormDataStore.getQuestionPaperForm);
        formRef.current.values.testType = testType;
        formRef.current.values.blocks = blocks;
        formRef.current.values.difficulty = difficulty;
        formRef.current.values.questionType = questionType;
        formRef.current.values.testName = testName;
        formRef.current.values.minutes = minutes;
        formRef.current.values.marks = marks;
        formRef.current.values.objectiveType = objectiveType;
        setMinutes(minutes);
        setMarks(marks);
        getGrades()
      }
    }
    getSubjects()
    return () => {
      console.log("Filter question clean up");
      if (formRef.current) {
        // console.log(formRef.current);
        stores.FormDataStore.changeQuestionPaperForm(formRef.current.values);
      }
    };
  }, []);

  const TestTypes = stores.userStore.schoolDetails ? stores.userStore.schoolDetails.testTypes :  [];
  const getSubjects = () => {
    post("", {
      query: "query { getSubjects {id, name, shortName}  }",
    })
      .then((res) => {
        console.log("GetSubjects:", res.data);
        setSubjects(res.data.data.getSubjects);
      })
      .catch((e) => console.log(e));
  };

  const getGrades = () => {
    console.log(selectedSubjects, "ingradesss");
    let subjectIds: string[] = []
    if (selectedSubjects.length > 0) {
      selectedSubjects.map((subject) => {
        subjectIds.push(subject.id)
      })
      post("", {
        query: `query { getGrade(subjectIds: [${subjectIds}]){ id, name, displayName }}`,
      })
        .then((res) => {
          console.log("GetGrades:", res.data);
          setGrades(res.data.data.getGrade);
        })
        .catch((e) => console.log(e));
    }
  };

  const resetVal = () => {
    setSelectedSubjects([]);
    setSelectedGrades([]);
  };

  const printGrades = () => {
    if (selectedGrades.length == 1) {
      return selectedGrades[0].displayName;
    }
    const sortedSelectedGrades = selectedGrades.sort((a, b) => {return parseInt(a.id) - parseInt(b.id)})
    let gradeDisplay = ''
    sortedSelectedGrades.map((grade, index) => {
      if (index == sortedSelectedGrades.length - 1) {
        gradeDisplay += grade.displayName;
      } else {
        gradeDisplay += grade.displayName + ', '
      }
    })
    return gradeDisplay
  }

  const printSubjects = () => {
    if (selectedSubjects.length == 1) {
      return selectedSubjects[0].name;
    }
    let subjectDisplay = ''
    selectedSubjects.map((grade, index) => {
      if (index == selectedSubjects.length - 1) {
        subjectDisplay += grade.name;
      } else {
        subjectDisplay += grade.name + ', '
      }
    })
    return subjectDisplay
  }

  return (
    <>
      <MainContainer style={{border: '1px solid RGB(149, 149, 149)', borderRadius: '0px 5px 5px 5px'}}>
        <Container>
          <Grid
            item
            md={12}
            xs={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Grid
              item
              md={6}
              xs={12}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                alignItems: 'center'
              }}
            >
              <StyledDropDown
                id="filled-select-subject"
                select
                label="Select subjects"
                style={{ border: "0.5px solid #939393" }}
                value={subjects}
                InputProps={{ disableUnderline: true }}
                variant="filled"
                onChange={(e: any) => {
                  const reminderSubjects = e.target.value
                  const selectedSub = subjects.filter((subject) => !reminderSubjects.includes(subject))[0];
                  const temp = selectedSubjects
                  if (!selectedSubjects.includes(selectedSub)) {
                    temp.push(selectedSub)
                  } else {
                    const index = temp.indexOf(selectedSub)
                    temp.splice(index, 1)
                  }
                  setSelectedSubjects(temp)
                  stores.FormDataStore.changeSelectedSubject(temp);
                  console.log(selectedSubjects)
                  // setGrades([]);
                  setSelectedGrades([]);
                  localStorage.removeItem("subID");
                  localStorage.setItem(e.target.value.id, "subID");
                }}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                  multiple: true,
                  renderValue: (selected: any) => printSubjects(),
                  MenuProps: MenuProps,
                }}
              >
                {/* <MenuItem value={initialSubject.name}>None</MenuItem> */}
                {subjects &&
                  subjects.map((subject, index) => (
                    <Item key={index} value={subject}>
                      <Checkbox checked={selectedSubjects.includes(subject)} />
                      {subject.name}
                    </Item>
                  ))}
              </StyledDropDown>
              <StyledDropDown
                style={{ border: "0.5px solid #939393" }}
                id="filled-select-grade"
                select
                label="Select grades"
                value={grades}
                InputProps={{ disableUnderline: true }}
                variant="filled"
                disabled={selectedSubjects.length ? false : true}
                onChange={(e: any) => {
                  const reminderGrades = e.target.value
                  const selectedGrade = grades.filter(grade => !reminderGrades.includes(grade))[0]
                  const temp = selectedGrades
                  if (temp.includes(selectedGrade)) {
                    const index = temp.indexOf(selectedGrade)
                    temp.splice(index, 1)
                  } else {
                    temp.push(selectedGrade)
                  }
                  setSelectedGrades(temp);
                  console.log(selectedGrades)
                  localStorage.removeItem("gradeID");
                  localStorage.setItem(e.target.value.id, "gradeID");
                  stores.FormDataStore.changeSelectedGrade(temp);
                  doRefresh((prev: number) => prev + 1)
                  // reLoader()
                  getTestList()
                }}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                  multiple: true,
                  renderValue: (selected: any) => printGrades(),
                  MenuProps: MenuProps,
                }}
              >
                {/* <MenuItem value={initialGrade.displayName}>None</MenuItem> */}
                {grades.map((grade) => (
                  <Item key={grade.id} value={grade}>
                    <Checkbox checked={selectedGrades.includes(grade)} />
                    {grade.displayName}
                  </Item>
                ))}
              </StyledDropDown>
              <Button
                style={{
                  color: "RGB(102, 102, 102)",
                  padding: 0,
                  fontSize: "0.8em",
                  lineHeight: '20px',
                  height: '40px',
                  width: '150px',
                  borderRadius: 5,
                  border: '1px solid RGB(102, 102, 102)',
                  marginLeft: "60px",
                  background: "transparent",
                }}
                disableElevation
                onClick={resetVal}
                variant="contained"
                size="small"
              >
                Clear selection
              </Button>
            </Grid>
            <Typography
              style={{
                fontSize: "12px",
                marginLeft: "15px",
                marginBottom: "5px",
              }}
            >
              {
                "NOTE : Available Subject and Grade combination is as per your XSEED SuperTeacher subscription."
              }
            </Typography>
          </Grid>
        </Container>
        <Formik
          initialValues={initialFormValue}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({})}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              {/* Desktop component */}
              {screenWidth[width] > 2 ? (
                <Container>
                  <StyledHeader>
                    {selectedSubjects.length} Subjects | Grade {printGrades()}
                    <span> Tests</span>
                  </StyledHeader>
                  <Line />

                  <Grid container spacing={4}>
                    <Grid item xs={10}>
                      <DropDown
                        id="testType"
                        select
                        label="TEST TYPE"
                        value={values.testType}
                        variant="filled"
                        onChange={handleChange("testType")}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          multiple: true,
                          MenuProps: MenuProps,
                          renderValue: (selected: any) => selected.join(", "),
                        }}
                        disabled={
                          selectedSubjects.length && selectedGrades.length
                            ? false
                            : true
                        }
                      >
                        {TestTypes.map((type: any, index: any) => (
                          <Item key={index} value={type}>
                            <Checkbox
                              checked={values.testType.indexOf(type) > -1}
                            />
                            <ListItemText primary={type} />
                          </Item>
                        ))}
                      </DropDown>
                      {/* <DropDown
                        id='blocks'
                        select
                        label='BLOCKS'
                        value={values.blocks}
                        variant='filled'
                        onChange={handleChange('blocks')}

                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          multiple: true,
                          placeholder: 'Select',
                          MenuProps: MenuProps,
                          renderValue: (selected: any) =>
                            (selected as string[]).length + ' Blocks selected',
                        }}
                        disabled={
                          selectedSubjects.name && selectedGrades.name
                            ? false
                            : true
                        }
                      >
                        <Item
                          value='select all'
                        >
                            <Checkbox
                              checked={dropDownData?.blocks.length > 0 && values.blocks.length === dropDownData?.blocks.length}
                              indeterminate={
                                values.blocks.length > 0 &&
                                values.blocks.length < dropDownData?.blocks.length
                              }
                            />
                          <ListItemText
                            primary='Select All'
                          />
                        </Item>
                        {dropDownData?.blocks.map(
                          (block: any, index: number) => (
                            <Item key={index} value={block.id}>
                              <Checkbox
                                checked={values.blocks.indexOf(block.id) > -1}
                              />
                              <ListItemText primary={block.title} />
                            </Item>
                          )
                        )}
                      </DropDown> */}
                      <DropDown
                        id="blocks"
                        select
                        label="BLOCKS"
                        value={values.blocks}
                        variant="filled"
                        // onChange={handleChange('blocks')}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value[value.length - 1] === "select all") {
                            setFieldValue(
                              "blocks",
                              values.blocks.length ===
                                dropDownData.blocks.length
                                ? []
                                : dropDownData.blocks.map((i: any) => i.id)
                            );
                            return;
                          }
                          setFieldValue("blocks", event.target.value);
                        }}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          multiple: true,
                          placeholder: "Select",
                          MenuProps: MenuProps,
                          renderValue: (selected: any) =>
                            (selected as string[]).length + " Blocks selected",
                        }}
                        disabled={
                          selectedSubjects.length && selectedGrades.length
                            ? false
                            : true
                        }
                      >
                        <Item value="select all">
                          <Checkbox
                            checked={
                              dropDownData?.blocks.length > 0 &&
                              values.blocks.length ===
                                dropDownData?.blocks.length
                            }
                            indeterminate={
                              values.blocks.length > 0 &&
                              values.blocks.length < dropDownData?.blocks.length
                            }
                          />
                          <ListItemText primary="Select All" />
                        </Item>

                        {dropDownData?.blocks.map(
                          (block: any, index: number) => (
                            <Item key={index} value={block.id}>
                              <Checkbox
                                checked={values.blocks.indexOf(block.id) > -1}
                              />
                              <ListItemText primary={block.title} />
                            </Item>
                          )
                        )}
                      </DropDown>
                      {console.log(
                        typeof values.blocks,
                        "blllll",
                        values.blocks
                      )}
                      <DropDown
                        id="Difficulty"
                        select
                        label="DIFFICULTY LEVEL"
                        value={values.difficulty}
                        variant="filled"
                        onChange={handleChange("difficulty")}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          multiple: true,
                          MenuProps: MenuProps,
                          renderValue: (selected: any) => {
                            // console.log(selected);
                            return selected.join(", ");
                          },
                        }}
                        disabled={
                          selectedSubjects.length && selectedGrades.length
                            ? false
                            : true
                        }
                      >
                        {Difficulty.map((difficulty, index) => (
                          <Item key={index} value={difficulty}>
                            <Checkbox
                              checked={
                                values.difficulty.indexOf(difficulty) > -1
                              }
                            />
                            <ListItemText primary={difficulty} />
                          </Item>
                        ))}
                      </DropDown>
                      <DropDown
                        id="QuestionType"
                        select
                        label="QUESTION TYPE"
                        value={values.questionType}
                        variant="filled"
                        onChange={handleChange("questionType")}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          multiple: true,
                          MenuProps: MenuProps,
                          renderValue: (selected: any) => {
                            return selected
                              .join(", ")
                              .replace("sa", "Short")
                              .replace("all", "Short and long");
                          },
                        }}
                        disabled={
                          selectedSubjects.length && selectedGrades.length
                            ? false
                            : true
                        }
                      >
                        {QuestionType.map((type, index) => (
                          <Item key={index} value={type}>
                            <Checkbox
                              checked={values.questionType.indexOf(type) > -1}
                            />
                            <ListItemText
                              primary={
                                type === "sa" ? "Short" : "Short and Long"
                              }
                            />
                          </Item>
                        ))}
                      </DropDown>
                      {/* <DropDown
                        id="ObjectiveType"
                        select
                        label="OBJECTIVE TYPE"
                        value={values.objectiveType}
                        variant="filled"
                        onChange={handleChange("objectiveType")}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                          MenuProps: MenuProps,
                          renderValue: (selected: any) => selected,
                        }}
                        disabled={selectedSubjects.name && selectedGrades.name ? false : true}
                      >
                        {ObjectiveType.map((type, index) => (
                          <Item key={index} value={type}>
                            <Checkbox checked={values.objectiveType === type} />
                            <ListItemText primary={type} />
                          </Item>
                        ))}
                        </DropDown> */}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "17px" }}>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          xs={5}
                          md={3}
                          style={{ gridTemplateColumns: "200px" }}
                        >
                          <Autocomplete
                            options={
                              dropDownData ? dropDownData.testNameList : []
                            }
                            getOptionLabel={(option: any) => option.testName}
                            value={values.testName}
                            style={{ width: '100%' }}
                            onChange={(event, value: any) => {
                              setFieldValue('testName', value, true);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='TEST NAME'
                                variant='outlined'
                                fullWidth
                              />
                            )}
                            disabled={
                              selectedSubjects.length && selectedGrades.length
                                ? false
                                : true
                            }
                          />
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <StyledDiv>
                            <Title variant="subtitle1">TOTAL TIME</Title>
                            <Caption variant="subtitle2">(Mins)</Caption>
                          </StyledDiv>
                          <CustomizedSlider
                            track="inverted"
                            value={minutes}
                            marks={Minutes}
                            max={120}
                            min={0}
                            step={5}
                            onChange={(
                              event: object,
                              value: number | number[]
                            ) => {
                              setMinutes(value);
                              values.minutes = value;
                            }}
                            disabled={
                              selectedSubjects.length && selectedGrades.length
                                ? false
                                : true
                            }
                          />
                        </Grid>
                        <Grid item xs={3} md={2}>
                          <StyledDiv>
                            <Title variant="subtitle1">MARKS</Title>
                            {/* <Caption variant='subtitle2'>(Mins)</Caption> */}
                          </StyledDiv>
                          <CustomizedSlider
                            track="inverted"
                            value={marks}
                            marks={Marks}
                            max={100}
                            min={15}
                            step={5}
                            onChange={(
                              event: object,
                              value: number | number[]
                            ) => {
                              setMarks(value);
                              values.marks = value;
                            }}
                            disabled={
                              selectedSubjects.length && selectedGrades.length
                                ? false
                                : true
                            }
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <SubmitButton
                            title="Apply"
                            color="red"
                            style={ButtonStyle}
                            isSubmitting={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <ClearButton disableElevation disableFocusRipple onClick={ClearForm} variant="contained">
                            CLEAR ALL
                          </ClearButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              ) : (
                // Mobile component
                <>
                  <Container>
                    <StyledHeader style={{ marginLeft: 0 }}>
                      {selectedSubjects.map((subject) => {
                      if (selectedSubjects.indexOf(subject) - 1 == selectedSubjects.length) {
                        return subject.name
                      } else if (selectedSubjects.length == 1 && selectedSubjects[0] == initialSubject) {
                        return subject.name
                      } else {
                        return subject.name + ", "
                      }
                    })} <br />
                      Grade {printGrades()}
                      <span> Tests</span>
                    </StyledHeader>
                  </Container>
                  <Grid container style={{ position: "relative" }}>
                    <MobileFilterContainer container direction="column">
                      <FormControl variant="outlined">
                        <InputLabel id="select-testType">TEST TYPE</InputLabel>
                        <Select
                          multiple
                          value={values.testType}
                          disabled
                          IconComponent={ExpandMoreIcon}
                          renderValue={(selected) => {
                            return (
                              (selected as string[]).length +
                              " TestType selected"
                            );
                          }}
                        >
                          {TestTypes.map((type: any, index: any) => (
                            <Item key={index} value={type}>
                              <ListItemText primary={` ${type}`} />
                            </Item>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel id="select-block">BLOCK NUMBER</InputLabel>
                        <Select
                          multiple
                          value={values.blocks}
                          disabled
                          IconComponent={ExpandMoreIcon}
                          renderValue={(selected) => {
                            return (
                              (selected as string[]).length + " Blocks selected"
                            );
                          }}
                        >
                          {dropDownData?.blocks.map(
                            (block: any, index: number) => (
                              <Item key={index} value={block.title}>
                                <ListItemText primary={` ${block.title}`} />
                              </Item>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel id="DIFFICULTY">DIFFICULTY</InputLabel>
                        <Select
                          multiple
                          disabled
                          value={values.difficulty}
                          IconComponent={ExpandMoreIcon}
                          renderValue={(selected) => {
                            return (
                              (selected as string[]).length +
                              " Difficulty selected"
                            );
                          }}
                        >
                          {Difficulty.map((type, index) => (
                            <Item key={index} value={type}>
                              <ListItemText primary={` ${type}`} />
                            </Item>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel id="QuestionType">QUESTION TYPE</InputLabel>
                        <Select
                          multiple
                          value={values.questionType}
                          disabled
                          IconComponent={ExpandMoreIcon}
                          renderValue={(selected) => {
                            return (
                              (selected as string[]).length +
                              " QuestionType selected"
                            );
                          }}
                        >
                          {QuestionType.map((type, index) => (
                            <Item key={index} value={type}>
                              <ListItemText primary={` ${type}`} />
                            </Item>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <FormControl variant="outlined">
                        <InputLabel id="ObjectiveType">OBJECTIVE TYPE</InputLabel>
                        <Select
                          value={values.objectiveType}
                          IconComponent={ExpandMoreIcon}
                          disabled
                          renderValue={(selected) => {
                            return selected as string;
                          }}
                        >
                          {ObjectiveType.map((type, index) => (
                            <Item key={index} value={type}>
                              <ListItemText primary={type} />
                            </Item>
                          ))}
                        </Select>
                          </FormControl> */}
                      <FormControl variant="outlined">
                        <InputLabel id="TestName">TEST NAME</InputLabel>
                        <Select
                          multiple
                          value={values.testName}
                          disabled
                          IconComponent={ExpandMoreIcon}
                          renderValue={(selected) => {
                            return (
                              (selected as string[]).length +
                              " TestName selected"
                            );
                          }}
                        >
                          {dropDownData?.testNameList.map(
                            (name: any, index: number) => (
                              <Item key={index} value={name.testName}>
                                <ListItemText primary={name.testName} />
                              </Item>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </MobileFilterContainer>
                    <FilterButton onClick={toggleDrawer}>
                      <TuneIcon />
                    </FilterButton>
                    <Drawer
                      anchor={"bottom"}
                      open={drawer}
                      onClose={toggleDrawer}
                    >
                      {formRef.current ? (
                        <FilterComponent
                          toggleDrawer={toggleDrawer}
                          TestName={
                            dropDownData ? dropDownData.testNameList : []
                          }
                          Blocks={dropDownData ? dropDownData.blocks : []}
                          formRef={formRef}
                          Minutes={Minutes}
                          Marks={Marks}
                          QuestionType={QuestionType}
                          ObjectiveType={ObjectiveType}
                          TestType={TestTypes}
                          Difficulty={Difficulty}
                          handleSubmit={() => {
                            handleSubmit();
                            toggleDrawer();
                          }}
                          ClearForm={ClearForm}
                          setMinutes={setMinutes}
                        />
                      ) : (
                        <div>Loading..</div>
                      )}
                    </Drawer>
                  </Grid>
                </>
              )}
            </Form>
          )}
        </Formik>
      </MainContainer>
      {selectedSubjects.length && selectedGrades.length ? (
        <DataGridTable
          filterApplied={filterApplied}
          formRef={formRef}
          stores={stores}
          refresh={refresh}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default injectWithObserver(ViewQuestion);

const StyledHeader = styled(Typography)`
  margin: 1.5rem;
  font-size: 1.1em;
  font-weight: 700;
  & span {
    font-weight: 400;
  }
`;
const Form = styled.form`
  width: 100%;
  height: 100%;
`;
const DropDown = styled(TextField)`
  width: 23%;
  border: 1px solid #999999;
  border-radius: 5px;
  margin-left: 2%;
  & .MuiFormLabel-root {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
  }
  & .MuiFilledInput-root {
    background-color: white;
  }
  & .MuiFilledInput-input {
    font-size: 0.7em;
    font-weight: 600;
  }
  & .MuiChip-root {
    margin-left: 10px;
    font-size: 1em;
    font-weight: 600;
  }
`;
const StyledDropDown = styled(TextField)`
width:35%;
// border-bottom: 2px solid #AF0000;
// border-radius:2px;
margin-left:2%;
& .MuiFilledInput-root {
  background-color: white
};
// & .MuiFilledInput-underline:before {
//   border-bottom: 2px solid #AF0000;
// }
`;
const Title = styled(Typography)`
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
`;
const Caption = styled(Title)`
  color: #666666;
  font-size: 0.8em;
  margin-top: 3px;
  margin-left: 2px;
`;
const StyledDiv = styled.div`
  display: flex;
`;
const ButtonStyle = {
  width: "100%",
  height: "60%",
  fontWeight: 'bold'
};

const ClearButton = styled(Button)`
  color: RGB(57, 86, 83);
  width: 100%;
  height: 60%;
  background: white;
  font-weight: bold;
`;
const Item = styled(MenuItem)`
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  & .MuiCheckbox-colorSecondary.Mui-checked {
    color: #af0000;
  }
  & .MuiSvgIcon-root {
    width: 20px;
  }
  & .MuiTypography-root {
    font-size: 16px;
  }
`;
const MobileFilterContainer = styled(Grid)`
  overflow: auto;
  padding: 10px;
  background-color: #f8f8f8;
  height: 85px;
  & .MuiFormControl-root {
    width: 200px;
    margin-right: 15px;
    background: white;
  }
`;
const FilterButton = styled(Button)`
  position: absolute;
  height: 100%;
  background-color: #f8f8f8;
  color: #666666;
  width: 100px;
  right: 0;
  z-index: 1;
  box-shadow: inset 2px 0px 0px 0px #b5b5b5;
  &:hover {
    background-color: #f8f8f8;
  }
`;
const MainContainer = styled(Paper)`
  height: 100%;
  width: 100%;
  padding: 0;
`;