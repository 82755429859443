import firebase from "firebase";
import { action, computed, observable } from "mobx";
import firebaseApp from "../../firebase";

class UserStore {
  @observable user: any | null = null;
  @observable schoolDetails: any | null = null;
  @observable academicYear: any | null = "";

  @action
  changeUser = (user: any | null) => {
    this.user = user;

    if(user == null) {
      firebaseApp.AuthSignOut();
    }
  };

  @action
  setSchoolDetails = (schoolDetails: any | null) => {
    this.schoolDetails = schoolDetails;
  };

  @action
  addNewTestType = (testType: any | null) => {
    if(this.schoolDetails) {
      this.schoolDetails.testTypes.push(testType);
    }
  };

  @action
  setAcademicYear = (academicYear : any | null) => {
    this.academicYear = academicYear;
  }
}
export default new UserStore();
