/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Formik, FormikHelpers } from "formik";
import { toJS } from "mobx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styled from "styled-components";
import * as Yup from "yup";
import SubmitButton from "../../components/Button/SubmitButton";
import CustomizedDialog from "../../components/Modal/CustomizedDialog";
import useSnackbar from "../../hooks/useSnackbar";
import { QuestionPaperRoute } from "../../Routes/RoutesConstants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { post } from "../../utils/Api";
import injectWithObserver from "../../utils/injectWithObserver";
import Line from "./Line";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { OneClickTests, TEST_TYPES_WITH_DEFAULT_BLOCKS } from "../../utils/oneClickTests";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
interface FormValues {
  testName: string;
  marks: number;
  minutes: number;
  blocks: string[];
  testType: string;
  difficulty: "Easy" | "Medium" | "Hard";
  questionType: "sa" | "all";
  objectiveType: "Yes" | "No";
}

interface SubjectProps {
  id: string;
  name: string;
  shortName: string;
}
interface GradeProps {
  id: string;
  name: string;
  displayName: string;
}

const initialFormValue: FormValues = {
  testName: "",
  marks: 15,
  minutes: 30,
  blocks: [],
  testType: "",
  difficulty: "Medium",
  questionType: "sa",
  objectiveType: "Yes",
};

export const Marklist: number[] = [10, 15, 20, 25, 30, 40, 50, 60, 75, 80, 100];
export const Difficulty: string[] = ["Easy", "Medium", "Hard"];
export const QuestionType: string[] = ["sa", "all"];
export const ObjectiveType: string[] = ["Yes", "No"];
const initialSubject: SubjectProps = { id: "", name: "", shortName: "" };
const initialGrade: GradeProps = { id: "", name: "", displayName: "" };

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    onScroll: (event: any) => {
      console.log("we scroll");
    },
    style: {
      maxHeight: 220,
      marginTop: 45,
      background: "#FAFAFA",
    },
  },
};

function CreateQuestion({ stores }: { stores: any }) {
  const TestTypes = stores.userStore.schoolDetails
    ? toJS(stores.userStore.schoolDetails.testTypes)
    : [];
  const [difficulty, setDifficulty] = useState(initialFormValue.difficulty);
  const [questionType, setQuestionType] = useState(
    initialFormValue.questionType
  );
  // const [objecttiveType, setObjecttiveType] = useState(
  //   initialFormValue.objectiveType
  // );
  const [blocks, setBlocks] = useState<any[]>([]);
  const Navigate = useNavigate();
  const snackBar = useSnackbar();
  const formRef: any = useRef();
  const [open, setOpen] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string>("");
  const [showTestTypeDialog, setShowTestTypeDialog] = useState<any>(false);
  const [newTestTypeName, setNewTestTypeName] = useState<any>("");
  const [testTypeErrorMessage, setTestTypeErrorMessage] = useState<any>("");
  const [newTestTypeInProgress, setNewTestTypeInProgress] = useState<any>(
    false
  );
  const [
    showTestExecutionLogDialog,
    setShowTestExecutionLogDialog,
  ] = useState<any>(false);
  const [showTestExecutionSuccess, setShowTestExecutionSuccess] = useState<any>(
    false
  );
  const [
    showTestExecutionFailureMessage,
    setShowTestExecutionFailureMessage,
  ] = useState<any>(false);
  const [testExecutionSuccessData, setTestExecutionSuccessData] = useState<any>(
    {}
  );
  const [testExecutionFailureData, setTestExecutionFailureData] = useState<any>(
    {}
  );
  const [testExecutionLogs, setTestExecutionLogs] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [boards, setBoards] = useState<any[]>([]);
  const [grades, setGrades] = useState<any[]>([]);
  const [selectedSubject, setSelectedSubject] = useState(initialSubject);
  const [selectedGrade, setSelectedGrade] = useState(initialGrade);
  // const [refresh, doRefresh] = useState<number>(0);
  var timer: any = null;

  const handleError = (_error: any) => {
    snackBar.show(
      _error,
      "error",
      { vertical: "bottom", horizontal: "center" },
      true
    );
  };
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    getGrades();
    formRef.current.setFieldValue("blocks", []);
    formRef.current.setFieldValue("testType", "");
    formRef.current.setFieldValue("testType", "");
  }, [selectedSubject, selectedGrade]);
  const handleSubmit = (
    {
      testName,
      marks,
      minutes,
      blocks,
      testType,
      difficulty,
      questionType,
      objectiveType,
    }: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    const minimumNoOfBlockNeedsToBeSelected: number = Math.ceil(minutes / 20);
    const selectedBlocksLength: number = blocks.length;

    let isValidOptionsSelected: boolean = true;
    let dialogErrorMessageText: string = "";
    if (selectedBlocksLength < minimumNoOfBlockNeedsToBeSelected) {
      isValidOptionsSelected = false;
      dialogErrorMessageText = `You have selected Test Time as ${minutes} minutes and ${selectedBlocksLength} blocks. In order to generate a Test - select at least ${minimumNoOfBlockNeedsToBeSelected} blocks.`;
    }

    const rules = OneClickTests.find((t) => t.testType.includes(testType));
    if (
      rules?.minNumberOfBlocks &&
      rules?.minNumberOfBlocks > 0 &&
      selectedBlocksLength < rules?.minNumberOfBlocks
    ) {
      isValidOptionsSelected = false;
      dialogErrorMessageText = `You have selected test type as ${testType}, so minimum of ${rules?.minNumberOfBlocks} blocks needs to be selected.`;
    }

    if (
      rules?.maxNumberOfBlocks &&
      rules?.maxNumberOfBlocks > 0 &&
      selectedBlocksLength > rules?.maxNumberOfBlocks
    ) {
      isValidOptionsSelected = false;
      dialogErrorMessageText = `You have selected test type as ${testType}, so maximum of only ${rules?.maxNumberOfBlocks} blocks allowed.`;
    }

    if (isValidOptionsSelected) {
      post("", {
        query: `query{ triggerTestGeneration (QuestionInput: { subjectId:${selectedSubject.id} ,gradeId:${selectedGrade.id} ,subjectName: \"${selectedSubject.name}\",gradeName: \"${selectedGrade.displayName}\",blockId: \"[${blocks}]\", testName: \"Draft Test\", testType: \"${testType}\", minutes: ${minutes},marks: ${marks}, objectiveType: \"${objectiveType}\",  difficulty: \"${difficulty}\", questionType: \"${questionType}\"} ) {  status executionLogId} }`, //eslint-disable-line
      })
        .then((res) => {
          if (
            res.data &&
            res.data.data &&
            res.data.data.triggerTestGeneration
          ) {
            const responseData = res.data.data.triggerTestGeneration;

            formikHelpers.setSubmitting(false);

            if (responseData.status) {
              startExecutionLog(responseData.executionLogId);
            } else {
              throw Error("Something went wrong, Please try again later.");
            }
          } else {
            throw Error("Something went wrong, Please try again later.");
          }
        })
        .catch((e) => {
          console.log(e);
          handleError("Something went wrong");
          formikHelpers.setSubmitting(false);
        });
    } else {
      setAlert(true);
      setDialogErrorMessage(dialogErrorMessageText);
      formikHelpers.setSubmitting(false);
    }
  };

  const startExecutionLog = (executionLogId: string) => {
    var testExecutionCallInProgress = false;
    setShowTestExecutionSuccess(false);
    setShowTestExecutionFailureMessage(false);
    setTestExecutionLogs([
      { status: "loading", message: "Generating test using your criteria." },
    ]);

    setShowTestExecutionLogDialog(true);

    let _executionLogTimer = setInterval(function () {
      if (testExecutionCallInProgress === true) {
        return;
      }

      testExecutionCallInProgress = true;
      post("", {
        query: `query{ getTestExecutionStatus(executionLogId: \"${executionLogId}\") { isCompleted masterQuestionsTime executionLog output { previewId,short{ _id, questionGroupId, stimulus, difficulty, questionType, questionMarks,time,writingLines,drawingBox,questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}},long { _id, questionGroupId, stimulus, difficulty, questionType, questionMarks,time,writingLines,drawingBox,questionData {questionText, answerGuideText,options {_id,optionText,isCorrectAnswer,misconception}, optionKeys{_id, optionText, sequence}, optionValues{_id, optionText, sequence}, blanks {_id, correctAnswers {_id, text}, sequence} distractors {_id, text}}},blocks {id,bId,title,displaySequence},questionDetails {testType,minutes,marks,difficulty,questionType,testName,subjectName, gradeName } }} }`, //eslint-disable-line
      })
        .then((res) => {
          if (
            res.data &&
            res.data.data &&
            res.data.data.getTestExecutionStatus
          ) {
            const responseData = res.data.data.getTestExecutionStatus;
            const executionResponse = responseData.executionLog || [];

            let executionLogsObject = [];
            for (let i = 0; i < executionResponse.length; i++) {
              const logText = executionResponse[i];
              let status = "failed";
              if (logText) {
                if (i == executionResponse.length - 1) {
                  if (responseData.isCompleted) {
                    if (responseData.output.short.length > 0) {
                      status = "success";
                    } else {
                      status = "failed";
                    }
                  } else {
                    status = "loading";
                  }
                }

                executionLogsObject.push({
                  status: status,
                  message: logText,
                });
              }
            }

            setTestExecutionLogs(executionLogsObject);
            scrollToBottomOfExecutionLog();

            if (responseData.isCompleted) {
              stopExecutionLog();

              if (responseData.output.short.length > 0) {
                setShowTestExecutionSuccess(true);
                setTestExecutionSuccessData(responseData.output);
              } else {
                setTestExecutionFailureData({
                  totalTime: responseData.masterQuestionsTime,
                });
                setShowTestExecutionFailureMessage(true);
              }
            } else {
              testExecutionCallInProgress = false;
            }

            testExecutionCallInProgress = false;
          } else {
            throw Error("Something went wrong, Please try again later.");
          }
        })
        .catch((e) => {
          console.log(e);
          handleError("Something went wrong");
          handleTestExecutionLogeDialogClose();
          testExecutionCallInProgress = false;
        });
    }, 1000);

    timer = _executionLogTimer;
  };

  const goToTestPage = () => {
    if (testExecutionSuccessData) {
      stores.QuestionsStore.changeQuestions(testExecutionSuccessData);
      Navigate(
        QuestionPaperRoute.replace(":id", testExecutionSuccessData.previewId)
      );
    }
  };

  const scrollToBottomOfExecutionLog = () => {
    const element = document.getElementById("test-execution-log-outer-wrapper");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const stopExecutionLog = () => {
    clearInterval(timer);
  };

  const handleTestExecutionLogeDialogClose = () => {
    stopExecutionLog();
    setShowTestExecutionLogDialog(false);
  };

  const getBlocks = () => {
    setBlocks([]);
    if (selectedGrade.id && selectedSubject.id) {
      post("", {
        query: ` query { getBlocks(subjectId: ${selectedSubject.id}, standardId: ${selectedGrade.id}){ id, bId, title, displaySequence, quarter, enabled}}`,
      })
        .then((res) => {
          console.log("GetBlocks:", res.data);
          setBlocks(res.data.data.getBlocks || []);
        })
        .catch((e) => {
          console.log(e);
          handleError("Something went wrong");
        });
    }
  };

  const ResetFieldValues = () => {
    formRef.current.setFieldValue("blocks", []);
    formRef.current.setFieldValue("testType", "");
    formRef.current.setFieldValue("testType", "");
  };

  const onTestTypeChange = (event: any, handleChange: Function) => {
    const rules = OneClickTests.find((t) =>
      t.testType.includes(event.target.value)
    );
    if (rules) {
      let rulesObject;
      if (rules.subRules) {
        const standard = toJS(stores.userStore.schoolDetails.standards).find(
          (s: { name: string }) => s.name === selectedGrade.name
        );
        const board = boards.find(
          (b) => b.id === standard.globalBoardId.toString()
        );
        const product = products.find(
          (b) => b.id === standard.globalProductId.toString()
        );
        const combination = rules.subRules.find(
          (sb) =>
            sb.combo &&
            sb.combo.find(
              (c) =>
                c.board === board.name &&
                c.product === product.name &&
                c.subject === selectedSubject.shortName &&
                c.grade.indexOf(standard.name) > -1
            )
        );
        if (combination) {
          if (
            combination.minutes ||
            combination.marks ||
            combination.difficulty ||
            combination.questionType
          ) {
            rulesObject = combination;
          }
        } else {
          rulesObject = rules.subRules[0];
        }
      } else {
        rulesObject = rules;
      }
      if (rulesObject && rulesObject.difficulty && rulesObject.questionType) {
        getModifiedMarks(rulesObject.minutes);
        formRef.current.setFieldValue("minutes", rulesObject.minutes);
        formRef.current.setFieldValue("marks", rulesObject.marks);
        formRef.current.setFieldValue("difficulty", rulesObject.difficulty);
        formRef.current.setFieldValue("questionType", rulesObject.questionType);
        setDifficulty(rulesObject.difficulty);
        setQuestionType(rulesObject.questionType);
      }
    }

    const selectedTestTypeShortName = TEST_TYPES_WITH_DEFAULT_BLOCKS[event.target.value as string];
    let defaultSelectedBlocks: string[] = [];
    if (selectedTestTypeShortName !== undefined) {
      try {
        defaultSelectedBlocks = blocks
          .filter((_b) => _b.testTypes.includes(selectedTestTypeShortName) && _b.enabled)
          .map((_b) => _b.id);
      } catch (error) {
        defaultSelectedBlocks = [];
      }
    }
    formRef.current.setFieldValue("blocks", defaultSelectedBlocks);

    if (event.target.value == "Others") {
      setShowTestTypeDialog(true);
      setNewTestTypeInProgress(false);
      setNewTestTypeName("");
    } else {
      handleChange("testType")(event);
    }
  };

  useEffect(() => {
    getBlocks();
    ResetFieldValues();
  }, [selectedGrade, selectedSubject]);

  console.log(selectedSubject, selectedGrade, "selectedSubject");

  useEffect(() => {
    // Adding the below code for generating the marks dropdown on load
    getModifiedMarks(initialFormValue.minutes);
    // This lines of code is used for preserving the values in mobx and get it back
    if (formRef.current) {
      if (stores.FormDataStore.getCreateQuestionPaper) {
        const {
          testName,
          testType,
          questionType,
          objectiveType,
          minutes,
          marks,
          difficulty,
          blocks,
        } = toJS(stores.FormDataStore.getCreateQuestionPaper);
        formRef.current.values.testName = testName;
        formRef.current.values.testType = testType;
        formRef.current.values.questionType = questionType;
        formRef.current.values.objectiveType = objectiveType;
        formRef.current.values.minutes = minutes;
        formRef.current.values.marks = marks;
        formRef.current.values.difficulty = difficulty;
        formRef.current.values.blocks = blocks;
        setDifficulty(difficulty);
        setQuestionType(questionType);
        // setObjecttiveType(objectiveType);
      } else {
        console.log("Create question form value is resetting");
        formRef.current.values.testName = "";
        formRef.current.values.testType = "";
        formRef.current.values.questionType = "sa";
        formRef.current.values.objectiveType = "Yes";
        formRef.current.values.minutes = 30;
        formRef.current.values.marks = 15;
        formRef.current.values.difficulty = "Medium";
        formRef.current.values.blocks = [];
        setDifficulty("Medium");
        setQuestionType("sa");
        // setObjecttiveType("Yes");
      }
    }
    getProductsBoards();
    getGrades();
    getSubjects();
    if (stores.FormDataStore.SelectedSubject) {
      setSelectedSubject(toJS(stores.FormDataStore.getSelectedSubject));
    }
    if (stores.FormDataStore.SelectedGrade) {
      setSelectedGrade(toJS(stores.FormDataStore.getSelectedGrade));
    }
    return () => {
      console.log("Create question clean up");
      if (formRef.current) {
        // console.log(formRef.current);
        stores.FormDataStore.changeCreateQuestionPaperForm(
          formRef.current.values
        );
      }
    };
  }, []);
  const [validMarks, setValidMarks] = useState<number[]>(Marklist);
  const handleTimeChange = (e: any) => {
    getModifiedMarks(e.target.value);
  };

  const getModifiedMarks = (selectedTime: number) => {
    const minMark = selectedTime * 0.5;
    const maxMark = selectedTime * 2;
    const validMarksArray = Marklist.filter((mark) => {
      if (mark >= minMark && mark <= maxMark) {
        return mark;
      }
    });
    console.log("VALID MARKS", validMarksArray);
    setValidMarks(validMarksArray || []);
  };

  const handleNewTestTypeDialogClose = () => {
    setShowTestTypeDialog(false);
  };

  const handleTestTypeSave = () => {
    const inSchoolId = stores.userStore.schoolDetails
      ? stores.userStore.schoolDetails.inSchoolId
      : null;
    setNewTestTypeInProgress(true);

    if (inSchoolId && newTestTypeName) {
      post("", {
        query: `mutation { createSchoolTestType (inSchoolId: ${inSchoolId}, testTypeName: \"${newTestTypeName}\") }`,
      })
        .then((res) => {
          setShowTestTypeDialog(false);
          formRef.current.setFieldValue("testType", newTestTypeName);
          stores.userStore.addNewTestType(newTestTypeName);
          setNewTestTypeInProgress(false);
        })
        .catch((e) => {
          setNewTestTypeInProgress(false);
          setShowTestTypeDialog(false);
        });
    }
  };

  const handleTestTypeChange = (event: any) => {
    const input = event.target.value;
    setNewTestTypeName(input);

    const errorMessage = validateTestType(input);

    if (errorMessage.length) {
      setTestTypeErrorMessage(errorMessage);
    } else {
      setTestTypeErrorMessage("");
    }
  };

  const validateTestType = (testType: any) => {
    const testTypeLower = testType.toLowerCase();

    if (!testType.length) {
      return "Please enter test type.";
    }

    if (testType == "others") {
      return "Test type with name 'Others' is not allowed.";
    }

    const hasExistingTestTypeIndex = TestTypes.findIndex(
      (_: any) => _.toLowerCase() == testTypeLower
    );
    if (hasExistingTestTypeIndex != -1) {
      return "The test type already exists.";
    }

    return "";
  };

  const digitsOnly = (value: any) => /^\d*[\.{1}\d*]\d*$/.test(value);

  const getProductsBoards = () => {
    post("", {
      query:
        "query { getProductBoardList {boards{id, name}, products{id, name} }  }",
    })
      .then((res) => {
        console.log("GetProductsBoards:", res.data);
        setProducts(res.data.data.getProductBoardList.products);
        setBoards(res.data.data.getProductBoardList.boards);
      })
      .catch((e) => console.log(e));
  };

  const getSubjects = () => {
    post("", {
      query: "query { getSubjects {id, name, shortName}  }",
    })
      .then((res) => {
        console.log("GetSubjects:", res.data);
        setSubjects(res.data.data.getSubjects);
      })
      .catch((e) => console.log(e));
  };

  const getGrades = () => {
    console.log(selectedSubject, "ingradesss");
    if (selectedSubject.id) {
      const subjectIdArray = [selectedSubject.id];
      post("", {
        query: `query { getGrade(subjectIds: [${subjectIdArray}]){ id, name, displayName }}`,
      })
        .then((res) => {
          console.log("GetGrades:", res.data);
          setGrades(res.data.data.getGrade);
        })
        .catch((e) => console.log(e));
    }
  };

  const resetVal = () => {
    setSelectedSubject(initialSubject);
    setSelectedGrade(initialGrade);
  };

  return (
    <MainContainer
      style={{
        border: "1px solid RGB(149, 149, 149)",
        borderRadius: "0px 5px 5px 5px",
      }}
    >
      <Container>
        <Grid
          item
          md={12}
          xs={12}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Grid
            item
            md={6}
            xs={12}
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledDropDown
              id="filled-select-subject"
              select
              label="Select subject"
              style={{ border: "0.5px solid #939393" }}
              value={selectedSubject.name}
              InputProps={{ disableUnderline: true }}
              variant="filled"
              onChange={(e: any) => {
                setSelectedSubject(e.target.value);
                stores.FormDataStore.changeSelectedSubject(e.target.value);
                // setGrades([]);
                setSelectedGrade(initialGrade);
                localStorage.removeItem("subID");
                localStorage.setItem(e.target.value.id, "subID");
              }}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
                renderValue: (selected: any) => selectedSubject.name,
                MenuProps: MenuProps,
              }}
            >
              {/* <MenuItem value={initialSubject.name}>None</MenuItem> */}
              {subjects &&
                subjects.map((subject) => (
                  <MenuItem key={subject.id} value={subject}>
                    {subject.name}
                  </MenuItem>
                ))}
            </StyledDropDown>
            <StyledDropDown
              style={{ border: "0.5px solid #939393" }}
              id="filled-select-grade"
              select
              label="Select grade"
              value={selectedGrade.displayName}
              InputProps={{ disableUnderline: true }}
              variant="filled"
              disabled={selectedSubject.name ? false : true}
              onChange={(e: any) => {
                setSelectedGrade(e.target.value);
                localStorage.setItem(e.target.value.id, "gradeID");
                stores.FormDataStore.changeSelectedGrade(e.target.value);
                // doRefresh((prev: number) => prev + 1);
                // reLoader()
              }}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
                renderValue: (selected: any) => selectedGrade.displayName,
                MenuProps: MenuProps,
              }}
            >
              {/* <MenuItem value={initialGrade.displayName}>None</MenuItem> */}
              {grades.map((grade) => (
                <MenuItem key={grade.id} value={grade}>
                  {grade.displayName}
                </MenuItem>
              ))}
            </StyledDropDown>
            <Button
              style={{
                color: "RGB(102, 102, 102)",
                padding: 0,
                fontSize: "0.8em",
                lineHeight: "20px",
                height: "40px",
                width: "150px",
                borderRadius: 5,
                border: "1px solid RGB(102, 102, 102)",
                marginLeft: "60px",
                background: "transparent",
              }}
              disableElevation
              onClick={resetVal}
              variant="contained"
              size="small"
            >
              Clear selection
            </Button>
          </Grid>
          <Typography
            style={{
              fontSize: "12px",
              marginLeft: "15px",
              marginBottom: "5px",
            }}
          >
            {
              "NOTE : Available Subject and Grade combination is as per your XSEED SuperTeacher subscription."
            }
          </Typography>
        </Grid>
        <StyledHeader>
          {selectedSubject.name} | Grade {selectedGrade.displayName}
          <span> Tests</span>
          <Grid>
            <Dialog
              onClose={handleNewTestTypeDialogClose}
              aria-labelledby="customized-dialog-title"
              open={showTestTypeDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleNewTestTypeDialogClose}
              >
                Add New Test Type
              </DialogTitle>
              <DialogContent>
                {newTestTypeInProgress ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: 120 }}
                  >
                    <CircularProgress />
                    <DialogText>Loading...</DialogText>
                  </Grid>
                ) : (
                  <form>
                    <TextField
                      id="newTestType"
                      variant="outlined"
                      label="Test Type"
                      value={newTestTypeName}
                      name="testName"
                      type="string"
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      onChange={handleTestTypeChange}
                      helperText={testTypeErrorMessage}
                      error={testTypeErrorMessage.length > 0}
                    />
                  </form>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  style={{ background: "#af0000", color: "#fff" }}
                  disabled={testTypeErrorMessage.length > 0}
                  onClick={handleTestTypeSave}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={handleTestExecutionLogeDialogClose}
              aria-labelledby="customized-dialog-title"
              open={showTestExecutionLogDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleTestExecutionLogeDialogClose}
              >
                <p>
                  {showTestExecutionSuccess
                    ? "Test generation successful"
                    : showTestExecutionFailureMessage
                    ? "Test generation failed"
                    : "Test generation in progress"}
                </p>
                {/*  */}
              </DialogTitle>
              <DialogContent>
                <TestExecutionLogWrapper
                  id={"test-execution-log-outer-wrapper"}
                >
                  <div className={"test-execution-log-wrapper"}>
                    {testExecutionLogs.map((log) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: 10 }}>
                            <div>
                              {log.status == "loading" ? (
                                <CircularProgress size={22} />
                              ) : log.status == "failed" ? (
                                <ClearIcon style={{ color: "red" }} />
                              ) : (
                                <CheckIcon style={{ color: "green" }} />
                              )}
                            </div>
                          </div>
                          <div>{log.message}</div>
                        </div>
                      );
                    })}
                  </div>
                </TestExecutionLogWrapper>
                <br />
                {showTestExecutionSuccess ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      style={{ background: "#af0000", color: "#fff" }}
                      onClick={goToTestPage}
                    >
                      View Test
                    </Button>
                  </div>
                ) : null}
                {showTestExecutionFailureMessage ? (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 18,
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Sorry! We were not able to generate a test for you.{" "}
                    {!isNaN(testExecutionFailureData.totalTime)
                      ? `Only ${testExecutionFailureData.totalTime} minutes worth of questions are available for the filter criteria you chose.`
                      : null}{" "}
                    Please try different criteria.
                  </div>
                ) : null}
              </DialogContent>
              <DialogActions></DialogActions>
              {/* <DialogActions>
                <Button
                  variant="contained"
                  style={{ background: "#af0000", color: "#fff" }}
                  onClick={handleTestTypeSave}
                >
                  Create
                </Button>
              </DialogActions> */}
            </Dialog>
          </Grid>
        </StyledHeader>
        <Line />
        <Formik
          initialValues={initialFormValue}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validationSchema={Yup.object().shape({
            // testName: Yup.string().required('Testname is required'),
            marks: Yup.number()
              .required("Mark is required")
              .min(1, "Mark is required"),
            minutes: Yup.number()
              .required("Time is required")
              .min(15, "Min 15 mins only")
              .max(180, "Max 180 mins only ")
              .test(
                "inputEntry",
                "The field should have digits only",
                digitsOnly
              ),
            blocks: Yup.array()
              .required("Blocks is required")
              .max(30, "Maximum 30 blocks only allowed"),
            testType: Yup.string().required("Test type is required"),
            difficulty: Yup.string().required("Difficulty is required"),
            questionType: Yup.string().required("QuestionType is required"),
            objectiveType: Yup.string().required("ObjectiveType is required"),
          })}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item md={2} xs={8}>
                  <FormControl
                    id="testType"
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.testType && errors.testType)}
                  >
                    <InputLabel id="select-testtype">Test Type</InputLabel>
                    <Select
                      value={values.testType}
                      onChange={(e) => onTestTypeChange(e, handleChange)}
                      label="Test Type"
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        return selected as string;
                      }}
                      MenuProps={MenuProps}
                      style={{ maxHeight: 300 }}
                      disabled={
                        selectedSubject.name && selectedGrade.name
                          ? false
                          : true
                      }
                    >
                      {[...TestTypes, "Others"].map((type: any) => (
                        <Item key={type} value={type}>
                          {/* <Checkbox checked={values.testType === type} /> */}
                          <ListItemText primary={type} />
                        </Item>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(touched.testType && errors.testType) ? (
                    <HelperText>
                      {touched.testType && errors.testType}
                    </HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={2} xs={6}>
                  <TextField
                    id="hour"
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.minutes && errors.minutes)}
                    label=" Total Time (Mins)"
                    name="minutes"
                    type="number"
                    value={values.minutes}
                    onChange={(e) => {
                      setFieldValue("minutes", e.target.value);
                      setFieldValue("marks", 0);
                      handleTimeChange(e);
                    }}
                    onBlur={handleBlur}
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  />
                  {Boolean(touched.minutes && errors.minutes) ? (
                    <HelperText>{touched.minutes && errors.minutes}</HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={2} xs={6}>
                  <FormControl
                    id="marks"
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.marks && errors.marks)}
                  >
                    <InputLabel id="select-mark">Marks</InputLabel>
                    <Select
                      value={values.marks || ""}
                      onChange={handleChange("marks")}
                      label="Marks"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      disabled={
                        selectedSubject.name && selectedGrade.name
                          ? false
                          : true
                      }
                    >
                      {validMarks.map((mark) => (
                        <Item key={mark} value={mark}>
                          {mark}
                        </Item>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(touched.marks && errors.marks) ? (
                    <HelperText>{touched.marks && errors.marks}</HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={3} xs={8}>
                  <FormControl
                    id="block"
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.blocks && errors.blocks)}
                  >
                    <InputLabel id="select-block">Select Blocks</InputLabel>
                    <Select
                      multiple
                      value={values.blocks || []}
                      onChange={(event, actionMeta) => {
                        const value = event.target.value;

                        if ((value as string[])[(value as string[]).length - 1] === "select all") {
                          setFieldValue(
                            "blocks",
                            values.blocks.length === blocks.length
                              ? []
                              : blocks.filter((data) => data.enabled).map((i: any) => i.id)
                          );
                          return;
                        }

                        const currentvalue = (actionMeta as any)?.props.value;
                        let find = blocks.find((data) => { return data.id === currentvalue; });
                        if(find.enabled) {
                          setFieldValue("blocks", event.target.value);
                        }
                         
                      }}
                      label="Select Blocks"
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        return (
                          (selected as string[]).length + " Blocks selected"
                        );
                      }}
                      MenuProps={{
                        autoFocus: false,
                        getContentAnchorEl: null,
                        PaperProps: {
                          onScroll: (event: any) => {
                            console.log("we scroll");
                          },
                          style: {
                            maxHeight: 200,
                            marginTop: 45,
                            background: "#FAFAFA",
                          },
                        },
                      }}
                      disabled={ values.blocks.length === blocks.length ? true : false} 
                      >
                      <Item value="select all">
                        <Checkbox
                          checked={
                            blocks.length > 0 &&
                            values.blocks.length === blocks.length
                          }
                          indeterminate={
                            values.blocks.length > 0 &&
                            values.blocks.length < blocks.length
                          }
                        />
                        <ListItemText primary="Select All" />
                      </Item>
                      {/* pointer-events: none; */}
                      {blocks.map((block, index) => (
                        <Item key={index} value={block.id}>
                          <Checkbox
                            checked={values.blocks.indexOf(block.id) > -1}
                            disabled={ !block.enabled }
                          />
                          <ListItemText
                            primary={`Block ${index + 1} - ${block.title}`}
                            style={{ color: `${block.enabled ? "black" : "#929292"}` }}
                          />
                        </Item>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(touched.blocks && errors.blocks) ? (
                    <HelperText>{touched.blocks && errors.blocks}</HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={4} xs={12}>
                  <Label
                    style={{ marginLeft: "10px" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Difficulty Level
                  </Label>
                  <CustomTypeButton
                    variant="outlined"
                    style={
                      difficulty === "Easy" ? buttonSelected : buttonUnSelected
                    }
                    onClick={() => {
                      setDifficulty("Easy");
                      values.difficulty = "Easy";
                    }}
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  >
                    Easy
                  </CustomTypeButton>
                  <CustomTypeButton
                    variant="outlined"
                    style={
                      difficulty === "Medium"
                        ? buttonSelected
                        : buttonUnSelected
                    }
                    onClick={() => {
                      setDifficulty("Medium");
                      values.difficulty = "Medium";
                    }}
                    value="Medium"
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  >
                    Medium
                  </CustomTypeButton>
                  <CustomTypeButton
                    variant="outlined"
                    style={
                      difficulty === "Hard" ? buttonSelected : buttonUnSelected
                    }
                    onClick={() => {
                      setDifficulty("Hard");
                      values.difficulty = "Hard";
                    }}
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  >
                    Hard
                  </CustomTypeButton>
                  {Boolean(errors.difficulty) ? (
                    <HelperText>{errors.difficulty}</HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={5} xs={12}>
                  <Label
                    variant="subtitle2"
                    gutterBottom
                    style={{ display: "flex", marginLeft: "10px" }}
                  >
                    Question Type
                    <span style={{ marginLeft: "10px" }}>
                      <LightTooltip
                        placement="right"
                        title=" Questions are marked short or long depending on whether it takes less than 5 minutes or more."
                        arrow
                      >
                        <InfoOutlinedIcon style={{ fontSize: "14px" }} />
                      </LightTooltip>
                    </span>
                  </Label>
                  <CustomTypeButton
                    variant="outlined"
                    style={
                      questionType === "sa" ? buttonSelected : buttonUnSelected
                    }
                    onClick={() => {
                      setQuestionType("sa");
                      values.questionType = "sa";
                    }}
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  >
                    Short
                  </CustomTypeButton>
                  <CustomTypeButton
                    disableRipple
                    variant="outlined"
                    style={
                      questionType === "all" ? buttonSelected : buttonUnSelected
                    }
                    onClick={() => {
                      setQuestionType("all");
                      values.questionType = "all";
                    }}
                    disabled={
                      selectedSubject.name && selectedGrade.name ? false : true
                    }
                  >
                    Short and Long
                  </CustomTypeButton>
                  {Boolean(errors.questionType) ? (
                    <HelperText>{errors.questionType}</HelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item md={3} xs={12}>
                  <Label variant="subtitle2" gutterBottom>
                    Include Objective Type Questions
                  </Label>
                  <CustomTypeButton
                    variant="outlined"
                    style={objecttiveType === "Yes" ? buttonSelected : buttonUnSelected}
                    onClick={() => {
                      setObjecttiveType("Yes");
                      values.objectiveType = "Yes";
                    }}
                   disabled={selectedSubject.name && selectedGrade.name ? false : true}
                  >
                    Yes
                  </CustomTypeButton>
                  <CustomTypeButton
                    variant="outlined"
                    style={objecttiveType === "No" ? buttonSelected : buttonUnSelected}
                    onClick={() => {
                      setObjecttiveType("No");
                      values.objectiveType = "No";
                    }}
                   disabled={selectedSubject.name && selectedGrade.name ? false : true}
                  >
                    No
                  </CustomTypeButton>
                  {Boolean(errors.objectiveType) ? <HelperText>{errors.objectiveType}</HelperText> : ""}
                  </Grid> */}

                <CustomizedDialog
                  open={open}
                  toggleDialog={() => {
                    setOpen(!open);
                  }}
                  title={"Information"}
                  actionTitle={"OK"}
                  cancelBtn={""}
                  onClose={() => {
                    setOpen(false);
                  }}
                  content={
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: 120 }}
                    >
                      <DialogText>
                        {
                          "Sorry, we did not find any Test matching the specified search criteria"
                        }
                      </DialogText>
                    </Grid>
                  }
                  onConfirm={() => {
                    setOpen(false);
                  }}
                />
                <CustomizedDialog
                  open={isAlert}
                  toggleDialog={() => {
                    setAlert(!open);
                  }}
                  title={"Information"}
                  actionTitle={"OK"}
                  cancelBtn={""}
                  onClose={() => {
                    setAlert(false);
                  }}
                  content={
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: 120 }}
                    >
                      <DialogText>
                        {`${dialogErrorMessage}`}
                      </DialogText>
                    </Grid>
                  }
                  onConfirm={() => {
                    setAlert(false);
                  }}
                />
                <Grid container justify="center" alignItems="center">
                  <SubmitButton
                    title="Submit"
                    isSubmitting={isSubmitting}
                    style={SubmitButtonStyle}
                    color="red"
                    selectedSubject={selectedSubject}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </MainContainer>
  );
}

export default injectWithObserver(CreateQuestion);

const StyledHeader = Styled(Typography)`
  margin: 1.5rem;
  font-size: 1.1em;
  font-weight: 700;
  & span {
    font-weight: 400;
  }
`;
const Form = Styled.form`
width :100%;
height:100%;
`;
const SubmitButtonStyle = {
  width: "240px",
  height: "50px",
  margin: "25px",
  borderRadius: "5px",
  fontWeight: "700",
  fontSize: "1.12em",
  letterSpacing: "0.12px",
};

const Label = Styled(Typography)`
  color:#000000;
  font-size:1rem;
  letter-spacing: 0.11px;
  font-weight: 600;
`;
const buttonSelected = {
  backgroundColor: "RGB(255, 232, 232)",
  color: "RGB(175, 0, 0)",
  border: "1px solid RGB(175, 0, 0)",
};
const buttonUnSelected = {};

const CustomTypeButton = Styled(Button)`
  text-transform:none;
  color: RGB(51, 51, 51);
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 0.9rem;
  min-width: 100px;
  height: 38px;
  cursor:pointer;
`;
const HelperText = Styled(FormHelperText)`
  position: absolute;
  color:#f44336;
`;
const Item = Styled(MenuItem)`
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  & .MuiCheckbox-colorSecondary.Mui-checked{
    color:#af0000;
  };
  & .MuiSvgIcon-root{
    width:20px; 
  };
  & .MuiTypography-root{
    font-size: 16px;
  }  
`;
const MainContainer = Styled(Paper)`
  height:100%;
  width:100%;
  padding:0;
`;
const DialogText = Styled(Typography)`
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;
const TestExecutionLogWrapper = Styled(Typography)`
  max-height: 50vh;
  overflow: scroll;
`;

const StyledDropDown = Styled(TextField)`
width:35%;
// border-bottom: 2px solid #AF0000;
// border-radius:2px;
margin-left:2%;
& .MuiFilledInput-root {
  background-color: white
};
// & .MuiFilledInput-underline:before {
//   border-bottom: 2px solid #AF0000;
// }
`;
