/* eslint-disable react/display-name */
// eslint-disable-next-line
import ApexCharts from "apexcharts";
import React, { useEffect } from "react";

function ChartTwo({ gradeGraph }: any) {
  useEffect(() => {
    if (gradeGraph.length > 0) {
      var options = {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: "Used",
            data: gradeGraph.map((x: any) => x.usedPaper),
          },
          {
            name: "Unused",
            data: gradeGraph.map((x: any) => (x.saveQuestion - x.usedPaper)),
          },
        ],
        colors: ["#5C8985", "#D8D8D8"],
        plotOptions: {
          bar: {
            // barHeight: 10,
            borderRadius: 10,
            columnWidth: "40%",
            barHeight: "20%",
            dataLabels: {
              hideOverflowingLabels: false,
              position: "top",
            },
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          categories: gradeGraph.map((x: any) => x._id),
          labels: {
            show: true,
            rotate: 0,
            hideOverlappingLabels: false,
            trim: true,
            style: {
              colors: [],
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              cssClass: "apexcharts-xaxis-label",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#000000"],
          },
          formatter: function (val: any) {
            return val.toString().replace("-", "");
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false, // you can either change hear to disable all grids
          // yaxis: {
          //   lines: {
          //     show: true, //or just here to disable only x axis grids
          //   },
          // },
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val.toString().replace("-", "");
            },
          },
        },
      };

      var chart = new ApexCharts(document.querySelector("#chart_two"), options);
      chart.render();
    }
  }, [gradeGraph]);
  return <div id="chart_two" style={{ width: "95%" }}></div>;
}

export default ChartTwo;
