/* eslint-disable react/display-name */
// eslint-disable-next-line
import { Button, Checkbox, Grid, ListItem, Slider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import styled from "styled-components";
import SubmitButton from "../../../components/Button/SubmitButton";
import useForceUpdate from "../../../utils/useForceUpdate";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: 10 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "65vh",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#F6F6F6",
    width: "45vw",
    "& .Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiButtonBase-root": {
      fontSize: 16,
      fontWeight: 600,
    },
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        marginTop: 4,
        marginLeft: 5,
        marginBottom: 0,
      },
    },
  },
}));

function FilterComponent({
  toggleDrawer,
  formRef,
  Minutes,
  TestType,
  Difficulty,
  ObjectiveType,
  QuestionType,
  TestName,
  Blocks,
  handleSubmit,
  ClearForm,
  setMinutes,
}: any) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const forceUpdate = useForceUpdate();
  const FormType: any = {
    0: "testType",
    1: "blocks",
    2: "difficulty",
    3: "questionType",
    4: "objectiveType",
    5: "testName",
    6: "minutes",
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const handleValueClick = (item: any) => {
    if (formRef.current) {
      if (FormType[tabValue] === "objectiveType") {
        formRef.current.values.objectiveType = item;
      } else {
        const currentItem = formRef.current.values[FormType[tabValue]].indexOf(item);
        const newItems = [...formRef.current.values[FormType[tabValue]]];

        if (currentItem === -1) {
          newItems.push(item);
        } else {
          newItems.splice(currentItem, 1);
        }

        formRef.current.values[FormType[tabValue]] = newItems;
      }
      forceUpdate();
    }
  };

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    // console.log(formRef.current.values[FormType[tabValue]]);
    if (FormType[tabValue] === "blocks") {
      return (
        <ListItem button style={style} key={index} onClick={() => handleValueClick(data[index].id)}>
          <Checkbox edge="start" checked={formRef.current.values[FormType[tabValue]].indexOf(data[index].id) > -1} />
          <ListText>{data[index].title}</ListText>
        </ListItem>
      );
    } else if (FormType[tabValue] === "questionType") {
      return (
        <ListItem button style={style} key={index} onClick={() => handleValueClick(data[index])}>
          <Checkbox edge="start" checked={formRef.current.values[FormType[tabValue]].indexOf(data[index]) > -1} />
          <ListText>{data[index] === "sa" ? "Short question only" : "Short and long questions"}</ListText>
        </ListItem>
      );
    } else if (FormType[tabValue] === "testName") {
      return (
        <ListItem button style={style} key={index} onClick={() => handleValueClick(data[index].testName)}>
          <Checkbox
            edge="start"
            checked={formRef.current.values[FormType[tabValue]].indexOf(data[index].testName) > -1}
          />
          <ListText>{data[index].testName}</ListText>
        </ListItem>
      );
    } else {
      return (
        <ListItem button style={style} key={index} onClick={() => handleValueClick(data[index])}>
          <Checkbox
            edge="start"
            checked={
              FormType[tabValue] === "objectiveType"
                ? formRef.current.values[FormType[tabValue]] === data[index]
                : formRef.current.values[FormType[tabValue]].indexOf(data[index]) > -1
            }
          />
          <ListText>{data[index]}</ListText>
        </ListItem>
      );
    }
  };

  return (
    <Grid container>
      <Header container>
        <Text>Filter</Text>
        <IconButton edge="start" color="inherit" onClick={toggleDrawer} aria-label="close">
          <Close />
        </IconButton>
      </Header>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="TEST Type" icon={tabValue === 0 ? <ExpandMoreIcon /> : ""} {...a11yProps(0)} />
          <Tab label="Blocks" icon={tabValue === 1 ? <ExpandMoreIcon /> : ""} {...a11yProps(1)} />
          <Tab label="Difficulty Level" icon={tabValue === 2 ? <ExpandMoreIcon /> : ""} {...a11yProps(2)} />
          <Tab label="Question Type" icon={tabValue === 3 ? <ExpandMoreIcon /> : ""} {...a11yProps(3)} />
          {/* <Tab
            label="Objective Type"
            icon={tabValue === 4 ? <ExpandMoreIcon /> : ""}
            {...a11yProps(4)}
          /> */}
          <Tab label="Test Name" icon={tabValue === 5 ? <ExpandMoreIcon /> : ""} {...a11yProps(5)} />
          <Tab label="Total Time" icon={tabValue === 6 ? <ExpandMoreIcon /> : ""} {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={46}
            itemCount={TestType.length}
            itemData={TestType}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={60}
            itemCount={Blocks.length}
            itemData={Blocks}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={46}
            itemCount={Difficulty.length}
            itemData={Difficulty}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={46}
            itemCount={QuestionType.length}
            itemData={QuestionType}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={46}
            itemCount={ObjectiveType.length}
            itemData={ObjectiveType}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <FixedSizeList
            height={450}
            width={"100%"}
            style={{ width: "55vw" }}
            itemSize={60}
            itemCount={TestName.length}
            itemData={TestName}
          >
            {renderRow}
          </FixedSizeList>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Container>
            <Typography className="title">SELECT FROM </Typography>
            <Typography className="caption">0 - 180mins </Typography>
            <SliderContainer>
              <CustomSlider
                orientation="vertical"
                track="inverted"
                value={formRef.current.values.minutes}
                marks={Minutes}
                max={120}
                min={0}
                step={5}
                onChange={(event: object, value: number | number[]) => {
                  formRef.current.values.minutes = value;
                  setMinutes(value);
                }}
              />
            </SliderContainer>
          </Container>
        </TabPanel>
      </div>
      <FooterContainer>
        <Button style={{ color: "#395653" }} onClick={ClearForm}>
          CLEAR ALL
        </Button>
        <SubmitButton
          title="Apply"
          color="red"
          onClick={handleSubmit}
          style={{ width: "150px", marginLeft: 5 }}
          isSubmitting={formRef.current.isSubmitting}
        />
      </FooterContainer>
    </Grid>
  );
}

export default FilterComponent;

const Header = styled(Grid)`
  padding: 10px 20px;
  border-bottom: 1px solid #979797;
  border-top: 4px solid #af0000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Text = styled(Typography)`
  font-size: 1.2em;
  font-weight: 600;
`;
const Close = styled(CloseIcon)`
  width: 0.8em;
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  padding: 10px;
`;
const Container = styled.div`
  width: 55vw;
  height: 450px;
  padding: 20px;
  & .title {
    font-size: 1.2em;
    font-weight: 600;
  }
  & .caption {
    font-size: 1.4em;
    font-weight: 600;
  }
`;
const SliderContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
`;

const CustomSlider = styled(Slider)`
  & .MuiSlider-rail {
    background-color: #888888;
  }
  & .MuiSlider-track {
    background-color: #888888;
  }
  & .MuiSlider-thumb {
    background-color: #888888;
    height: 20px;
    width: 20px;
    left: 16px;
  }
  & .MuiSlider-mark {
    background-color: #888888;
    height: 0px;
    font-size: 0.7em;
  }
  & .MuiSlider-markLabel {
    font-size: 0.7em;
  }
`;

const ListText = styled(Typography)`
  font-size: 15px;
  margin-left: 10px;
`;
