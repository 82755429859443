import { action, observable, computed } from "mobx";

class QuestionsStore {
  @observable Questions: any | null = null;

  @action
  changeQuestions = (Questions: any | null) => {
    console.log("Change Questions:", Questions);
    this.Questions = Questions;
  };

  @computed
  get getQuestions() {
    return this.Questions;
  }
}
export default new QuestionsStore();
