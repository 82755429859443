import { action, observable, computed } from "mobx";

class FormDataStore {
  @observable SelectedSubject: any | null = null;
  @observable SelectedGrade: any | null = null;
  @observable CreateQuestionPaperForm: any | null = null;
  @observable QuestionPaperForm: any | null = null;
 // filterapplied
 @observable FilterAppliedValue: any | null = null;
 @observable SavedQuestionPaperValues: any | null = null;

  // filterapplied
  @action
  changeFilterApplied = (FilterAppliedValue: any | null) => {
    this.FilterAppliedValue = FilterAppliedValue;
  };
  @action
  SavedQpaper = (SavedQuestionPaperValues: any | null) => {
    this.SavedQuestionPaperValues = SavedQuestionPaperValues;
  };
  @action
  changeSelectedSubject = (SelectedSubject: any | null) => {
    console.log("Change SelectedSubject:", SelectedSubject);
    this.SelectedSubject = SelectedSubject;
  };
  @action
  changeSelectedGrade = (SelectedGrade: any | null) => {
    console.log("Change SelectedGrade:", SelectedGrade);
    this.SelectedGrade = SelectedGrade;
  };
  @action
  changeCreateQuestionPaperForm = (CreateQuestionPaperForm: any | null) => {
    console.log("Change CreateQuestionPaperForm:", CreateQuestionPaperForm);
    this.CreateQuestionPaperForm = CreateQuestionPaperForm;
  };
  @action
  changeQuestionPaperForm = (QuestionPaperForm: any | null) => {
    console.log("Change QuestionPaperForm:", QuestionPaperForm);
    this.QuestionPaperForm = QuestionPaperForm;
  };

  @computed
  get getSelectedSubject() {
    return this.SelectedSubject;
  }
  @computed
  get getSelectedGrade() {
    return this.SelectedGrade;
  }
  @computed
  get getCreateQuestionPaper() {
    return this.CreateQuestionPaperForm;
  }
  @computed
  get getQuestionPaperForm() {
    return this.QuestionPaperForm;
  }
  // filterApplied
  @computed
  get getFilterApplied() {
    return this.FilterAppliedValue;
  }
  @computed
  get getSavedPaper() {
    return this.SavedQuestionPaperValues;
  }
}
export default new FormDataStore();
