/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Box, Typography } from "@material-ui/core";
import Styled from "styled-components";

function Header({ isMobile }: any) {
  return (
    <Box>
      <Heading
        variant="h3"
        style={isMobile ? { textAlign: "center", fontSize: "2em" } : {}}
      >
        Welcome to XSEED <br />
        TestMaker
      </Heading>
      <Subtitle
        variant="subtitle2"
        style={isMobile ? { textAlign: "center", fontSize: 16 } : {}}
      >
        The XSEED TestMaker allows you to create test papers<br />
        for all grade levels and subjects!
      </Subtitle>
    </Box>
  );
}

export default Header;

const Heading = Styled(Typography)`
  line-height: 1.16;
  font-weight: 700;
  font-size: 3.75rem;
`;
const Subtitle = Styled(Heading)`
  font-size: 1.2rem;
  margin-top: 1.2rem;
  font-weight: 400;
`;
