/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import PageNotFoundView from "../views/errors/NotFoundView";
import Login from "../views/auth/Login";
import Dashboard from "../views/dashboard/Dashboard";
import RouteMap, { DashboardRoute } from "./RoutesConstants";
import AuthLayouts from "../Layouts/AuthLayouts";
import DefaultLayout from "../Layouts/DefaultLayout";
import QuestionPaper from "../views/QuestionPaper/QuestionPaper";

// const appName = "Test Maker App";

const AppRoutes = () => {
  let element = useRoutes([
    // These are the same as the props you provide to <Route>

    {
      path: RouteMap.main.default,
      element: <AuthLayouts />,
      children: [
        {
          path: RouteMap.main.default,
          element: <Navigate to={RouteMap.main.LoginPage} />,
        },
        { path: RouteMap.main.LoginPage, element: <Login /> },
      ],
    },
    {
      path: RouteMap.app.default,
      element: <Navigate to={DashboardRoute} />,
    },
    {
      path: RouteMap.app.default,
      element: <DefaultLayout />,
      children: [
        { path: RouteMap.app.dashboardPage, element: <Dashboard /> },
        {
          path: RouteMap.app.QuestionPaperPage,
          element: <QuestionPaper />,
        },
      ],
    },
    { path: "*", element: <PageNotFoundView /> },
  ]);
  return element;
};

export default AppRoutes;
