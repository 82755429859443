export enum Difficulty {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
}

export enum QuestionType {
  all = "all",
  sa = "sa",
}

export const OneClickTests = [
  {
    testType: "Block Assessment",
    minutes: 15,
    marks: 15,
    minNumberOfBlocks: 1,
    maxNumberOfBlocks: 2,
    difficulty: Difficulty.Medium,
    questionType: QuestionType.sa,
  },
  {
    testType: "Unit Test",
    minutes: 30,
    marks: 40,
    minNumberOfBlocks: 2,
    maxNumberOfBlocks: 5,
    difficulty: Difficulty.Medium,
    questionType: QuestionType.all,
  },
  {
    testType:
      "Summative Assessment 1/Summative Assessment 2/Summative Assessment 3",
    subRules: [
      {
        minutes: 90,
        marks: 80,
        difficulty: Difficulty.Medium,
        questionType: QuestionType.all,
      },
      {
        minutes: 60,
        marks: 80,
        difficulty: Difficulty.Medium,
        questionType: QuestionType.all,
        combo: [
          {
            subject: "MATH",
            grade: ["1", "2", "3"],
            product: "Foundation",
            board: "ICSE",
          },
          {
            subject: "EVS",
            grade: ["1", "2", "3"],
            product: "Foundation",
            board: "ICSE",
          },
          {
            subject: "HIN",
            grade: ["1", "2", "3"],
            product: "Foundation",
            board: "ICSE",
          },
        ],
      },
      {
        minutes: 0,
        marks: 0,
        difficulty: null,
        questionType: null,
        combo: [
          {
            subject: "ENG",
            grade: [],
            product: "",
            board: "ICSE",
          },
          {
            subject: "SCI",
            grade: ["6", "7", "8"],
            product: "",
            board: "ICSE",
          },
          {
            subject: "SST",
            grade: ["6", "7", "8"],
            product: "",
            board: "ICSE",
          },
        ],
      },
    ],
  },
  {
    testType: "Final Exam",
    minutes: 120,
    marks: 100,
    difficulty: Difficulty.Medium,
    questionType: QuestionType.all,
  },
];

export const TEST_TYPES_WITH_DEFAULT_BLOCKS: { [key: string]: string } = {
  "Summative Assessment 1": "sa1",
  "Summative Assessment 2": "sa2",
  "Summative Assessment 3": "sa3",
  "Final Exam": "final",
};
