/* eslint-disable react/display-name */
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { DashboardRoute } from "../Routes/RoutesConstants";
import firebase from "firebase";
import firebaseApp from "../firebase";

export default function AuthLayouts() {
  const Navigate = useNavigate();
  const [isLogin , setIsLogin] = useState<Boolean>(false)

  useEffect(() => {
    firebaseApp.AuthCurrentUser().then((user) => {
      setIsLogin(user != null);

      if (user) {
        Navigate(DashboardRoute);
        window.MessageBirdChatWidget.hide();
        // console.log(DashboardRoute);
      } else {
        window.MessageBirdChatWidget.init();
        window.MessageBirdChatWidget.show();
      }
    })
  }, []);

  return isLogin ? <PageLoader /> : <Outlet />;
}
