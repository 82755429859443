/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Grid, Typography, Fab } from "@material-ui/core";
import styled from "styled-components";
import ChatIcon from "../../assets/images/chatIcon.svg";
import PaymentSection from "./PaymentSection";
import SocialMedia from "./SocialMedia";

function Footer({ isMobile }: any) {
  return (
    <StyledGrid
      container
      justify="space-between"
      alignItems="center"
      style={!isMobile ? { padding: "50px 30px" } : { padding: "50px 15px" }}
    >
      {!isMobile ? (
        <>
          <Grid item md={6}>
            <Grid container>
              <Typography style={{paddingRight: 30}} variant="caption">Privacy Policy</Typography>
              <Typography style={{paddingRight: 30}} variant="caption">Terms and Conditions</Typography>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container justify="flex-end">
              <Chat id="chat_container">
                <img src={ChatIcon} alt="" />
              </Chat>
            </Grid>
          </Grid>
          <Grid item md={5}>
          </Grid>
          <Grid item md={7}>
            <Grid container justify="flex-end">
              <SocialMedia />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justify="space-between">
            <Grid item xs={7}>
              <SocialMedia />
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-end">
                <Chat id="chat_container">
                  <img src={ChatIcon} alt="" />
                </Chat>
              </Grid>
            </Grid>
            <div style={{ marginTop: 15 }}>
            </div>
            <Grid container justify="space-between">
              <Caption>Shipping and Refund Policy</Caption>
              <Caption>Privacy Policy</Caption>
              <Caption>Terms and Conditions</Caption>
            </Grid>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
}

export default Footer;

const StyledGrid = styled(Grid)`
  background: #f6f6f6;
  margin-top: 50px;
`;
const Chat = styled(Fab)`
  background: #03a9f4;
  padding: 8px;
`;
const Caption = styled(Typography)`
 font-size: 0.8em;
 margin-top: 25px;
 font-weight: 400;
`;
