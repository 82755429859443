/* eslint-disable react/display-name */
// eslint-disable-next-line
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  submit: {
    textTransform: "none",
  },
  signupButtonText: {
    fontSize: 12,
  },
}));

const SubmitButton = ({
  title = "",
  isSubmitting,
  color,
  style,
  ...rest
}: any) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      className={classes.submit}
      style={{
        ...style,
        ...{
          backgroundColor: `${color === "red" ? "#AC2323" : "#FFFFFF"}`,
          borderRadius: 6,
          height: '54px',
          color: `${color === "red" ? "#FFFFFF" : "#AC2323"}`,
          "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: `${color === "red" ? "#AC2323" : "#FFFFFF"}`,
          },
        },
      }}
      {...rest}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <CircularProgress
          size={30}
          style={{ color: `${color === "red" ? "#FFFFFF" : "#AC2323"}` }}
        />
      ) : (
        ""
      )}
      {isSubmitting ? "" : title}
    </Button>
  );
};

export default SubmitButton;
