import React from "react";
// import { Renderer } from "prosemirror-to-html-js";

function QuestionRender({ data ,dbl}: any) {
  // const renderer = new Renderer();
  if (data) {
    console.log(data,'htmldatatarr',dbl);
    return (
      <div
        dangerouslySetInnerHTML={
          // { __html: renderer.render(data) }
          {
            __html: data,
          }
        }
      ></div>
    );
  } else return <div>{dbl?'':'Loading...'}</div>;
}

export default QuestionRender;
