/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";

function RoundedButton({ title, ...rest }: any) {
  return <RoundButton {...rest}>{title}</RoundButton>;
}

export default RoundedButton;
const RoundButton = styled(Button)`
  border-radius: 25px;
  background-color: #f4e0e0;
  padding: 0px 10px;
  font-size: 1.1rem;
  font-weight: 400;
  color: #000000;
  text-transform: capitalize;
  margin-right: 5%;
`;
