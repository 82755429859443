/* eslint-disable react/display-name */
// eslint-disable-next-line
import React from "react";
import logo from "../assets/images/Logo.svg";

const Logo = (props?: any) => {
  return <img alt="Logo" src={logo} {...props} />;
};

export default Logo;
