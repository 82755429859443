/* eslint-disable react/display-name */
// eslint-disable-next-line
import {
  AppBar,
  Avatar,
  Button,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { toJS } from "mobx";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { DashboardRoute, LoginRoute } from "../../Routes/RoutesConstants";
import injectWithObserver from "../../utils/injectWithObserver";
import Logo from "../Logo";
import CustomizedDialog from "../Modal/CustomizedDialog";
import firebaseApp from "../../firebase";

const useStyles = makeStyles({
  whiteColor: {
    color: "white",
  },
  select: {
    "&:before": {
      // normal
      // borderBottom: "1px solid orange"
    },
    "&:after": {
      // focused
      // borderBottom: `3px solid green`
    },
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      // borderBottom: `2px solid purple`
    },
    "& .MuiSvgIcon-root": {
      top: "0px",
    },
  },
});

function NavBar({ stores }: any) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  const handleLogOut = () => {
    stores.userStore.changeUser(null);
    Navigate(LoginRoute);
  };

  const handleNavigate = () => {
    Navigate(DashboardRoute);
  };

  return (
    <StyledAppBar position="fixed">
      <Grid container>
        <Grid item xs={7} md={7}>
          <StyledGrid container alignItems="center">
            <StyledLogo onClick={handleNavigate} />
            {/* <SchoolLogo src={School_logo} alt={""} /> */}
            <Hidden only={["xs", "sm", "md"]}>
              <StyledText variant="body2" gutterBottom>
                {stores.userStore.schoolDetails &&
                  stores.userStore.schoolDetails.name}
              </StyledText>
            </Hidden>
          </StyledGrid>
        </Grid>
        <Grid item xs={5} md={5}>
          <StyledGrid container justify="flex-end" alignItems="center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                border: "1px solid #fff",
                minWidth: 150,
                maxWidth: 250,
                borderRadius: 5,
                height: 40,
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontSize: "18px", margin: "auto" }}>
                &nbsp;&nbsp;Academic Year:&nbsp;&nbsp;
              </Typography>
              <Typography style={{ minWidth: 100, fontSize: "18px", margin: "auto" }}>
              {stores.userStore.academicYear}
              </Typography>
            </div>
            <Hidden only={["xs", "sm", "md"]}>
              <TeacherText variant="body2">
                {toJS(firebaseApp.AuthCurrentUserSync()?.displayName || "Teacher Name")}
              </TeacherText>
            </Hidden>
            <StyledIconButton>
              <Avatar alt="Teacher name" src="" />
            </StyledIconButton>
            <LogoutButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              Log Out
            </LogoutButton>
          </StyledGrid>
        </Grid>
      </Grid>
      <CustomizedDialog
        open={open}
        toggleDialog={() => {
          setOpen(!open);
        }}
        onClose={() => {
          setOpen(false);
        }}
        cancelBtn={"Cancel"}
        title={"Log out"}
        actionTitle={"Log out"}
        content={<Text gutterBottom>Are you sure you want to logout ?</Text>}
        onConfirm={handleLogOut}
      />
    </StyledAppBar>
  );
}

export default injectWithObserver(NavBar);

const StyledAppBar = styled(AppBar)`
  background: #af0000;
  color: #ffffff;
`;
const StyledGrid = styled(Grid)`
  height: 100%;
  padding-left: 8px;
`;
const StyledLogo = styled(Logo)`
  width: 112px;
  height: 64px;
  padding: 8px 0px;
  margin-left: 80px;
  object-fit: contain;
  cursor: pointer;
`;
const SchoolLogo = styled.img`
  object-fit: contain;
  margin-left: 15px;
  width: 82px;
`;
const StyledText = styled(Typography)`
  font-size: 1.125rem;
  letter-spacing: 0.12px;
  font-weight: 600;
  margin-left: 0.5rem;
`;
const TeacherText = styled(Typography)`
  font-size: 1rem;
  color: RGB(255, 255, 255, 0.75);
  margin-left: 1.25rem;
  margin-bottom: 3px;
`;
const StyledIconButton = styled(IconButton)`
  color: #ffffff;
`;
const LogoutButton = styled(Button)`
  color: #ffffff;
  font-size: 1em;
  text-transform: none;
  font-weight: 600;
  margin-right: 21px;
`;
const Text = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
`;
