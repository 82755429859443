import React from "react";
import { Player } from 'video-react';
import Styled from "styled-components";
import HowToUseBanner from "../../assets/images/how_to_use_banner.png";
import { Grid, Typography} from "@material-ui/core";
import useWidth from "../../hooks/useWidth";
import "../../../node_modules/video-react/dist/video-react.css"; 

function HowToUseVideo() {
  
  const width = useWidth();

  const screenWidth: any = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };

  return (
    <Container>
      <Grid justify="center" container>
        <BottomHeading variant="h3" gutterBottom style={
            screenWidth[width] > 2 ? { fontSize: "2em" } : { fontSize: "1.4em" }
          }>
        How to use XSEED TestMaker
        </BottomHeading>
      </Grid>
      
      <Player
        playsInline
        poster={HowToUseBanner}
        src="https://xseed-public-static-assets.s3.us-west-2.amazonaws.com/xseed-testmaker/How+To+use+Testmaker.mp4"
      />  
    </Container>
  );
}
export default HowToUseVideo;

const Container = Styled.div`
position: relative;
width: 100%;
height: 100%;
`;

const BottomHeading = Styled(Typography)`
  font-size: 2rem;
  color: #111111;
  font-weight: 700;
  margin-bottom: 1.4em;
  margin-top: 2em;
`;


