/* eslint-disable react/display-name */
// eslint-disable-next-line
import { Button, FormHelperText, Grid, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff, Lock } from "@material-ui/icons";
import { Formik, FormikHelpers } from "formik";
import { Theme, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import OtpInput from "react-otp-input";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Styled from "styled-components";
import * as Yup from "yup";
import styled from "styled-components";
import "yup-phone";
import SubmitButton from "../../components/Button/SubmitButton";
import useSnackbar from "../../hooks/useSnackbar";
import { DashboardRoute, LoginRoute } from "../../Routes/RoutesConstants";
import { emailPasswordLogin,  googleSignIn, post } from "../../utils/Api";
import injectWithObserver from "../../utils/injectWithObserver";
import CustomizedDialog from "../../components/Modal/CustomizedDialog";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import otpImage from "../../../src/assets/images/otp_login_method.png";
import axios from "axios";
import GoogleSignInButton from "../../utils/GoogleSignIn";
import * as jose from 'jose'
import { GenerateEmailOtpResponse, GenerateMobileOtpResponse, LoginUserResponse, XGQLGatewatService } from "../../utils/XGQLGatewatService";
interface FormValues {
  countryCode: string;
  mobile: string;
  password: string;
  email:string
}

const initialFormValue: FormValues = {
  countryCode: "91",
  mobile: "",
  password: "",
  email:""
};

const xGQLGatewatService = new XGQLGatewatService(process.env.REACT_APP_XGQL_GATEWAY_ENDPOINT || '');
function LoginForm({ stores, isMobile }: any) {
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [time, setTime] = useState(5);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alert_Txt, setAlertText] = useState("");
  const [mobileVal, setMobileVal] = useState();
  const [startTimer, setStartTimer] = useState(false);
  const Navigate = useNavigate();
  const snackBar = useSnackbar();
  const [setTitle, setBtnTitle] = useState("Send OTP");
  const [open, setOpen] = useState(false);
  const [openPasswordInput, setOpenPasswordInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [countryCode, setCountryCode] = useState<any>();
  const [email, setEmail] = useState<string>();
  const [emailLogin , setEmailLogin] = useState<Boolean>(false)
  const handleError = (_error: any) => {
    snackBar.show(
      _error,
      "error",
      { vertical: "bottom", horizontal: "center" },
      true
    );
  };
  const formRef: any = useRef();

  const DialogActions = withStyles((theme: Theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  console.log(formRef, "formRefformRef");

  const handleDialogModalClose = () => {
    stores.userStore.changeUser(null);
    Navigate(LoginRoute);
    setDialogOpen(false);
    setAlertText("");
  };

  useEffect(() => {
    let timerId: any = null;
    if (time > 0 && startTimer) {
      timerId = setTimeout(() => {
        setTime((t) => t - 1);
      }, 1000);
    } else if (time <= 0) {
      setStartTimer(false);
    }
    return () => clearTimeout(timerId);
  }, [time, startTimer]);

  const handleResendOTP = ({ mobile, countryCode }: any) => {
    // console.log(mobile, countryCode);
    post("v3/users/generate_otp", {
      mobile: mobile.slice(countryCode.length),
      countryCode: countryCode,
      sourceApp: process.env.REACT_APP_SOURCE_APP_STRING,
    })
      .then((res) => {
        console.log("Generate otp :", res.data);
        setOtpGenerated(true);
        snackBar.show(
          res.data.message,
          "success",
          { vertical: "bottom", horizontal: "center" },
          true,
          3000
        );
        setTime(30);
        setStartTimer(true);
      })
      .catch((err) => {
        console.log(err);
        handleError("Something went wrong");
      });
  };

  const toggleEmailMobileLogin = ()=>{
    setEmailLogin(s=>!s)
  }

  const handleSubmit = (
    { mobile,email, password, countryCode }: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
      if(otpGenerated){
        handleLogin(password)
      }else if(openPasswordInput){
        if(!passwordValue){
          formRef.current.setSubmitting(false);
          return handleError("Please Enter Password")
        }

        handleLogin(passwordValue)
      }else{
        handleOtpGenerate({mobile,countryCode,email})
      }  
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const closeModal = () => {
    Navigate(LoginRoute);
    setDialogOpen(false);
    setAlertText("");
  };

  const handlePasswordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
  };

  const handlePasswordOption = () => {
    if (openPasswordInput) {
      setOpenPasswordInput(false);
      setBtnTitle("Submit");
      setOtpGenerated(false);
    } else {
      setOpenPasswordInput(true);
    }
  };
  const handleOtpGenerate = async(values:any)=>{
    try {
      formRef.current.setSubmitting(true);
      let res;

      if(emailLogin){
        const response: GenerateEmailOtpResponse = await xGQLGatewatService.generateEmailOtp(values.email);
        res = response.generateOtpForEmail;
      }else{
        const response: GenerateMobileOtpResponse = await xGQLGatewatService.generateMobileOtp(values.countryCode, values.mobile.slice(values.countryCode.length));
        res = response.generateOtpForMobile;
      }

      setBtnTitle("Log In");
        if (!res) {
          setOpen(true);
        }
        console.log("Generate otp :", JSON.stringify(res));

        if (res) {
          // eslint-disable-next-line
          // setBtnTitle("Send OTP");
          setOtpGenerated(true);
        }
        snackBar.show(
          res.message,
          "success",
          { vertical: "bottom", horizontal: "center" },
          true,
          3000
        );
        setStartTimer(true);
        setTime(30);
        formRef.current.setSubmitting(false);
    } catch (error) {
      console.log(error);
        // handleError("Something went wrong");
        formRef.current.setSubmitting(false);
    }
  }

  const handleOtpLogin =async(input:string)=>{
    handleLogin(input);
  }

  const handleLogin =async(input:string)=>{
    try {
      formRef.current.setSubmitting(true);

      const res: LoginUserResponse = await xGQLGatewatService.loginUser(otpGenerated, input, email!, countryCode, mobileVal?.toString()?.slice(countryCode?.length));
      if (!res.status) {
        formRef.current.setSubmitting(false);
        return handleError(res.message);
      }

      formRef.current.setSubmitting(false);
      Navigate(DashboardRoute);
    } catch (error) {
      console.log(error);
      handleError("Something went wrong");
      formRef.current.setSubmitting(false);
    }
  }

  const handleGoogleSignIn =async(googleUser:any) => {
    try {
      console.log(googleUser)
      formRef.current.setSubmitting(true);
      const userObject = jose.decodeJwt(googleUser.credential)
      // const header = jose.decodeProtectedHeader(googleUser.credential)
      console.log(userObject)
      const res = await googleSignIn({
        "email": userObject.email,
        "authProviderName": "Google",
        "verificationToken": googleUser.credential,
        "authProviderId": userObject.sub,
        "sourceApp": "XSEED_TESTMAKER",
        "platform": "web"
    })
  
    if (res.data.success === false) {
      formRef.current.setSubmitting(false);
      return handleError(res.data.message);
    }

    formRef.current.setSubmitting(false);
    } catch (error) {
      console.log(error)
      formRef.current.setSubmitting(false);
      handleError("Something went wrong");
    }
  };

  return (
    <Grid
      container
      justify={isMobile ? "center" : "flex-start"}
      style={{ zIndex: 1 }}
    >
      <Formik
        initialValues={initialFormValue}
        onSubmit={handleSubmit}
        innerRef={formRef}
        validationSchema={
          otpGenerated
            ? Yup.object().shape({
                password: Yup.string()
                  .required("OTP is required")
                  .min(4, "OTP must be 4 characters"),
              })
            : emailLogin
            ? Yup.object().shape({
                email: Yup.string()
                  .required("Email is required")
                  .matches(
                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    "Invalid email address"
                  ),
              })
            : Yup.object().shape({
                mobile:
                  countryCode == "91"
                    ? Yup.string()
                        .required("Phone number is required")
                        .matches(/^[0-9]+$/, "Must be only digits")
                        .min(12, "Please input valid 10 digit number")
                    : Yup.string()
                        .required("Phone number is required")
                        .matches(/^[0-9]+$/, "Must be only digits")
                        .min(8, "Please enter a valid mobile number.")
                        .max(16, "Please enter a valid mobile number."),
                countryCode: Yup.string().required("Country code is required"),
              })
        }
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {!otpGenerated ? (
              <>
                {emailLogin ? (
                  <input
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      setEmail(e.target.value);
                    }}
                    style={{ width: "100%", height: "60px", fontSize: "1.2em" }}
                  />
                ) : (
                  <ReactPhoneInput
                    country="in"
                    inputProps={{
                      name: "mobile",
                      required: true,
                      autoFocus: true,
                    }}
                    placeholder="Enter Mobile Number"
                    containerStyle={{
                      width: "100%",
                      height: "60px",
                    }}
                    inputStyle={{
                      marginLeft: "10%",
                      width: "90%",
                      height: "60px",
                      fontSize: "1.2em",
                    }}
                    buttonStyle={{
                      width: "20%",
                      backgroundColor: "#FFFFFF",
                      padding: "15px",
                    }}
                    dropdownStyle={{
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={mobileVal}
                    autoFormat={true}
                    onChange={(value: any, data: any) => {
                      values.mobile = value;
                      values.countryCode = data.dialCode;
                      setCountryCode(values.countryCode);
                      setMobileVal(value);
                    }}
                    // onChange={handleMobile}
                    onBlur={handleBlur}
                  />
                )}

                {Boolean(openPasswordInput) ? (
                  <>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="pwd"
                      style={{
                        width: "100%",
                        height: "60px",
                        marginTop: "1%",
                        borderRadius: "4px",
                        fontSize: "1.2em",
                      }}
                      value={passwordValue}
                      onChange={handlePasswordValue}
                      maxLength={16}
                    />

                    {showPassword ? (
                      <Visibility
                        style={{
                          position: "absolute",
                          marginLeft: "-50px",
                          marginTop: "1%",
                          color: "darkred",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          position: "absolute",
                          marginLeft: "-50px",
                          marginTop: "1%",
                          color: "darkred",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </>
                ) : (
                  <Text variant="subtitle2">
                    We will send OTP to your{" "}
                    {emailLogin ? "Email" : "mobile number"}
                  </Text>
                )}
                <div style={{ height: "26px" }}>
                  {emailLogin ? (
                    <Error>{touched.email && errors.email}</Error>
                  ) : (
                    <Error>{touched.mobile && errors.mobile}</Error>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    paddingBottom: "10px",
                  }}
                  onClick={toggleEmailMobileLogin}
                >
                  <Typography
                    variant="body2"
                    style={{
                      borderBottom: "1px dashed",
                    }}
                  >
                    {" "}
                    Continue with {emailLogin ? "Mobile" : "Email"}{" "}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "2%",
                  }}
                >
                  <Typography variant="body2"> or log in using </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "2%",
                  }}
                >
                  <Button
                    style={{
                      color: " darkred",
                      backgroundColor: "#FFFFFF",
                      padding: 4,
                      fontSize: "1em",
                      marginRight: "10px",
                    }}
                    onClick={handlePasswordOption}
                  >
                    {openPasswordInput ? <img src={otpImage} /> : <Lock />}
                  </Button>

                  {/* TODO: Change google signin to firebase */}
                  {/* <GoogleSignInButton handleGoogleSignIn={handleGoogleSignIn} /> */}
                </div>
              </>
            ) : (
              <Grid
                container
                alignItems="center"
                style={{ marginBottom: "20px" }}
              >
                <OtpInput
                  value={values.password}
                  // onChange={handleChange("password")}
                  onChange={(e: any) => {
                    setFieldValue("password", e);
                    if (e.length === 6) {
                      handleOtpLogin(e);
                    }
                  }}
                  numInputs={6}
                  inputStyle={OTPStyle}
                  shouldAutoFocus={true}
                  placeholder={"000000"}
                />
                <Button
                  style={{ color: " #FFFFFF", textDecoration: "underline" }}
                  onClick={() => {
                    setOtpGenerated(false);
                    setStartTimer(false);
                    setFieldValue("password", "");
                  }}
                >
                  Edit
                </Button>
                <div>
                  <Text variant="subtitle2">
                    Please Enter the OTP sent to &nbsp;{" "}
                    <b>
                      {emailLogin
                        ? values.email
                        : values.mobile.slice(values.countryCode.length)}
                    </b>
                  </Text>
                  <Button
                    style={{
                      color: " #FFFFFF",
                      padding: 0,
                      fontSize: "1em",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    disabled={time > 0}
                    onClick={() => {
                      setFieldValue("password", "");
                      handleResendOTP(values);
                    }}
                  >
                    {time > 0 ? `Resend OTP in ${time}` : `Resend OTP`}
                  </Button>
                </div>
              </Grid>
            )}
            {openPasswordInput ? (
              <SubmitButton
                title={setTitle === "Submit" ? setTitle : "Login"}
                isSubmitting={isSubmitting}
                style={LoginButtonStyle}
              />
            ) : (
              <SubmitButton
                title={
                  values.mobile.length && setTitle === "Log In"
                    ? setTitle
                    : "Send OTP"
                }
                isSubmitting={isSubmitting}
                style={LoginButtonStyle}
              />
            )}
          </Form>
        )}
      </Formik>

      <CustomizedDialog
        open={open}
        cancelBtn={""}
        toggleDialog={() => {
          setOpen(!open);
        }}
        onClose={() => {
          setOpen(false);
        }}
        title={"Cancel"}
        actionTitle={"OK"}
        content={
          <Err_Text>{"Sorry, this number is not registered with us"}</Err_Text>
        }
        onConfirm={handleModalClose}
      />
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogText>
          {alert_Txt
            ? alert_Txt
            : " Sorry, Test Maker is only accessible for teachers using SuperTeacher subscribed subjects in Grades 1-8."}
        </DialogText>
        <DialogActions>
          <Button
            variant="contained"
            style={{ background: "#af0000", color: "#fff" }}
            onClick={alert_Txt ? closeModal : handleDialogModalClose}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default injectWithObserver(LoginForm);

const Form = Styled.form`
width:400px;`;

const LoginButtonStyle = {
  fontSize: "1.4em",
  fontWeight: "700",
  width: "100%",
};

export const DialogText = styled(Typography)`
  padding: 15px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 15px;
`;

const OTPStyle = {
  height: "50px",
  width: "45px",
  fontSize: "1.2em",
  background: "#FFFFFF",
  border: "none",
  borderRadius: "5px",
  marginRight: "10px",
};
const Error = Styled(FormHelperText)`
color:#FFFFFF;
`;
const Text = Styled(Typography)`
color:#FFFFFF;
font-size: 16px;
`;
const PhoneNumber = Styled(Text)`
font-weight: 600;
font-size: 1.4em;
`;
const Err_Text = Styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
`;
