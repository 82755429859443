import firebase from "firebase";
import axios from "axios";
import firebaseApp from "../firebase";

export interface GenerateMobileOtpResponse {
	generateOtpForMobile: Record<string, any>;
}

export interface GenerateEmailOtpResponse {
	generateOtpForEmail: Record<string, any>;
}

export interface LoginUserResponse {
	status: boolean;
	message: string;
}

export class XGQLGatewatService {
	apiEndpoint: string;

	constructor(apiEndpoint: string) {
		this.apiEndpoint = apiEndpoint;
	}

	private async processRequest(query: string): Promise<any> {
		const response = await axios.post(this.apiEndpoint, { query: query }, {
			headers: {
				accept: "application/json",
				"content-type": "application/json",
				"x-public-api": true
			},
		});

		return response.data.data;
	}

	async generateMobileOtp(countryCode: string, mobile: string): Promise<GenerateMobileOtpResponse> {
		const body = `mutation {
			generateOtpForMobile(otpRequest: {countryCode: "+${countryCode}", mobile: "${mobile}", whatsappEnabled: false, isLogin: true}) {
				appStatus
				message
			}
		}`;

		return this.processRequest(body);
	}

	async generateEmailOtp(emailId: string): Promise<GenerateEmailOtpResponse> {
		const body = `mutation {
			generateOtpForEmail( otpRequest: { emailId: "${emailId}", isLogin: true } ) {
				appStatus
				message
			}
		}`;

		return this.processRequest(body);
	}

	async loginUser(isOtpLogin: Boolean, input: string, email: string, countryCode: string, mobile: string,): Promise<LoginUserResponse> {
		try {
			let credential = '{}';

			if(isOtpLogin) {
				if (email) {
					credential = `{email: "${email}" otp: "${input}" }`;
				} else {
					credential = `{countryCode: "+${countryCode}" phone: "${mobile}" otp: "${input}"}`;
				}
			} else {
				if (email) {
					credential = `{email: "${email}" password: "${input}" }`;
				} else {
					credential = `{countryCode: "+${countryCode}" phone: "${mobile}" password: "${input}"}`;
				}
			}

			const body = `mutation { loginUser( user: { credential: ${credential} } ) { appStatus message customToken } }`;
			const { loginUser } = await this.processRequest(body);
			if (!loginUser.customToken) throw new Error(loginUser.message);
			await firebaseApp.AuthSignInWithCustomToken(loginUser.customToken);

			return {
				status: true,
				message: loginUser.message,
			}
		} catch (error) {
			console.log(error);
			return {
				status: false,
				message: (error as any).message || 'Something went wrong, Please try again later.'
			}
		}
	}
}
