/* eslint-disable react/display-name */
// eslint-disable-next-line
import ApexCharts from "apexcharts";
import React, { useEffect } from "react";

function ChartOne({ subjectGraph }: any) {
  useEffect(() => {
    if (subjectGraph.length > 0) {
      var options = {
        chart: {
          type: "bar",
          stacked: true,
          // offsetX: -60,
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: "Used",
            data: subjectGraph.map((x: any) => x.usedPaper),
          },
          {
            name: "Unused",
            data: subjectGraph.map((x: any) => (x.saveQuestion - x.usedPaper)),
          },
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
            barHeight: 50,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            colors: ["#000000"],
          },
        },
        xaxis: {
          categories: subjectGraph.map((x: any) => x._id),
          labels: {
            formatter: function () {
              return "";
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "center",
            style: {
              fontSize: "12px",
              colors: ["#000000"],
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ["#5C8985", "#D8D8D8"],
        grid: {
          show: false,
        },
      };

      var chart = new ApexCharts(document.querySelector("#chart_one"), options);
      chart.render();
    }
  }, [subjectGraph]);
  return <div id="chart_one" style={{ width: "90%" }}></div>;
}

export default ChartOne;
