/* eslint-disable react/display-name */
// eslint-disable-next-line
import { Button, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  whiteButton: {
    color: "#474747",
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFFFFF",
    },
  },
  redButton: {
    backgroundColor: "#AC2323",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#AC2323",
    },
  },
}));

const CustomizedButton = ({ title = "", color, variant = "outlined", ...rest }: any) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      className={color === "red" ? classes.redButton : classes.whiteButton}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default CustomizedButton;
