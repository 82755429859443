const main = "/";
export const LoginRoute = `${main}login`;

const AppLayout = "/app";
export const AppDefaultLayout = AppLayout;
export const DashboardRoute = `${AppLayout}/dashboard`;
export const QuestionPaperRoute = `${AppLayout}/PreviewQuestionPaper/:id`;

const RouteMap = {
  main: {
    default: main,
    LoginPage: LoginRoute.replace(main, ""),
  },
  app: {
    default: AppDefaultLayout,
    dashboardPage: DashboardRoute.replace(AppLayout, ""),
    QuestionPaperPage: QuestionPaperRoute.replace(AppLayout, ""),
  },
};
// console.log('RouteMap', RouteMap);

export default RouteMap;
